import { Link } from 'react-router-dom';
import Header from '../components/header'
import StockHoldingForm from '../components/Stock/StockHoldingForm'
import StoreLevels from '../components/Stock/StoreLevels'
import ExpiredButton from '../components/Stock/ExpiredButton'
import MedicineBatchTable from '../components/EditTables/MedicineBatchTable';  

const token = localStorage.getItem("token");

function EditStock() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <StoreLevels token={token} />  
        <StockHoldingForm token={token} />   
        <ExpiredButton token={token}/>
        <MedicineBatchTable token={token} /> 
        <Link to="/stockcheck">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

          <button className="button">Stock Check</button>
          </div>
        </Link>
        </div>
    </div>
  );
}

export default EditStock;

