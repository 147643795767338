import React, { useState } from 'react';
import Header from '../components/header';
import MedicineNotPresent from '../components/Unpacking/MedicineNotPresent';
import DisplayInfo from '../components/Display/DisplayInfo';

const Unpack = () => {
  const token = localStorage.getItem('token');

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <MedicineNotPresent token={token} /> 
        <div className="PackButtonCheck">
        </div>
      </div>
    </div>
  );
};

export default Unpack;