import React from 'react';
import Header from '../../components/header';
import PackedPouchesInMedicineBag from '../../components/Reports/PackedPouchesInMedicineBag';

const PackedPouchesInMedicineBagReport = () => {
  const token = localStorage.getItem('token');

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <PackedPouchesInMedicineBag token={token} />
      </div>
    </div>
  );
};

export default PackedPouchesInMedicineBagReport;
