import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const PouchTypeSummary = ({ token, pouch_type, status }) => {
  const [data, setData] = useState([]);

  const fetchPouchTypeSummary = async () => {
    const response = await axios.get(`api/pouch_type_summary/${pouch_type}/${status}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Pouch Number': item.Pouch_Number || '',
      'Pouch Type': item.Pouch_Type || '',
      'Most Recent Date': item.Most_Recent_Date || '',
      'Pouch Status': item.Pouch_Status || '',
      'Soonest Expiry': item.Soonest_Expiry || '',
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchPouchTypeSummary();
  }, [pouch_type, status]);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pouch Type Summary');
    XLSX.writeFile(wb, 'PouchTypeSummary.xlsx');
  };

  const initialColumns = ['Pouch Number', 'Pouch Type', 'Most Recent Date', 'Pouch Status', 'Soonest Expiry'];
  const allColumns = ['Pouch Number', 'Pouch Type', 'Most Recent Date', 'Pouch Status', 'Soonest Expiry'];

  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default PouchTypeSummary;
