import React, { useState } from 'react';
import Header from '../components/header';
import PouchContentDisplay from '../components/Packing/PouchContentDisplay';
import MedicineUpload from '../components/Packing/MedicineUpload';
import NSMedicineUpload from '../components/Packing/NSMedicineUpload';
import PouchFiller from '../components/Packing/PouchFiller';
import CombinedMedicineUpload from '../components/Packing/CombinedMedicineUpload';

const token = localStorage.getItem("token");

const Pack = () => {
  const [reloadFlag, setReloadFlag] = useState(false);
  const handleReload = () => {
    setReloadFlag(!reloadFlag);
  };
  const [showBarcodeInput, setShowBarcodeInput] = useState(true);


  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="switch-button-container">
          <button onClick={() => setShowBarcodeInput(!showBarcodeInput)} className='button'>
            {showBarcodeInput ? 'Switch to Manual' : 'Switch to Barcode'}
          </button>
        </div>
        <div className="medicine-upload-container">
          {showBarcodeInput ? (
            <MedicineUpload token={token} onReload={handleReload} />
          ) : (
            <NSMedicineUpload token={token} onReload={handleReload} />
          )}
        </div>

        <div className="content-row">
          <div className="tables-pack">
            <PouchFiller token={token} reloadFlag={reloadFlag} />
            <PouchContentDisplay token={token} reloadFlag={reloadFlag} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pack;