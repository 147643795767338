import Header from '../components/header'
import PouchTypeTable from '../components/EditTables/PouchTypeTable';

const token = localStorage.getItem("token");

function EditPouchType() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <PouchTypeTable token={token}/> 
        </div>
    </div>
  );
}

export default EditPouchType;