import { QueryClient, QueryClientProvider } from 'react-query';
import Header from '../components/header'
import StoreLevels from '../components/Stock/StoreLevels'
import StockCheck from '../components/Stock/StockCheck'

const token = localStorage.getItem("token");
const queryClient = new QueryClient();

function StockCheckpage() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <QueryClientProvider client={queryClient}>
          <StockCheck token={token} />
        </QueryClientProvider>
        </div>
    </div>
  );
}

export default StockCheckpage;