import React, { useEffect } from 'react';
import Header from '../components/header';
import UserDetails from '../components/Display/UserDetails';  // Import the UserDetails component
import { useSelector } from 'react-redux';

const token = localStorage.getItem("token");

const AccountPage = () => {
  const user = useSelector((state) => state.user);  // Get the user details from Redux

  useEffect(() => {
    // You can perform any side-effects here if needed
  }, [user]);

  return (
    <div className='main-content'>
      <Header token={token} />
      <h2 className="Large-title">Your Account Information</h2>
      <UserDetails user={user} token={token} /> 
    </div>
  );
};

export default AccountPage;
