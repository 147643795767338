import React, { useState, useEffect } from 'react';

const EditUserOnly = ({ user, token }) => {
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({});

  const handleFieldChange = (field, value) => {
    setUserData({ ...userData, [field]: value });
    setEditedFields({ ...editedFields, [field]: true });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/user_barcode/${user.userBarcode}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const data = await response.json();
        if (response.ok) {
          setUserData(data);
        } else {
          console.error('Failed to fetch user:', data);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    
    fetchData();
  }, [user]);

  const handleSave = async () => {
    const dataToSend = Object.keys(editedFields).reduce((acc, field) => {
      acc[field] = userData[field];
      return acc;
    }, {});
    
    try {
      const response = await fetch(`/api/User/update/${user.userBarcode}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend),
      });
      
      if (response.ok) {
        setIsEditing(false);
        console.log('User updated');
      } else {
        console.error('Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <div className="medicine-upload-card">
      <h2 className="medicine-upload-header Large-title">Edit User</h2>
      <div className="medicine-upload-body">
        <form onSubmit={handleSave} className="medicine-upload-form">
          <div className="medicine-upload-item">
            <span className="medicine-upload-label">Name:</span>
            <input
              type="text"
              value={userData.name || ''}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
          </div>

          <div className="medicine-upload-item">
            <span className="medicine-upload-label">Station:</span>
            <input
              type="text"
              value={userData.station || ''}
              onChange={(e) => handleFieldChange('station', e.target.value)}
            />
          </div>

          <div className="medicine-upload-item">
            <span className="medicine-upload-label">Email:</span>
            <input
              type="email"
              value={userData.email || ''}
              onChange={(e) => handleFieldChange('email', e.target.value)}
            />
          </div>

          <div className="medicine-upload-item">
            <button className="medicine-upload-button" type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserOnly;
