import Header from '../components/header'
import VehicleTypeTable from '../components/EditTables/VehicleTypeTable'; // Updated import

const token = localStorage.getItem("token");

function EditVehicleType() { 
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <VehicleTypeTable token={token}/>
        </div>
    </div>
  );
}

export default EditVehicleType; 
