import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm';


const StockHoldingForm = ({ token }) => {
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const [medicineName, setMedicineName] = useState('');
  const [batchNumber, setBatchNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [comments, setComments] = useState('');
  const [medicineBatchData, setMedicineBatchData] = useState([]);
  const [drugBarcode, setDrugBarcode] = useState('');

  useEffect(() => {
    const fetchMedicineBatchData = async () => {
      try {
        const response = await axios.get('/api/MedicineBatch/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMedicineBatchData(response.data);
      } catch (error) {
        console.error('Error fetching medicine batch data:', error);
      }
    };

    fetchMedicineBatchData();
  }, []);

  const handleMedicineNameChange = (e) => {
    setMedicineName(e.target.value);
    setBatchNumber('');
    updateDrugBarcode(e.target.value, '');
  };

  const handleBatchNumberChange = (e) => {
    setBatchNumber(e.target.value);
    updateDrugBarcode(medicineName, e.target.value);
  };
  
  const updateDrugBarcode = (medicineName, batchNumber) => {
    const selectedMedicine = medicineBatchData.find(
      (item) => item.medicine_name === medicineName && item.batch_number === batchNumber
    );
  
    if (selectedMedicine) {
      setDrugBarcode(selectedMedicine.drug_barcode);
    } else {
      setDrugBarcode('');
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const historyResponse = await axios.post('/api/StockHoldingHistory/create/', {
        location: stationBarcode,
        user_barcode: userBarcode,
        drug_barcode: drugBarcode,
        transaction: `Add stock`,
        amount: amount,
        datetime_transaction: new Date().toISOString(),
        comments: comments,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      alert('StockHolding and StockHoldingHistory saved successfully!');
    } catch (error) {
      console.error('Error saving StockHolding and StockHoldingHistory:', error);
      alert('Error saving StockHolding and StockHoldingHistory');
    }
  };


  const handleRefresh = () => {
    window.location.reload();
  };

  const uniqueMedicineNames = Array.from(
    new Set(medicineBatchData.map((item) => item.medicine_name))
  );

  const filteredBatchNumbers = medicineBatchData
    .filter((item) => item.medicine_name === medicineName)
    .map((item) => item.batch_number);

    const questions = [
      {
        type: 'select',
        text: 'Medicine Name',
        value: medicineName,
        handleChange: handleMedicineNameChange,
        options: [{ value: '', label: 'Select medicine' }, ...uniqueMedicineNames.map((name) => ({ value: name, label: name }))],
      },
      {
        type: 'select',
        text: 'Batch Number',
        value: batchNumber,
        handleChange: handleBatchNumberChange,
        options: [{ value: '', label: 'Select Batch' }, ...filteredBatchNumbers.map((number) => ({ value: number, label: number }))], // Added "Select Batch" here
      },
      {
        type: 'input',
        text: 'Amount',
        value: amount,
        handleChange: (e) => setAmount(e.target.value),
      },
      {
        type: 'input',
        text: 'Comments',
        value: comments,
        handleChange: (e) => setComments(e.target.value),
      },
    ];
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

      <div className="medicine-container">
    <div className="form-container">
      <DynamicForm
        title="Add New Stock"
        questions={questions}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        // ... other props ...
      />
      </div>
      </div>
      </div>

    );
  }
export default StockHoldingForm;

