import React, { useState, useEffect } from 'react';
import CarrierForm from "./CarrierForm";


const CarrierTable = ({token}) => {
  let [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  useEffect(() => {
    getCarriers();
  }, []);

  let getCarriers = async () => {
    try {
      let response = await fetch('/api/Carrier/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      setCarriers(data);
    } catch (error) {
      console.error('Error fetching carriers:', error);
    }
  };

  const handleRowClick = (carrier) => {
    setSelectedCarrier(carrier);
  };

  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        <div className="table-list" style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
        <CarrierForm selectedCarrier={selectedCarrier}  token={ token }/>
      </div>
          <table>
            <thead>
              <tr>
                <th>Carrier Number</th>
                <th>Carrier Type</th>
                <th>Carrier Barcode</th>
              </tr>
            </thead>
            <tbody>
              {carriers.map((carrier, index) => (
                <tr key={index} onClick={() => handleRowClick(carrier)}>
                  <td>{carrier.number}</td>
                  <td>{carrier.carrier_type}</td>
                  <td>{carrier.barcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
};

export default CarrierTable;