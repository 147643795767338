import React, { useState } from 'react';
import axios from 'axios';
import "../../static/CSS/LoginForm.css"

const ForgotPasswordModal = ({ isOpen, toggleModal }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post('/api/forgot_password/', {
        email,
      });
      
      if (response.status === 200) {
        setMessage('Temporary password has been sent to your email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to send email.');
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button onClick={toggleModal}>Close</button>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <button onClick={handleForgotPassword}>Submit</button>
        {message && <div>{message}</div>}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;

