import React, { useState } from 'react';
import axios from 'axios';

const SubmitHandlePackComplete = ({ pouchBarcode, token, pouchData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    const hasItemsToPack = pouchData.some(item => item.Remaining_Amount > 0);
    if (hasItemsToPack) {
      const confirmPack = window.confirm('There are still items to be packed. Are you sure you want to set as packed?');
      if (!confirmPack) {
        return; // Exit if user does not confirm
      }
    }

    try {
      setIsLoading(true);
      const response = await axios.get(`/PouchStatus/${pouchBarcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.length === 0) {
        // No row exists, create a new one
        await createPouchStatus();
      } else {
        // Row exists, update it
        await updatePouchStatus();
      }

      alert('Pouch status updated successfully');
    } catch (error) {
      console.error('Error updating pouch status:', error);
      alert('Pouch Status not updated. Error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const createPouchStatus = async () => {
    await axios.post('/PouchStatus/create/', {
      pouch_barcode: pouchBarcode,
      status: 'Packed',
      QA: 0,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const updatePouchStatus = async () => {
    await axios.put(`/PouchStatus/update/${pouchBarcode}`, {
      status: 'Packed',
      QA: 0,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  return (
    <button className='button' onClick={handleButtonClick} disabled={isLoading}>
      {isLoading ? 'Processing...' : 'Update Pouch Status'}
    </button>
  );
};

export default SubmitHandlePackComplete;
