import React, { useEffect } from 'react';
import Header from '../components/header';
import UserDetails from '../components/Display/UserDetails2';
import { useSelector } from 'react-redux';
import PouchTypesPieChart from '../components/Visulisations/PouchTypesPieChart';
import PouchesPackedByDateChart from '../components/Visulisations/PouchesPackedByDateChart';



const token = localStorage.getItem("token");

const HomePage = () => {
  const { username, station, pouchNumber } = useSelector((state) => state.user);

  useEffect(() => {
  }, [username, station, pouchNumber]);

  return (
    <div className='main-content'>
      <Header />
      <UserDetails token={token} /> 
      <PouchTypesPieChart token={token}/>
      <PouchesPackedByDateChart token={token}/>

    </div>
  );
};

export default HomePage;


 