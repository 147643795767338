import axios from 'axios';

const useUnpackProductLocationAndCarrierHistorySubmit = (token, user) => {
  
  // Function to submit the product location
  const submitProductLocation = async (Barcode, Location_Barcode, onSuccess, onError) => {
    const url = `/api/ProductLocation/create/`;
    const dataWithDatetime = {
      barcode: Barcode,
      location_barcode: Location_Barcode,
      datetime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(url, dataWithDatetime, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        onSuccess(`Product location created successfully.`);
      } else {
        onError(`Error creating product location: status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error creating product location:`, error);
      onError(`Error creating product location: ${error.response.data}`);
    }
  };

  // Function to check if a pouch is packed in a carrier and then unpack it
  const unpackSinglePouch = async (selectedPouchBarcode, quantity = 1, onFormSubmit) => {
    try {
      // Fetching all packed products in the carrier
      const response = await axios.get('/api/packed_products_in_carrier/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Filter to find all pouches in the carrier that match the selected barcode and are marked as "Packed"
      const pouchesInCarrier = response.data.filter(pouch => 
        pouch.Product_Barcode === selectedPouchBarcode && 
        pouch.Carrier_Barcode === user.carrierBarcode && 
        pouch.Carrier_Status === "Packed"
      );
  
      // Check if the specified quantity to unpack is available in the carrier
      if (pouchesInCarrier.length < quantity) {
        alert(`Only ${pouchesInCarrier.length} items available in the carrier, but ${quantity} were requested to unpack.`);
        return;
      }
  
      // Loop through the pouches and update the specified quantity to be unpacked
      for (let i = 0; i < quantity; i++) {
        const pouchToUnpack = pouchesInCarrier[i];
        const dataToUpdate = {
          Product_Barcode: pouchToUnpack.Product_Barcode,
          Station_Unpacked: user.station_barcode,
          datetime_Unpacked: new Date().toISOString(),
          User_Unpacked: user.userBarcode,
          Carrier_Status: "Unpacked",
        };
  
        // Attempting to update the carrier history for each item
        const updateResponse = await axios.put('/api/CarrierHistory/updateSingle/', dataToUpdate, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (updateResponse.status !== 200) {
          // If any update fails, alert and stop the process
          alert(`Error unpacking item ${i+1} of ${quantity}.`);
          console.log('UnpackSinglePouch Update Response:', updateResponse.data); // Log the error response data
          return; // Exit the loop and function early
        }
      }
  
      // After successfully updating all items
      alert(`${quantity} items from pouch ${selectedPouchBarcode} unpacked successfully!`);
      if (onFormSubmit) onFormSubmit(); // Callback after successful operation
    } catch (error) {
      console.error('Error unpacking pouch:', error);
      console.log('Error details:', error.response ? error.response.data : 'No response data'); // Log detailed error info
    }
  };
  
  
  

  return { unpackSinglePouch, submitProductLocation };
};

export default useUnpackProductLocationAndCarrierHistorySubmit;
