import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const UserPositionForm = ({ token, selectedPosition }) => {
  const [positionName, setPositionName] = useState('');
  const [selectedPositionId, setSelectedPositionId] = useState(null);

  useEffect(() => {
    if (selectedPosition) {
      setPositionName(selectedPosition.name);
      setSelectedPositionId(selectedPosition.id);
    }
  }, [selectedPosition]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/UserPosition/create/', {
        name: positionName,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Position created:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error creating position:', error);
    }
  };
  
  


  const handleRefresh = () => {
    setPositionName('');
    setSelectedPositionId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${positionName}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/UserPosition/update/${selectedPositionId}/`, {
        name: positionName,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating position:', error);
    }
  };
  
  

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/UserPosition/delete/${selectedPositionId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Position deleted');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting position:', error);
    }
  };
  
  

  const questions = [
    {
      type: 'text',
      text: 'Position Name',
      value: positionName,
      handleChange: (e) => setPositionName(e.target.value),
    },
  ];

  return (
    <DynamicForm
      title="Add New Position"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedPositionId}
    />
  );
};

export default UserPositionForm;
