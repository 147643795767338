// src/pages/LoginForm.js
import React, { useState } from 'react';
import axios from 'axios';
import LandingPageHeader from '../components/Landingscreen/LandingPageHeader';

import { useDispatch } from 'react-redux';
import { setUser } from '../Redux/slices/userSlice';
import { setPermissions } from '../Redux/slices/permissionSlice';  // <-- Import this
import { useNavigate } from 'react-router-dom';
import "../static/CSS/LoginForm.css"
import ForgotPasswordModal from '../components/Forgotpassword/ForgotPasswordModal'; // Import the Forgot Password Modal
import { Link } from 'react-router-dom';


const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const fetchUserByUsername = async (username) => {
    try {
      const response = await axios.get(`/api/User/${username}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        const userData = response.data[0];
        userData.username = username; 
        dispatch(setUser(userData));
      }
    } catch (error) {
      console.error('Error fetching user by username:', error);
    }
  };

  const fetchUserPermissions = async () => {
    try {
      const response = await axios.get('/api/get_user_permissions/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        dispatch(setPermissions(response.data.permissions));
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };


  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login/', {
        username,
        password,
      });
    
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        await fetchUserByUsername(username);
        await fetchUserPermissions(); 
        navigate('/home');
      }
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.error('Login failed:', error.response.data);
        setLoginError(error.response.data.message || 'Login failed');
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Login failed:', error.request);
        setLoginError('No response from the server');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Login error:', error.message);
        setLoginError('Error: ' + error.message);
      }
    }
  };
  
  

  return (
    <div className="Page-container">
            <LandingPageHeader />
      <div className="login-form-container">
        <form className="login-form" onSubmit={loginUser}>
          <h2>Login</h2>
          {loginError && <div className="error">{loginError}</div>}
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
          <div className="form-links">
            <Link to="/forgot-username">Forgot Username</Link>
            <a href="#" onClick={(e) => { e.preventDefault(); toggleModal(); }}>Forgot Password</a>
          </div>
        </form>
      </div>
      <ForgotPasswordModal isOpen={isModalOpen} toggleModal={toggleModal} />
    </div>
  );
};


export default LoginForm;
