// QAHeader.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CombinedPouchNumberInput from '../../components/PouchAdd/CombinedPouchNumberInput';
import headerImage from '../../static/images/Scanner.png'; // Assuming you have this image
import '../../static/CSS/HeaderPacking.css'; // Use the existing CSS file

const QAHeader = ({ token, showBarcodeInput, setShowBarcodeInput }) => {
  const [showPouchForm, setShowPouchForm] = useState(false);
  const [showSwitchButton, setShowSwitchButton] = useState(false);
  const user = useSelector((state) => state.user);
  const { name, station, pouchNumber } = user;

  const handlePouchButtonClick = () => {
    setShowPouchForm(!showPouchForm);
    setShowSwitchButton(!showSwitchButton);
  };

  return (
    <div className="packing-header-container">
      <div className="packing-header-box">
        <div className="text-content">
          <h2 className='qa-title'>Quality Assurance</h2>
          <p className="user-greeting">Hi {name}</p>
          <div className="button-group">
            <button className="pouch-button" onClick={handlePouchButtonClick}>Pouch</button>
          </div>
        </div>
        <img src={headerImage} alt="Header" className="header-image"/>
      </div>


        <CombinedPouchNumberInput token={token} />

        </div>

  );
};

export default QAHeader;
