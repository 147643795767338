import React, { useEffect, useState } from "react";
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import axios from 'axios';

export const generateDocument = async ({ pouchDetails, token, pouchTypeData }) => {
  let pouchData;
  try {
    const response = await axios.get(`/api/pouch_medicine_data_QA/${pouchDetails.pouch_barcode}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    pouchData = response.data;
  } catch (error) {
    console.error('Error fetching pouch medicine data:', error);
    return;
  }

  if (!pouchData) {
    alert('Pouch data is not yet loaded.');
    return;
  }

  const earliestExpiryDate = pouchData.reduce((earliest, current) => {
    const currentExpiryDate = new Date(current.Expiry_Date);
    return currentExpiryDate < earliest ? currentExpiryDate : earliest;
  }, new Date());

  fetch("/PackingSheet.docx")
  .then(response => response.arrayBuffer())
  .then(arrayBuffer => {
    const content = new Uint8Array(arrayBuffer);
    const zip = new PizZip(content);
    const doc = new Docxtemplater().loadZip(zip);
    
    /* The function I provided starts here */

    const generateDocWithData = async () => {
      const medicineResponse = await axios.get(`/api/pouch_medicine_data_QA/${pouchDetails.pouch_barcode}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const pouchMedicineData = medicineResponse.data;
      if (!pouchMedicineData) {
        console.error('Pouch medicine data not found for the given pouch barcode');
        return;
      }

      const pouchHistoryResponse = await axios.get(`/api/Pouch_history/${pouchMedicineData[0]?.Unique_ID}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const pouchHistoryData = pouchHistoryResponse.data;
      if (!pouchHistoryData) {
        console.error('Pouch history data not found for the given Unique_ID');
        return;
      }
      
// Initialize default values
let Packedusername = { name: 'Unknown' };
let QAusername = { name: 'Unknown' };

// If user_barcode is available, fetch the corresponding username
if (pouchHistoryData.user_barcode) {
  try {
    const PackedusernameResponse = await axios.get(`/api/user_barcode/${pouchHistoryData.user_barcode}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    Packedusername = PackedusernameResponse.data || { name: 'Unknown' };
  } catch (error) {
    console.error('Error fetching Packedusername:', error);
  }
}

// If user_qa is available, fetch the corresponding username
if (pouchHistoryData.user_qa) {
  try {
    const QAusernameResponse = await axios.get(`/api/user_barcode/${pouchHistoryData.user_qa}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    QAusername = QAusernameResponse.data || { name: 'Unknown' };
  } catch (error) {
    console.error('Error fetching QAusername:', error);
  }
}


  pouchDetails = {
    ...pouchDetails,
    Packed_by: Packedusername.name,
    QA_By: QAusername.name,
  };


  const pouchContentsCount = pouchData.reduce((count, med) => {
    count[med.Medicine_Name] = (count[med.Medicine_Name] || 0) + med.Amount;
    return count;
  }, {});

  let pouchContents = [];
  let previousMedicineName = null;
  
  pouchData.forEach((med) => {
    const redTagAmount = pouchTypeData.find(pouchType => pouchType.medicine_name.toLowerCase() === med.Medicine_Name.toLowerCase())?.red_tag_amount || 0;
  
    // Create the appropriate number of entries for this medicine
    let rows = new Array(med.Amount).fill(null).map(() => ({
      name: med.Medicine_Name,
      expiry: med.Expiry_Date,
      incidentNumber: '',
      personnelNumber: '',
      red: 'Yellow Tag', // Initially mark all as 'Yellow Tag'
    }));
  
    // If a different medicine was previously added, insert a blank row for separation
    if (previousMedicineName && previousMedicineName !== med.Medicine_Name) {
      pouchContents.push({
        name: '',
        expiry: '',
        incidentNumber: '',
        personnelNumber: '',
        red: '',
      });
    }
  
    // Add the new entries to the pouchContents
    pouchContents = pouchContents.concat(rows);
    previousMedicineName = med.Medicine_Name;
  
    // Get all entries of the current medicine in pouchContents
    const currentMedicineEntries = pouchContents.filter(entry => entry.name === med.Medicine_Name);
  
    // If the total count of this medicine is less than or equal to redTagAmount, mark all as 'Red Tag'
    if (currentMedicineEntries.length <= redTagAmount) {
      currentMedicineEntries.forEach(entry => {
        entry.red = 'Red Tag';
      });
    } else {
      // Mark the last 'redTagAmount' entries as 'Red Tag'
      for (let i = currentMedicineEntries.length - 1; i >= currentMedicineEntries.length - redTagAmount; i--) {
        currentMedicineEntries[i].red = 'Red Tag';
      }
    }
  });
  

  const data = {
    Packer: pouchDetails.Packed_by,
    QA: pouchDetails.QA_By,
    ExpiryDate: earliestExpiryDate.toISOString().split('T')[0],
    PouchNumber: pouchDetails.pouch_number,
    PouchType: pouchDetails.pouch_type,
    PouchContents: pouchContents,
  };

  doc.setData(data);

  try {
    doc.render();
  } catch (error) {
    console.error('Error during document generation:', error);
  }

  const updatedDoc = doc.getZip().generate({ type: 'blob' });

  const today = new Date();
  const formattedDate = `${today.getMonth() + 1}-${today.getFullYear()}`;
  saveAs(updatedDoc, `${pouchDetails.pouch_number} ${formattedDate} Packing Sheet.docx`);
};

    
    

    generateDocWithData();
  })
  .catch(error => console.error('Error fetching the file:', error));
};

const PrintSheet = ({ pouchDetails, token }) => {
  const [updatedPouchDetails, setUpdatedPouchDetails] = useState(null);
  const [pouchData, setPouchData] = useState(null);
  const [pouchTypeData, setPouchTypeData] = useState([]);

  useEffect(() => {
    const fetchPouchTypeData = async () => {
      try {
        const response = await axios.get('/api/PouchType/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPouchTypeData(response.data);
      } catch (error) {
        console.error('Error fetching pouch type data:', error);
      }
    };
  
    fetchPouchTypeData();
  }, [token]);
  

  useEffect(() => {
    const fetchPouchData = async () => {
      try {
        const response = await axios.get(`/api/pouch_medicine_data/${pouchDetails.Pouch_Barcode}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        const dataWithPackerAndQA = {
          ...pouchDetails,
          Packed_by: response.data.Packed_by,
          QA_By: response.data.QA_By,
        };
        
        setUpdatedPouchDetails(dataWithPackerAndQA);
        setPouchData(dataWithPackerAndQA);
      } catch (error) {
        console.error('Error fetching pouch medicine data:', error);
      }
    };

    if (pouchDetails.Pouch_Barcode) {
      fetchPouchData();
    } else {
      setPouchData([]);
    }
  }, [pouchDetails.Pouch_Barcode, token]);

  return (
    <div>
      {pouchTypeData && updatedPouchDetails && <button onClick={() => generateDocument({ pouchDetails: updatedPouchDetails, token, pouchTypeData })}>Generate Document</button>}
    </div>
  );
};

export default PrintSheet;
