import React, { useState, useEffect, useCallback } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const UserForm = ({ selectedUser, token }) => {
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [station, setStation] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userBarcode, setUserBarcode] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [positions, setPositions] = useState([]); // New state for positions


  useEffect(() => {
    if (selectedUser) {

      setName(selectedUser['User Name']);
      setPosition(selectedUser['Position']);
      setStation(selectedUser['Station']);
      setEmail(selectedUser['Email']);
      setUsername(selectedUser['Username']);
      setPassword(selectedUser['Password']);
      setUserBarcode(selectedUser['User Barcode']);
      setIsActive(selectedUser.is_active);
      setSelectedUserId(selectedUser['User Barcode']);
    } else {
      handleRefresh(); // Reset all fields if no user is selected
      setSelectedUserId(null); // Ensure no user ID is set when adding a new user
    }
  }, [selectedUser]);

  const fetchStations = useCallback(async () => {
    try {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      
      const response = await fetch('/api/Station/', requestOptions);
      const data = await response.json();
      setStations(data);
    } catch (error) {
      console.error('Error fetching stations:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchStations();
  }, [fetchStations]);

// New function to fetch positions
const fetchPositions = useCallback(async () => {
  try {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch('/api/UserPosition/', requestOptions);
    const data = await response.json();
    setPositions(data.map(item => ({ value: item.name, label: item.name })));
  } catch (error) {
    console.error('Error fetching positions:', error);
  }
}, [token]);

    // Fetch positions when the component mounts
    useEffect(() => {
      fetchPositions();
    }, [fetchPositions]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedUserId) {
      await handleUpdate();
    } else {
      await handleCreate();
    }
  };

  const handleRefresh = () => {
    setName('');
    setPosition('');
    setStation('');
    setEmail('');
    setUsername('');
    setPassword('');
    setUserBarcode(null);
    setSelectedUserId(null); // Add this line

  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      handleDelete();
    }
  };


  const handleCreate = async () => {
    try {
      const response = await axios.post('/api/User/create/', {
        name,
        position,
        station,
        email,
        username,
        password,
        user_barcode: userBarcode,
        is_active: isActive,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      if (response.status === 200) {
        handleRefresh();
      } else {
        throw new Error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
  
  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/User/update/${userBarcode}/`, {
        Name: name,
        position: position,
        station: station,
        email: email,
        username: username,
        password: password,
        user_barcode: userBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      if (response.status === 200) {
        console.log('User updated:', response.data);
        window.location.reload();
      } else {
        console.error('Error updating user:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/User/delete/${userBarcode}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      if (response.status === 200) {
        handleRefresh();
        window.location.reload();
      } else {
        console.error('Error deleting user:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  

  const [stations, setStations] = useState([]);

  const handleGenerateBarcode = async () => {
    const prefix = 'U'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setUserBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  

  const questions = [
    {
      text: 'Name',
      value: name,
      handleChange: (e) => setName(e.target.value),
      type: 'text',
    },
    {
      text: 'Position',
      value: position,
      handleChange: (e) => setPosition(e.target.value),
      type: 'select',
      styled: true,
      options: [{ value: "", label: "Select a position" }, ...positions], 
    },

    {
      text: 'Station',
      value: station,
      handleChange: (e) => setStation(e.target.value),
      type: 'select',
      styled: true,
      options: stations.map(s => ({ value: s.station_name, label: s.station_name })),
    },
    {
      text: 'Email',
      value: email,
      handleChange: (e) => setEmail(e.target.value),
      type: 'email',
    },
    {
      text: 'Username',
      value: username,
      handleChange: (e) => setUsername(e.target.value),
      type: 'text',
    },
    {
      text: 'Password',
      value: password,
      handleChange: (e) => setPassword(e.target.value),
      type: 'password',
    },
    {
      text: 'User Barcode',
      value: userBarcode,
      handleChange: (e) => setUserBarcode(e.target.value),
      type: 'text',
      isLocked: true 
    },

      ...(!userBarcode ? [{
        text: 'Generate Barcode',
        type: 'generateBarcode'
      }] : [])

  ];

  return (
    <DynamicForm
      title="Add New User"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      handleGenerateBarcode={handleGenerateBarcode}
      selectedId={userBarcode}
      setDrugBarcode={setUserBarcode}
    />
  );
};

export default UserForm;



