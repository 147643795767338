import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import MedicineForm from "./MedicineForm";

const token = localStorage.getItem("token");

const MedicineTable = () => {
  let [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);

  useEffect(() => {
    getMedicines();
  }, []);

  let getMedicines = async () => {
    try {
      fetch("/api/Medicine/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setMedicines(data);
          } else {
            setMedicines([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching medicines:", error);
        });
    } catch (error) {
      console.error('Error fetching medicines:', error);
    }
  };


  const initialColumns = ['Medicine Name', 'Dose', 'Max Level', 'Reorder Level', 'Pack by Vial'];
  const allColumns = medicines.length > 0 ? Object.keys(medicines[0]) : [];

  const tableData = medicines.map(medicine => ({
    'Medicine Name': medicine.medicine_name || '',
    'Dose': medicine.dose,
    'Max Level': medicine.max_level,
    'Reorder Level': medicine.reorder_level,
    'Pack by Vial': medicine.pack_by_vial,
    'id': medicine.id,
  }));

  const handleRowSelect = (selectedRow) => {
    setSelectedMedicine(selectedRow);
    setSelectedMedicineId(selectedRow.id);
  };
  
  

  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="form-container">
        <MedicineForm selectedMedicine={selectedMedicine} selectedMedicineId={selectedMedicineId} setSelectedMedicineId={setSelectedMedicineId} token={token} />
      </div>
      <div className="medicine-container">
        <div className="Medicine-list">
          <EditableTable initialColumns={initialColumns} data={tableData} allColumns={allColumns} onRowSelect={handleRowSelect} />
        </div>
      </div>
    </div>
  );
};

export default MedicineTable;

