// axiosInstance.js
import axios from 'axios';
import { getCookie } from '../utils/utils'; // make sure the path is correct based on your file structure

const axiosInstance = axios.create({
    baseURL: 'https://www.precisepouchtrack.com/api', 
    timeout: 5000,
});

// Attach CSRF token to every request
axiosInstance.interceptors.request.use(config => {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
});

export default axiosInstance;


