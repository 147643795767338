import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ItemSelectionForm = ({ token, onItemSelect, refresh }) => {
    const [medicineBags, setMedicineBags] = useState([]);
    const [medicineBatch, setMedicineBatch] = useState([]);
    const [consumableBatch, setConsumableBatch] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [pouches, setPouches] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [searchBarcode, setSearchBarcode] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedDatabase, setSelectedDatabase] = useState('');
    const [databaseItems, setDatabaseItems] = useState([]);
    const [itemFilter, setItemFilter] = useState('');
    const [showManualAdd, setShowManualAdd] = useState(false); // New state to toggle UI

    useEffect(() => {
        // Reset all the state variables to their initial states
        setSearchBarcode('');
        setSearchResult('');
        setSelectedItem('');
        setSelectedDatabase('');
        setDatabaseItems([]);
        setItemFilter('');
        // Note: You do not need to reset the data fetching states (medicineBags, carriers, etc.)
        // as they do not change based on user input, but you might want to trigger a data refresh if necessary.
    }, [refresh]);

  // You might want to fetch these from your backend as done in ProductLocationForm
  const databases = {
    MedicineBags: [],
    Carriers: [],
    Pouches: [],
    Vehicles: [],
    ConsumableBatch: [],
  };

  // Fetch Medicine Bags
  useEffect(() => {
    const fetchMedicine_bags = async () => {
      try {
        const response = await axios.get('/api/MedicineBags/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setMedicineBags(response.data);
        }
      } catch (error) {
        console.error('Error fetching medicine bags:', error);
      }
    };
    fetchMedicine_bags();
  }, [token]);

  // Fetch Carriers
  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const response = await axios.get('/api/Carrier/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setCarriers(response.data);
        }
      } catch (error) {
        console.error('Error fetching carriers:', error);
      }
    };
    fetchCarriers();
  }, [token]);

  // Fetch Pouches
  useEffect(() => {
    const fetchPouches = async () => {
      try {
        const response = await axios.get('/api/Pouch/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setPouches(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouches:', error);
      }
    };
    fetchPouches();
  }, [token]);

  // Fetch Vehicles
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get('/api/Vehicle/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setVehicles(response.data);
        }
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };
    fetchVehicles();
  }, [token]);

  // Fetch Consumable Batch
  useEffect(() => {
    const fetchConsumableBatch = async () => {
      try {
        const response = await axios.get('/api/ConsumableBatch/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setConsumableBatch(response.data);
        }
      } catch (error) {
        console.error('Error fetching consumable batch:', error);
      }
    };
    fetchConsumableBatch();
  }, [token]);


    // Fetch Medicine Batch
  useEffect(() => {
    const fetchMedicineBatch = async () => {
        try {
        const response = await axios.get('/api/MedicineBatch/', {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200) {
            setMedicineBatch(response.data);
        }
        } catch (error) {
        console.error('Error fetching Medicine Batch:', error);
        }
    };
    fetchMedicineBatch();
    }, [token]);



  // Handle barcode search within the loaded data
  useEffect(() => {
    let allItems = [
        ...medicineBags.map(bag => ({ barcode: bag.medicine_bag_barcode, label: bag.medicine_bag_number, type: 'Medicine Bag' })),
        ...carriers.map(carrier => ({ barcode: carrier.carrier_barcode, label: carrier.carrier_number, type: 'Carrier' })),
        ...pouches.map(pouch => ({ barcode: pouch.pouch_barcode, label: pouch.pouch_number, type: 'Pouch' })),
        ...vehicles.map(vehicle => ({ barcode: vehicle.vehicle_barcode, label: vehicle.vehicle_number, type: 'Vehicle' })),
        ...consumableBatch.map(batch => ({ barcode: batch.product_barcode, label: batch.consumable_name, type: 'Consumable Batch' })),
        ...medicineBatch.map(drug => ({ barcode: drug.drug_barcode, label: drug.medicine_name, type: 'Medicine Batch' })),
    ];

    const matchedItem = allItems.find(item => item.barcode === searchBarcode);
    if (matchedItem) {
        setSearchResult(`Found in ${matchedItem.type}: ${matchedItem.label}`);
        setSelectedItem(matchedItem.barcode); // Keep this to update the state based on search
    } else {
        setSearchResult('No item found with that barcode in any database.');
    }
}, [searchBarcode, medicineBags, carriers, pouches, vehicles, consumableBatch, medicineBatch]);

  // Update the items dropdown when the selected database changes
  useEffect(() => {
    let items = [];
    switch (selectedDatabase) {
      case 'MedicineBags':
        items = medicineBags.map(bag => ({ id: bag.medicine_bag_barcode, label: bag.medicine_bag_number }));
        break;
      case 'Carriers':
        items = carriers.map(carrier => ({ id: carrier.carrier_barcode, label: carrier.carrier_number }));
        break;
      case 'Pouches':
        items = pouches.map(pouch => ({ id: pouch.pouch_barcode, label: pouch.pouch_number }));
        break;
      case 'Vehicles':
        items = vehicles.map(vehicle => ({ id: vehicle.vehicle_barcode, label: vehicle.vehicle_number }));
        break;
        case 'ConsumableBatch':
            // Assuming your consumable batches have a `consumable_name` and a `Batch_Number`
            items = consumableBatch.map(batch => ({
              id: batch.product_barcode,
              label: `${batch.consumable_name} - Batch ${batch.batch_number}` // Adjust this format as needed
            }));
            break;
          case 'MedicineBatch':
            // Assuming your medicine batches have a `drug_name` and a `Batch_Number`
            items = medicineBatch.map(drug => ({
              id: drug.drug_barcode,
              label: `${drug.medicine_name} - Batch ${drug.batch_number}` // Adjust this format as needed
            }));
            break;
      default:
        items = [];
    }
    items.sort((a, b) => a.label.localeCompare(b.label));

    // This is the corrected part: filter the items based on the itemFilter query
    const filteredItems = items.filter(item => item.label.toLowerCase().includes(itemFilter.toLowerCase()));
    setDatabaseItems(filteredItems);
}, [selectedDatabase, itemFilter]);


    // Aggregate items from all categories
    const aggregateItems = () => [
        ...medicineBags.map(bag => ({ barcode: bag.medicine_bag_barcode, type: 'Medicine Bag' })),
        ...carriers.map(carrier => ({ barcode: carrier.carrier_barcode, type: 'Carrier' })),
        ...pouches.map(pouch => ({ barcode: pouch.pouch_barcode, type: 'Pouch' })),
        ...vehicles.map(vehicle => ({ barcode: vehicle.vehicle_barcode, type: 'Vehicle' })),
        ...consumableBatch.map(batch => ({ barcode: batch.product_barcode, type: 'Consumable Batch' })),
        ...medicineBatch.map(drug => ({ barcode: drug.drug_barcode, type: 'Medicine Batch' })),
    ];

    // Handles the change in the search input field
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchBarcode(value);
        console.log(`Search Barcode: ${value}`); // Debug log

        if (!value) {
            setSearchResult('');
            return;
        }

        const allItems = aggregateItems();
        const matchedItem = allItems.find(item => item.barcode === value);
        if (matchedItem) {
            setSearchResult(`Found in ${matchedItem.type}: ${matchedItem.label}`);
            console.log(`Matched Item: ${matchedItem.barcode}`); // Debug log
            onItemSelect({ product_barcode: matchedItem.barcode });
        } else {
            setSearchResult('No item found with that barcode in any database.');
        }
    };

    // When a user selects an item from the dropdown
    const handleItemSelectChange = (e) => {
        const selectedId = e.target.value;
        console.log(`Selected Item ID: ${selectedId}`); // Debug log
    
        setSelectedItem(selectedId); // Update the selectedItem state with the chosen value
    
        if (!selectedId) {
            onItemSelect(null);
            return;
        }
    
        const allItems = aggregateItems();
        const selectedItemDetail = allItems.find(item => item.barcode === selectedId);
        if (selectedItemDetail) {
            console.log(`Normalized Barcode Passed: ${selectedItemDetail.barcode}`); // Debug log
            onItemSelect({ product_barcode: selectedItemDetail.barcode });
        }
    };

    // Toggle the method of adding (by barcode search or manual selection)
    const toggleAddMethod = () => {
        setShowManualAdd(prev => !prev);
        // Reset relevant states
        setSearchBarcode('');
        setSearchResult('');
        setSelectedItem('');
        setSelectedDatabase('');
    };

    // Correctly define handleDatabaseChange here
    const handleDatabaseChange = (e) => {
        const newSelectedDatabase = e.target.value;
        console.log(`Selected Database: ${newSelectedDatabase}`); // Debug log
        setSelectedDatabase(newSelectedDatabase);
        setSearchResult('');
        setSelectedItem('');
    };


    // Your return statement...
    return (
        <div>
            <h4>Add Product</h4>
            {!showManualAdd ? (
                <>
                    <input
                        type="text"
                        placeholder="Search by barcode"
                        value={searchBarcode}
                        onChange={handleSearchChange}
                    />
                    {searchResult && <div>{searchResult}</div>}
                    <button type="button" onClick={toggleAddMethod}>Add Manually</button>
                </>
            ) : (
                <>
                    <select
                        value={selectedDatabase}
                        onChange={handleDatabaseChange}
                    >
                        <option value="">Select Database</option>
                        <option value="MedicineBags">Medicine Bags</option>
                        <option value="Carriers">Carriers</option>
                        <option value="Pouches">Pouches</option>
                        <option value="Vehicles">Vehicles</option>
                        <option value="ConsumableBatch">Consumables</option>
                        <option value="MedicineBatch">Medicines</option>
                    </select>
                    
                    <select value={selectedItem} onChange={handleItemSelectChange}>
                        <option value="">Select an item</option>
                        {databaseItems.map(item => (
                            <option key={item.id} value={item.id}>{item.label}</option>
                        ))}
                    </select>
                    
                    <button type="button" onClick={toggleAddMethod}>Add by Barcode</button>
                </>
            )}
        </div>
    );
};


export default ItemSelectionForm;
