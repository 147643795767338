import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm';
import { useSelector } from 'react-redux';  // Import useSelector

const ErrorReportForm = ({ token, onReload, selectedReport }) => { 
  const [description, setDescription] = useState(selectedReport ? selectedReport.Description : '');
  const [area, setArea] = useState(selectedReport ? selectedReport.Area : '');
  const userBarcode = useSelector((state) => state.user.userBarcode);  
  
  useEffect(() => {
    if (selectedReport) {
      setDescription(selectedReport.Description);
      setArea(selectedReport.Area);
    }
  }, [selectedReport]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('/api/ErrorReport/create/', {
        description,
        area,
        Requester_User_Barcode: userBarcode,  // Include userBarcode
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Error report submitted successfully.');
    } catch (error) {
      console.error('Error submitting report:', error);
    }

    if (onReload) {
      onReload();
    }
  };

  const handleRefresh = () => {
    setDescription('');
    setArea('');
  };

  const questions = [
    {
      type: 'textarea',
      text: 'Description of Error',
      value: description,
      handleChange: (e) => setDescription(e.target.value),
      rows: 5
    },
    
    {
      type: 'text',
      text: 'What page did this happen on (link if possible)',
      value: area,
      handleChange: (e) => setArea(e.target.value),
    }
  ];

  return (
    <DynamicForm
      title="Submit Error Report"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      showDeleteButton={false}
    />
  );
};

export default ErrorReportForm;
