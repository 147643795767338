import React, { useState } from 'react';
import Header from '../components/header';
import '../static/CSS/Reports.css';

function Reports() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = event => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const linkData = [
    { href: "/PriceReport", text: "Price" },
    { href: "/PouchStatusReport", text: "Pouch Status" },
    { href: "/PouchesPackedReport", text: "Pouch Packed" },
    { href: "/PouchDataWithDateReport", text: "Pouch History with Date input" },
    { href: "/StockHistoryDataWithDateReport", text: "Stock History with Date input" },
    { href: "/MedicineSummaryReport", text: "Pouch find by medicine name" },
    { href: "/AllPoucheContentsReport", text: "All Pouch Contents" },
    { href: "/MedicineBatchSummaryReport", text: "Medicine Batch Search Pouch Numbers" },
    { href: "/CarrierPackedContentsReport", text: "Carrier Contents" },
    { href: "/DrugAdministrationReportPage", text: "Drug Administration Report" },
    { href: "/PackedPouchesInMedicineBagReport", text: "Packed Pouches in Medicine Bag" }

  ];

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <h1 className="page-title">Reports</h1>
        <p className="description">This section includes various reports related to medicine packaging. Click on a report name to view it.</p>

        <div className="search-container">
          <input
            type="text"
            placeholder="Search for a report"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <button className="search-button">Search</button>
        </div>

        <div className="report-box">
          <ul className='report-list'>
            {linkData.map((link, index) => (
              <li key={index} className={link.text.toLowerCase().includes(searchTerm) ? "highlighted" : ""}>
                <a href={link.href} className="boxed-link">{link.text}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Reports;

