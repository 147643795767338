// src/components/CarrierInput.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCarrier, setCarrierBarcode } from '../../Redux/slices/userSlice';
import StyledDropdown from "../Styling components/StyledDropdown";

const CarrierInput = ({ token }) => {
    const [carriers, setCarriers] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [carrierBarcode, setCarrierBarcode] = useState('');
    const dispatch = useDispatch();
    const [successMessage, setSuccessMessage] = useState('');  // Add this line

    useEffect(() => {
        const fetchCarriers = async () => {
            try {
                const response = await axios.get('/api/Carrier/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setCarriers(response.data);
                }
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        };

        fetchCarriers();
    }, [token]);

    const handleCarrierChange = (e) => {
        setSelectedCarrier(e.target.value);
    };

    const handleCarrierBarcodeChange = (e) => {
        const enteredBarcode = e.target.value;
        setCarrierBarcode(enteredBarcode);
        
        // Filter carriers based on entered barcode
        const filteredCarriers = carriers.filter(carrier => {
            return carrier && carrier.carrier_barcode && carrier.carrier_barcode.includes(enteredBarcode);
        });
    
        setCarriers(filteredCarriers);
    
        // If there's a matching carrier, set the first one as the selected carrier
        if (filteredCarriers.length > 0 && filteredCarriers[0].carrier_number) {
            setSelectedCarrier(filteredCarriers[0].carrier_number);
        }
    };
    
    
    const dropdownOptions = carriers.map(carrier => {
        const carrierNumber = carrier && carrier.carrier_number ? carrier.carrier_number : "Unknown";
        return {
            value: carrierNumber,
            label: carrierNumber,
        };
    });
   

    const handleCarrierSubmit = (e) => {
        e.preventDefault();
        const selectedCarrierObject = carriers.find(carrier => carrier.carrier_number === selectedCarrier);
        if (selectedCarrierObject) {
            dispatch({ type: 'user/setCarrier', payload: selectedCarrierObject.carrier_number });
            dispatch({ type: 'user/setCarrierBarcode', payload: selectedCarrierObject.carrier_barcode });
            
            setSuccessMessage(`Carrier ${selectedCarrierObject.carrier_number} saved!`);
            
            // Optionally, clear the success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
        }
    };
    
    
    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Carrier Selection</h2>
                <div className="packing-main-content">
                    <form onSubmit={handleCarrierSubmit}>
                        <div className="input-group">
                            <label>Carrier Barcode</label>
                            <input 
                                type="text" 
                                value={carrierBarcode} 
                                onChange={handleCarrierBarcodeChange}
                                placeholder="Enter Carrier Barcode" 
                            />
                        </div>
                        <StyledDropdown
                            options={dropdownOptions}
                            value={selectedCarrier}
                            onChange={handleCarrierChange}
                            placeholder="Select Carrier"
                        />
                        {successMessage && <p className="success-message">{successMessage}</p>}  {/* Display success message here */}

                        <button  type="submit" className="card-button">Set Carrier</button>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default CarrierInput;
