import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CombinedPouchNumberInput from '../../components/PouchAdd/CombinedPouchNumberInput';
import headerImage from '../../static/images/Scanner.png';

import '../../static/CSS/HeaderPacking.css';

const PackingHeader = ({ token, showBarcodeInput, setShowBarcodeInput }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { name, station, pouchBarcode, pouchNumber } = user;

  const handlePackButtonClick = () => {
    navigate('/pack', {
      state: {
        user: user,
        stationName: station,
        pouchBarcode: pouchBarcode,
      },
    });
  };

  // Added return statement here
  return (
    <div className="packing-header-container">
      <div className="packing-header-box">
        <div className="text-content">
          <h2 className='packing-title'>Packing</h2>
          <p className="user-greeting">Hi {name}</p>
          <div className="button-group">
            <button className="pack-button" onClick={handlePackButtonClick}>Pack</button>
          </div>
        </div>
        <img src={headerImage} alt="Header" className="header-image"/>
      </div>

      <div className="packing-container">
        <div className="combined-medicine-upload">
          <CombinedPouchNumberInput token={token} />
        </div>
  </div>
      </div>
  );
};

export default PackingHeader;
