import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const DrugAdministrationReport = ({ token }) => {
  const [data, setData] = useState([]);

  const fetchDrugAdministrationReport = async () => {
    const response = await axios.get('api/drug_administration_report/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Unique ID': item.Unique_ID || '',
      'Medicine Bag Number': item.medicine_bag_number || '',
      'Pouch Number': item.Pouch_Number || '',
      'Name': item.Name || '',
      'Medicine Name': item.Medicine_Name || '',
      'ePCR Number': item.ePCR_Number || '',
      'Date and Time': item.Date_and_Time || '',
      'Administered mg': item.Administered_mg || '',
      'Wasted mg': item.Wasted_mg || ''
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchDrugAdministrationReport();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DrugAdministrationReport');
    XLSX.writeFile(wb, 'DrugAdministrationReport.xlsx');
  };

  const initialColumns = [
    'Unique ID', 'Medicine Bag Number', 'Pouch Number', 'Name', 
    'Medicine Name', 'ePCR Number', 'Date and Time', 'Administered mg', 'Wasted mg'
  ];
  const allColumns = [
    'Unique ID', 'Medicine Bag Number', 'Pouch Number', 'Name', 
    'Medicine Name', 'ePCR Number', 'Date and Time', 'Administered mg', 'Wasted mg'
  ];

  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default DrugAdministrationReport;
