import React, { useState, useEffect } from 'react';

const EditableTable = ({ 
  data, 
  columns: initialColumns, 
  columnTitles, 
  onRowSelect, 
  showSearchBar: initialShowSearchBar,
  showSelectButton
}) => {
  const [columns, setColumns] = useState(initialColumns || []);
  const [searchText, setSearchText] = useState({});
  const [isAddingColumn, setIsAddingColumn] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const safeColumnTitles = columnTitles || {};
  const [globalSearch, setGlobalSearch] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(initialShowSearchBar || false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showColumnSearch, setShowColumnSearch] = useState(false);
  const [displayData, setDisplayData] = useState([]);


  useEffect(() => {
    if (data.length > 0) {
      const allColumns = Object.keys(data[0]);
      setColumns(allColumns);
    }
  }, [data]);
  
    // Pagination

  const allColumnsWithID = [...columns, 'unique_ID'];
  const availableColumns = allColumnsWithID.filter(column => !columns.includes(column));

  useEffect(() => {
    if (initialColumns && initialColumns.length > 0) {
      setColumns(initialColumns);
    } else if (data.length > 0) {
      const allColumns = Object.keys(data[0]);
      setColumns(allColumns);
    }
  }, [data, initialColumns]);
  
  const handleAddColumn = () => {
    if (selectedColumn && availableColumns.includes(selectedColumn)) {
      setColumns([...columns, selectedColumn]);
      setIsAddingColumn(false);
      setSelectedColumn(null);
    }
  };

  const handleRemoveColumn = (columnToRemove) => {
    setColumns(columns.filter(column => column !== columnToRemove));
  };


  const handleMoveColumn = (columnToMove, direction) => {
    const index = columns.indexOf(columnToMove);
    if ((direction === 'left' && index > 0) || (direction === 'right' && index < columns.length - 1)) {
      const newColumns = [...columns];
      const temp = newColumns[index];
      newColumns[index] = newColumns[index + (direction === 'left' ? -1 : 1)];
      newColumns[index + (direction === 'left' ? -1 : 1)] = temp;
      setColumns(newColumns);
    }
  };

  const handleSearchChange = (column, value) => {
    setSearchText(prevState => ({ ...prevState, [column]: value }));
  };

  // Global search filter
  const getGlobalFilteredData = (data, globalSearch) => {
    if (!globalSearch) return data;
    return data.filter(row => {
      const isMatch = Object.values(row).some(value => {
        const match = value && value.toString().trim().toLowerCase().includes(globalSearch.trim().toLowerCase());
        return match;
      });
      return isMatch;
    });
  };

// Column search filter
const getColumnFilteredData = (data, searchText) => {
  return data.filter(row => {
    return columns.every(column => {
      const value = row[column] || '';
      const match = value.toString().toLowerCase().includes((searchText[column] || '').toLowerCase());
      return match;
    });
  });
};

  // Apply global search
  const globalFilteredData = getGlobalFilteredData(data, globalSearch);

  // Apply column search on globally filtered data
  const columnFilteredData = getColumnFilteredData(globalFilteredData, searchText);


  useEffect(() => {
    // First apply the global search to the full dataset
    const globalFilteredData = getGlobalFilteredData(data, globalSearch);
  
    // Then apply the column search to the globally filtered data
    const columnFilteredData = getColumnFilteredData(globalFilteredData, searchText);
  
    // Now apply the sorting to the column filtered data
    const sortedData = columnFilteredData.sort((a, b) => {


      if (sortColumn) {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) {
          return sortDirection === 'asc' ? -1 : 1;
        } else if (aValue > bValue) {
          return sortDirection === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  
    // Set the sorted and filtered data for display
    setDisplayData(sortedData);
  
  }, [globalSearch, searchText, data, sortColumn, sortDirection]);
  

  // Pagination should be based on displayData
  const totalRows = displayData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  // Inside the render method before returning the JSX:
  const startRowIndex = (currentPage - 1) * rowsPerPage;
  const endRowIndex = startRowIndex + rowsPerPage;
  const currentData = displayData.slice(startRowIndex, endRowIndex);

  // Implement the handlers for pagination
  const handlePreviousPage = () => {
    setCurrentPage(current => Math.max(current - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(current => Math.min(current + 1, totalPages));
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };


  const handleRowClick = (rowIndex) => {
    const dataRowIndex = startRowIndex + rowIndex;
    setSelectedRow(dataRowIndex);
    if (typeof onRowSelect === 'function') {
      onRowSelect(displayData[dataRowIndex]);
    }
  };
  

  const handleSearchBarToggle = (e) => {
    setShowSearchBar(e.target.checked);
    // If turning off, also clear the searchText state
    if (!e.target.checked) {
      setSearchText({});
    }
  };

  return (
    <div className="editable-table-container">
      <div className="top-controls">
        <div className="global-search-container">
          <input
            className="global-search-input"
            type="text"
            placeholder="Global Search..."
            value={globalSearch}
            onChange={(e) => setGlobalSearch(e.target.value)}
          />
        </div>
        <div className="show-entries-container">
          Show
          <select
            className="entries-select"
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          entries
        </div>
        <div className="search-toggle-container">
          <label className="search-toggle-label">
            Show Search of each header
            <input
              type="checkbox"
              checked={showSearchBar}
              onChange={handleSearchBarToggle}
            />
          </label>
        </div>
      </div>
      <div className="table-wrapper">
        <table className="EditableTable">
          <thead>
            <tr>
              {showSelectButton && <th>Select</th>}
              {columns.map((column) => (
                <th key={column} style={{ position: 'relative' }}>
                  <div className="columnHeader">
                    <button
                      className="sortButton"
                      onClick={() => handleSort(column)}
                      data-direction={sortColumn === column ? sortDirection : null}
                    >
                      {safeColumnTitles[column] || column}
                    </button>
                  </div>
                  {showSearchBar && (
                    <input
                      type="text"
                      placeholder={`Search ${column}`}
                      value={searchText[column] || ''}
                      onChange={(e) => handleSearchChange(column, e.target.value)}
                    />
                  )}
                  <button
                    className="editable-table-button"
                    onClick={() => handleMoveColumn(column, 'left')}
                  >
                    ◄
                  </button>
                  <button
                    className="editable-table-button"
                    onClick={() => handleMoveColumn(column, 'right')}
                  >
                    ►
                  </button>
                  <button
                    className="removeButton"
                    onClick={() => handleRemoveColumn(column)}
                  >
                    🗑
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => handleRowClick(rowIndex)}
                className={rowIndex === selectedRow - startRowIndex ? 'selectedRow' : ''}
              >
                {showSelectButton && (
                  <td>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handleRowClick(rowIndex);
                      }}
                    >
                      {rowIndex === selectedRow ? 'Selected' : 'Select'}
                    </button>
                  </td>
                )}
                {columns.map((column) => (
                  <td key={column}>{row[column] || ''}</td>
                ))}
              </tr>
            ))}
          </tbody>
          </table>
        </div>
        <div className="pagination-controls">
          <div className="showing-entries">
            Showing {startRowIndex + 1} to {Math.min(endRowIndex, totalRows)} of {totalRows} entries
          </div>
          <div className="pagination-inner">
            <span className="page-item" onClick={() => handlePreviousPage()} disabled={currentPage === 1}>
              Previous
            </span>
            {[...Array(totalPages).keys()].map((page) => (
              <span
                key={page}
                className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}
                onClick={() => setCurrentPage(page + 1)}
              >
                {page + 1}
              </span>
            ))}
            <span className="page-item" onClick={() => handleNextPage()} disabled={currentPage === totalPages}>
              Next
            </span>
          </div>
        </div>
      </div>
    );
  
};

export default EditableTable;
