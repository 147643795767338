//-- consumableBatchForm 

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const ConsumableBatchForm  = ({ token, selectedconsumable }) => {
  const [consumable_name, setconsumableName] = useState('');
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const [batch_number, setBatchNumber] = useState('');
  const [expiry_date, setExpiryDate] = useState('');
  const [consumable_pricing, setconsumablePricing] = useState('');
  const [product_barcode, setproductBarcode] = useState('');
  const [selectedconsumableId, setSelectedconsumableId] = useState(null);
  const [supplier, setSupplier] = useState('');
  const [pack_size, setPackSize] = useState('');
  const [consumables, setconsumables] = useState([]);


  useEffect(() => {
    fetchconsumables();
    if (selectedconsumable) {
      setconsumableName(selectedconsumable['consumable name'],);
      setBatchNumber(selectedconsumable['Batch Number']);
      if (selectedconsumable['Expiry Date']) {
        const [day, month, year] = selectedconsumable['Expiry Date'].split('/');
        const expiryDateObj = new Date(`${year}-${month}-${day}`);
        if (!isNaN(expiryDateObj)) {
            const formattedExpiryDate = expiryDateObj.toISOString().split('T')[0];
            setExpiryDate(formattedExpiryDate);
        } else {
            console.error('Invalid expiry date:', selectedconsumable['Expiry Date']);
        }
    }
    
      setconsumablePricing(selectedconsumable['consumable Pricing']);
      setproductBarcode(selectedconsumable['product Barcode']);
      setPackSize(selectedconsumable['Pack Size']);
      setSupplier(selectedconsumable['Supplier']);
      setSelectedconsumableId(selectedconsumable.Unique_id);
    }
  }, [selectedconsumable]);


  const handleGenerateBarcode = async () => {
    const prefix = 'CB'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setproductBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  
  

  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/ConsumableBatch/create/', {
        consumable_name,
        batch_number,
        expiry_date,
        consumable_pricing,
        product_barcode: String(product_barcode),
        added_by: userBarcode,
        date_added: new Date().toISOString().split('T')[0],
        supplier,
        pack_size,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error creating consumable batch:', error);
    }
  };
  
  
  

    const handleRefresh = () => {
        setconsumableName('');
        setBatchNumber('');
        setExpiryDate('');
        setconsumablePricing('');
        setproductBarcode('');
        setSupplier('');
        setPackSize('');
        setSelectedconsumableId(null);
    };

    const handleDeleteConfirmation = () => {
        if (window.confirm(`Are you sure you want to delete batch number ${batch_number} for ${consumable_name}?`)) {
        handleDelete();
        }
    };

    const handleUpdate = async () => {
      try {
        await axios.put(`/api/ConsumableBatch/update/${selectedconsumableId}/`, {
          consumable_name,
          batch_number,
          expiry_date,
          consumable_pricing,
          product_barcode,
          added_by: userBarcode,
          date_added: new Date().toISOString().split('T')[0],
          supplier,
          pack_size,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        window.location.reload();
      } catch (error) {
        console.error('Error updating consumable batch:', error);
      }
    };
    
    
  
  
  
    const handleDelete = async () => {
      try {
        await axios.delete(`/api/ConsumableBatch/delete/${selectedconsumableId}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        window.location.reload();
      } catch (error) {
        console.error('Error deleting consumable batch:', error);
      }
    };
    
    

    
    const fetchconsumables = async () => {
      try {
        const response = await axios.get('/api/ConsumableBatch/');
        if (Array.isArray(response.data)) {
          setconsumables(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      } catch (error) {
        console.error('Error fetching consumables:', error);
      }
    };
    
    

    return (
      <DynamicForm
        title="Add New consumable Batch"
        questions={[
          {
            type: 'text',
            text: 'consumable Name',
            value: consumable_name,
            handleChange: (e) => setconsumableName(e.target.value)
          },
          {
            type: 'text',
            text: 'Batch Number',
            value: batch_number,
            handleChange: (e) => setBatchNumber(e.target.value),
          },
          {
            type: 'date',
            text: 'Expiry Date',
            value: expiry_date,
            handleChange: (e) => setExpiryDate(e.target.value),
          },
          {
            type: 'text',
            text: 'consumable Pricing',
            value: consumable_pricing,
            handleChange: (e) => setconsumablePricing(e.target.value),
          },
          {
            type: 'text',
            text: 'product Barcode',
            value: product_barcode,
            handleChange: (e) => setproductBarcode(e.target.value),
            isLocked: true 
          },
          {
            type: 'text',
            text: 'Supplier',
            value: supplier,
            handleChange: (e) => setSupplier(e.target.value),
          },
          {
            type: 'number',
            text: 'Pack Size',
            value: pack_size,
            handleChange: (e) => setPackSize(e.target.value),
          },
          ...(!product_barcode ? [{
            text: 'Generate Barcode',
            type: 'generateBarcode'
          }] : [])
        ]}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleUpdate={handleUpdate}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleGenerateBarcode={() => handleGenerateBarcode(setproductBarcode)} 
        setproductBarcode={setproductBarcode} 
        selectedId={selectedconsumableId}
        showSearchBar={true}
      />
    );
  }

export default ConsumableBatchForm;