import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useUnpackProductLocationAndCarrierHistorySubmit from './AllProductUnpack/UnpackProductLocationAndCarrierHistorySubmit';
import ProductDetailsCard from '../Display/ProductDetailsCard'; // Updated import

const UnpackCarrierCombined = ({ token, onFormSubmit }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [pouchDetails, setPouchDetails] = useState([]);
    const [filteredPouchDetails, setFilteredPouchDetails] = useState([]);
    const [selectedPouchBarcode, setSelectedPouchBarcode] = useState('');
    const [selectedProductDetail, setSelectedProductDetail] = useState(''); 
    const user = useSelector(state => state.user);
    const { unpackSinglePouch } = useUnpackProductLocationAndCarrierHistorySubmit(token, user);

    useEffect(() => {
        const fetchPouchDetails = async () => {
            try {
                const response = await axios.get('/api/packed_products_in_carrier/', {
                    params: { Carrier_Barcode: user.carrierBarcode },
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPouchDetails(response.data); // Assuming response.data is the array of pouch details
            } catch (error) {
                console.error('Error fetching pouch details:', error);
            }
        };
        fetchPouchDetails();
    }, [token, user.carrierBarcode]);

    useEffect(() => {
        setFilteredPouchDetails(
            pouchDetails.filter(detail =>
                detail.Product_Detail.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, pouchDetails]);

    const unpackAll = async () => {
        const confirmUnpack = window.confirm(`Are you sure you want to unpack all contents of ${user.carrier} to station: ${user.station}?`);
        if (!confirmUnpack) {
            return;
        }

        const dataToUpdate = {
            Carrier_Barcode: user.carrierBarcode,  // Add the Carrier_Barcode
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            Carrier_Status: "Unpacked"
        };
    
        try {
            const response = await axios.put('/api/CarrierHistory/updateAll/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert('All pouches unpacked successfully!');
            } else {
                alert('Error unpacking pouches.');
                console.log('UnpackAll Response:', response.data);  // Log the response data
            }
        } catch (error) {
            console.error('Error unpacking pouches:', error);
        }
        onFormSubmit();

    };


    const handleDirectUnpack = async (e) => {
        e.preventDefault();
        await unpackSinglePouch(selectedPouchBarcode, onFormSubmit);
    };

    const handleSelectPouchDetail = (selectedDetail) => {
        const selected = pouchDetails.find(detail => detail.Product_Detail === selectedDetail);
        if (selected) {
            setSelectedPouchBarcode(selected.Product_Barcode); // Update the barcode
            setSelectedProductDetail(selectedDetail); // Update the state to control the select element
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Carrier Unpacking</h2>
                <h3>{user.carrier}</h3>
                <div className="packing-main-content">
                <form>
                    <div className="input-group">
                        <label>Enter Product Barcode to Unpack:</label>
                        <input
                            type="text"
                            value={selectedPouchBarcode}
                            onChange={e => setSelectedPouchBarcode(e.target.value)}
                            placeholder="Enter Product Barcode"
                        />
                    </div>
                </form>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    placeholder="Search Product Detail"
                />
                <select onChange={e => handleSelectPouchDetail(e.target.value)} value={selectedProductDetail}> {/* Update value prop */}
                    <option value="">Select Product Detail</option>
                    {filteredPouchDetails.map((detail, index) => (
                        <option key={index} value={detail.Product_Detail}>{detail.Product_Detail}</option>
                    ))}
                </select>
                <button onClick={handleDirectUnpack} className="card-button">Unpack to: {user.station}</button> {/* Moved and changed to onClick */}
                <button onClick={unpackAll} className="card-button">Unpack All to {user.station}</button>

                </div>
                <ProductDetailsCard
                token={token}
                productBarcode={selectedPouchBarcode}
            />   
            </div>
        </div>
    );
};

export default UnpackCarrierCombined;
