import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';  

const CarrierTable = ({ token, reloadFlag }) => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);  
  const carrierBarcode = user.carrierBarcode;  

  useEffect(() => {

    // Fetch all packed pouches for the carrier
    const fetchPackedPouches = async () => {
      try {
        const response = await axios.get(`api/packed_products_in_carrier?Carrier_Barcode=${carrierBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error('Error fetching packed pouches:', error);
      }
    };

    if (carrierBarcode) {  
      fetchPackedPouches();
    }
  }, [token, carrierBarcode, reloadFlag])
  const displayColumns = ['Product_Detail', 'Product_Type', 'Carrier_Status', 'Carrier_Number'];
  
  // Define column titles mapping
  const columnTitles = {
    'Product_Detail': 'Product Number',
    'Product_Type': 'Product Type',
    'Carrier_Status': 'Carrier Status',
    'Carrier_Number': 'Carrier Number'
  }; 

  return (
    <div>
      <Table data={data} columns={displayColumns} columnTitles={columnTitles} />
    </div>
  );
};

export default CarrierTable;
