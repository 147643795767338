import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StorageBinForm from './StorageBinForm';

const StorageBinTable = ({ token }) => {
  const [bins, setBins] = useState([]);
  const [selectedBin, setSelectedBin] = useState(null);
  const [storageUnits, setStorageUnits] = useState([]);

  useEffect(() => {
    fetchStorageUnits().then(() => {
      fetchBins();
    });
  }, []);

  const fetchStorageUnits = async () => {
    try {
      const response = await axios.get('/api/storage-unit/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStorageUnits(response.data);
      return response.data; // Return storage units for chaining if needed
    } catch (error) {
      console.error('Error fetching storage units:', error);
    }
  };

  const fetchBins = async () => {
    try {
      const response = await axios.get('/api/bin/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const augmentedBins = response.data.map(bin => {
        // Find the storage unit by ID
        const storageUnit = storageUnits.find(unit => unit.id === bin.storage_unit);
        // Replace storage_unit ID with Unit_Name, or keep original ID if not found
        return { ...bin, storage_unit: storageUnit ? storageUnit.unit_name : bin.storage_unit };
      });
      setBins(augmentedBins);
    } catch (error) {
      console.error('Error fetching bins:', error);
    }
  };

  const handleRowClick = (bin) => {
    setSelectedBin(bin);
  };

  const onFormSubmit = () => {
    fetchStorageUnits().then(() => {
      fetchBins(); // Refresh the list of bins
    });
    setSelectedBin(null); // Reset the selected bin
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <StorageBinForm 
        token={token} 
        selectedBin={selectedBin} 
        storageUnits={storageUnits} 
        onFormSubmit={onFormSubmit} 
      />
      <table>
        <thead>
          <tr>
            <th>Bin Barcode</th>
            <th>Bin Name</th>
            <th>Storage Unit Name</th> {/* Updated header */}
            <th>Capacity</th>
            <th>Reorder Level</th>
          </tr>
        </thead>
        <tbody>
          {bins.map((bin) => (
            <tr key={bin.bin_barcode} onClick={() => handleRowClick(bin)}>
              <td>{bin.bin_barcode}</td>
              <td>{bin.bin_name}</td>
              <td>{bin.storage_unit}</td> {/* Now shows Unit_Name */}
              <td>{bin.capacity}</td>
              <td>{bin.Reorder_Level}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StorageBinTable;
