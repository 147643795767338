import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../static/CSS/Pouchdetails.css';

const ProductDetailsCard = ({ token, productBarcode, onStoredInChange, onPouchStatusChange }) => {
    const [productNumber, setProductNumber] = useState('Awaiting Input...');
    const [location, setLocation] = useState('');
    const [productType, setProductType] = useState('');
    const [storedIn, setStoredIn] = useState('');
    const [pouchStatus, setPouchStatus] = useState('');


    useEffect(() => {
        // Whenever storedIn changes and is not empty, call the callback function
        if (storedIn && onStoredInChange) {
            onStoredInChange(storedIn);
        }
    }, [storedIn, onStoredInChange]); 
    useEffect(() => {
        // Call onPouchStatusChange with pouchStatus, can be null
        if (onPouchStatusChange) {
            onPouchStatusChange(pouchStatus);
        }
    }, [pouchStatus, onPouchStatusChange]);

    useEffect(() => {
        if (productBarcode) {
            const determinedType = determineProductTypeAndFetchDetails(productBarcode);
            // Conditional check before fetching location and stored in information
            if (!['Consumable', 'Medicine'].includes(determinedType)) {
                fetchItemLocation();
                checkProductStoredin();
            }
            if (determinedType === 'Pouch') {
                fetchPouchStatus(productBarcode);
            }
        }
    }, [productBarcode, token]);

    const determineProductTypeAndFetchDetails = (barcode) => {
        const type = barcode.startsWith('M') ? 'Medicine'
                  : barcode.startsWith('C') && !barcode.startsWith('CB') ? 'Carrier'
                  : barcode.startsWith('CB') ? 'Consumable'
                  : barcode.startsWith('B') ? 'MedicineBag'
                  : barcode.startsWith('P') ? 'Pouch'
                  : barcode.startsWith('V') ? 'Vehicle'
                  : 'Unknown';
        setProductType(type); // This will trigger the useEffect hook
        fetchProductDetails(type, barcode);
        return type; // Return type for conditional logic in useEffect
    }

    const fetchProductDetails = async (type, barcode) => {
        let endpoint = '';
        switch(type) {
            case 'Medicine':
                endpoint = `/api/MedicineBatch/${barcode}`;
                break;
            case 'Carrier':
                endpoint = `/api/Carrier/${barcode}`;
                break;
            case 'Consumable':
                endpoint = `/api/ConsumableBatch/${barcode}`;
                break;
            case 'MedicineBag':
                endpoint = `/api/MedicineBags_Barcode/${barcode}`;               
                break;
            case 'Pouch':
                endpoint = `/api/Pouch/${barcode}`;
                break;
            case 'Vehicle':
                endpoint = `/api/Vehicle/${barcode}`;
                break;
            default:
                console.log("Unknown product type for barcode:", barcode);

                return;
        }

        try {
            const response = await axios.get(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data) {
                // Handling the response based on the product type
                switch (type) {
                    case 'Vehicle':
                        setProductNumber(response.data.vehicle_number || 'Number Not Available');
                        break;
                    case 'Medicine':
                        setProductNumber(response.data.medicine_name || 'Number Not Available');
                        break;
                    case 'Consumable':
                        setProductNumber(response.data.consumable_name || 'Number Not Available');
                        break;
                    case 'Carrier':
                        setProductNumber(response.data.carrier_number || 'Number Not Available');
                        break;
                    case 'MedicineBag':
                        setProductNumber(response.data.medicine_bag_number || 'Number Not Available');
                        break;
                    case 'Pouch':
                        setProductNumber(response.data.pouch_number || 'Number Not Available');
                        break;
                    default:
                        console.log(`No handling for the ${type} type.`);
                }
            }
        } catch (error) {
            console.error(`Error fetching details for ${type}:`, error);
        }
    };

    const fetchItemLocation = async () => {
        try {
            const response = await axios.get(`/api/item-location/${productBarcode}/`, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Check if the response data array is not empty and has a Station_Name
            if (response.data.length > 0 && response.data[0].Station_Name) {
                const locationData = response.data[0]; // Access the first element of the array
                setLocation(`${locationData.Station_Name} on ${new Date(locationData.Datetime).toLocaleString()}`);
            } else {
                setLocation('Location details not available');
            }
        } catch (error) {
            console.error('Error fetching item location:', error); // Log any errors
            setLocation('Error fetching location details');
        }
    };

    const checkProductStoredin = async () => {
        if (!productBarcode) {
            console.log("No productBarcode provided, skipping location check.");
            setStoredIn('No barcode provided');
            return;
        }

        try {
            // Make the API call to get the packed products in the carrier
            let response = await axios.get(`/api/packed_products_in_carrier/?Product_Barcode=${productBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });


            // Check if the product is in the response and matches the current carrier barcode
            const productInCarrier = response.data.find(entry => entry.Product_Barcode === productBarcode);
            if (productInCarrier) {
                // If the product is in the current carrier, set the state accordingly
                setStoredIn(`In Carrier: ${productInCarrier.Carrier_Number}`);
            } else {
                // Check if in a medicine bag
                response = await axios.get(`/api/packed_products_in_medicine_bag/?Product_Barcode=${productBarcode}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const productInMedicineBag = response.data.find(entry => entry.Product_Barcode === productBarcode);
                if (productInMedicineBag) {
                    setStoredIn(`In Medicine Bag: ${productInMedicineBag.medicine_bag_number}`);
                } else {
                    // Check if in a vehicle
                    response = await axios.get(`/api/packed_products_in_vehicle/?Product_Barcode=${productBarcode}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
    
                    const productInVehicle = response.data.find(entry => entry.Product_Barcode === productBarcode);
    
                    if (productInVehicle) {
                        setStoredIn(`In Vehicle: ${productInVehicle.Vehicle_Number}`);
                    } else {
                        // If the product is not stored in any of the above, set the state accordingly
                        setStoredIn('Not stored in carriers, bags, or vehicles');
                    }
                }
            }
        } catch (error) {
            console.error('Error checking product location:', error);
            setStoredIn('Error checking location');
        }
    };

    // New function to fetch pouch status
    const fetchPouchStatus = async (barcode) => {
        try {
            const response = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${barcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.length > 0 && response.data[0].Pouch_Status) {
                setPouchStatus(response.data[0].Pouch_Status); // Assuming the first item has the status
            } else {
                setPouchStatus('Status not available');
            }
        } catch (error) {
            console.error('Error fetching pouch status:', error);
            setPouchStatus('Error fetching status');
        }
    };

    return (
        <div className="product-details-card">
            <h3 className="product-details-title">Product Details</h3>
            <div className="product-detail">
                <span className="product-detail-key">Product Barcode:</span>
                <span className="product-detail-value">{productBarcode}</span>
            </div>
            <div className="product-detail">
                <span className="product-detail-key">Product Type:</span>
                <span className="product-detail-value">{productType}</span>
            </div>
            <div className="product-detail">
                <span className="product-detail-key">Product Number:</span>
                <span className="product-detail-value">{productNumber}</span>
            </div>
            {productType === 'Pouch' && pouchStatus && ( 
                <div className="product-detail">
                    <span className="product-detail-key">Pouch Status:</span>
                    <span className="product-detail-value">{pouchStatus}</span>
                </div>
            )}
            {productType !== 'Consumable' && productType !== 'Medicine' && storedIn && (
                <div className="product-detail">
                    <span className="product-detail-key">Stored in:</span>
                    <span className="product-detail-value">{storedIn}</span>
                </div>
            )}
            {productType !== 'Consumable' && productType !== 'Medicine' && location && (
                <div className="product-detail">
                    <span className="product-detail-key">Location:</span>
                    <span className="product-detail-value">{location}</span>
                </div>
            )}
        </div>
    );
};

export default ProductDetailsCard;
