import Header from '../components/header'
import MedicineBatchTable from '../components/EditTables/MedicineBatchTable';

const token = localStorage.getItem("token");

function EditMedicineBatch() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <MedicineBatchTable token={token} />     
        </div>
    </div>
  );
}

export default EditMedicineBatch;