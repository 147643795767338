import React, { useState } from 'react';
import axios from 'axios';

const ResetPasswordPage = ({ match }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { uid, token } = match.params;

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(`/api/reset_password/${uid}/${token}/`, {
        new_password: newPassword,
        confirm_password: confirmPassword,
      });

      if (response.status === 200) {
        alert('Password has been reset');
        // Redirect to login page
      }
    } catch (error) {
      alert('Failed to reset password');
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      <button onClick={handleResetPassword}>Reset Password</button>
    </div>
  );
};

export default ResetPasswordPage;
