import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useProductLocationSubmit from '../Location/ProductLocationForm'; // Ensure this path matches your file structure

const MedicineBagPack = ({token}) => {
    const [pouchBarcode, setPouchBarcode] = useState('');
    const [pouchStatus, setPouchStatus] = useState('');
    const [confirmAction, setConfirmAction] = useState(false);
    const user = useSelector((state) => state.user);
    const [pouchNumber, setPouchNumber] = useState(''); // New state for Pouch Number
    const submitProductLocation = useProductLocationSubmit(token); // Using the custom hook

    useEffect(() => {
        const fetchPouchStatus = async () => {
            try {
                const response = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${pouchBarcode}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                if (response.data[0] && response.data[0].Pouch_Status) {
                    setPouchStatus(response.data[0].Pouch_Status);
                } else {
                    setPouchStatus('Status Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch status:', error);
            }
        };
        
        const fetchPouchNumber = async () => {
            try {
                const response = await axios.get(`/api/PouchBarcode/${pouchBarcode}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                if (response.data && response.data.pouch_number) {
                    setPouchNumber(response.data.pouch_number);
                } else {
                    setPouchNumber('Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch number:', error);
            }
        };
    
        if (pouchBarcode) {
            fetchPouchStatus();
            fetchPouchNumber();
        }
    }, [pouchBarcode, token]);

    const handleProceed = () => {
        setConfirmAction(false);
        submitMedicineBagHistoryAndUpdateLocation();
    };

    const handleCancel = () => {
        setConfirmAction(false);
        setPouchBarcode('');
    };

    const submitMedicineBagHistoryAndUpdateLocation = async () => {
        // Assuming you have already defined the necessary data for the medicine bag history
        const medicineBagHistoryData = {
            MedicineBag_Barcode: user.Medicine_bagBarcode,
            Pouch_Barcode: pouchBarcode,
            Station_Barcode: user.station_barcode,
            datetime_start: new Date().toISOString(),
            amount: 1,
            User_Packed: user.userBarcode,
            MedicineBag_Status: "Packed"
        };
    
        try {
            // Submit the medicine bag history data to your API
            const response = await axios.post('/api/MedicineBagHistory/create/', medicineBagHistoryData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
    
            // Check if the submission was successful
            if (response.status === 200 || response.status === 201) {
                // Prepare the product location data
                const productLocationData = {
                    Barcode: medicineBagHistoryData.MedicineBag_Barcode, // Use the Medicine Bag Barcode as the product barcode
                    Location_Barcode: medicineBagHistoryData.Station_Barcode, // Use the station barcode as the location barcode
                    Datetime: medicineBagHistoryData.datetime_start, // Use the current datetime
                };
    
                // Call the custom hook or function to submit the product location data
                // Replace `useProductLocationSubmit` with your actual function or hook that handles the submission
                submitProductLocation(productLocationData, 'create',
                    (successMessage) => {
                        alert('Medicine bag history and product location updated successfully!');
                        // Additional success handling logic here
                    },
                    (errorMessage) => {
                        alert(`Error updating product location: ${errorMessage}`);
                        // Additional error handling logic here
                    }
                );
            } else {
                // Handle the case where the medicine bag history submission was not successful
                alert('Error adding medicine bag history. Please try again.');
            }
        } catch (error) {
            console.error('Error adding medicine bag history:', error);
            alert('An error occurred while adding medicine bag history. Please check the console for details.');
        }
    };
    

    const checkPouchExistsInMedicineBagHistory = async (barcode) => {
        try {
            const response = await axios.get(`/api/MedicineBagHistory/?pouchBarcode=${barcode}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            for (let pouchData of response.data) {
                if (pouchData.Pouch_Barcode === barcode && pouchData.Medicine_Bag_Status === 'Packed') {
                    const medicineBagResponse = await axios.get(`/api/Medicine_Bag/?Medicine_Bag_Barcode=${pouchData.Medicine_bagBarcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const medicineBagData = medicineBagResponse.data && medicineBagResponse.data[0];
                    return {
                        exists: true,
                        medicineBagNumber: medicineBagData ? medicineBagData.medicine_bag_number : "Unknown"
                    };
                }
            }
            return { exists: false };
    
        } catch (error) {
            console.error('Error checking pouch in MedicineBagHistory:', error);
            return { exists: false };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const pouchExistsInfo = await checkPouchExistsInMedicineBagHistory(pouchBarcode);

        if (pouchExistsInfo.exists) {
            alert(`This pouch is currently being shown as packed in the ${pouchExistsInfo.medicineBagNumber}`);
            setPouchBarcode('');
            return;
        }
    
        if (pouchStatus === 'Unpacked' || pouchStatus === 'Status Not Available') {
            alert("This is showing as unpacked. Please ensure it is packed");
            setPouchBarcode('');
            return;
        }
    
        if (pouchStatus === 'QAed') {
            submitMedicineBagHistoryAndUpdateLocation();
            return;
        }
    
        if (pouchStatus === 'Partially QAed' || pouchStatus === 'Packed') {
            setConfirmAction(true);
            return;
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Pack Medicine Bag</h2>
                <div className="packing-main-content">
                    {confirmAction ? (
                        <div>
                            <p>This is showing as {pouchStatus} and has not been QAed. Are you sure you want to continue?</p>
                            <button onClick={handleProceed} className="card-button">Yes</button>
                            <button onClick={handleCancel} className="card-button">No</button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label>Pouch Barcode</label>
                                <input 
                                    type="text"
                                    value={pouchBarcode}
                                    onChange={(e) => setPouchBarcode(e.target.value)}
                                    placeholder="Enter Pouch Barcode"
                                    />
                                    <p>Pouch Number: {pouchNumber}</p> {/* Displaying the fetched Pouch Number */}
                                </div>
                            <button type="submit" className="card-button">Pack to Medicine Bag {user.medicineBag}</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MedicineBagPack;
