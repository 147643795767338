import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';


const StoreLevels = ({ token }) => {
  const [storesData, setStoresData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);

  
  useEffect(() => {
    fetchStoreData();
  }, []);

  const fetchStoreData = async () => {
    try {
      const response = await axios.get('/api/pouch_medicine_data_stores/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });


      const formattedData = response.data.map(item => ({
        'Medicine Name': item.Medicine_Name,
        'Batch Number': item.Batch_Number,
        'Amount': item.Total_Amount,
        'Expiry Date': item.Expiry_Date,
        'Comments': item.comments,
      }));
      setData(formattedData);

      if (response.status === 200) {
        const data = response.data;
        setStoresData(data);
      } else {
        console.error('Error fetching store levels data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching store levels data:', error);
    }
  };
  const initialColumns = ['Medicine Name', 'Batch Number','Amount', 'Expiry Date'];
  const allColumns = ['Medicine Name', 'Batch Number','Amount', 'Expiry Date'];

  const handleRowSelect = (selectedRow) => {
    setSelectedMedicine(selectedRow['Medicine Name']);
    setSelectedMedicineId(selectedRow.Unique_id);
  };

  return (
    <div className="store-levels-container">
        <EditableTable 
            token={token} 
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns} // Add this
            allColumns={allColumns} // Add this
            data={data}
          />
    </div>
  );
};

export default StoreLevels;
