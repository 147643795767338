import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const PouchForm = ({ token, selectedPouch }) => {
  const [pouchNumber, setPouchNumber] = useState('');
  const [pouchType, setPouchType] = useState('');
  const [pouchBarcode, setPouchBarcode] = useState('');
  const [selectedPouchId, setSelectedPouchId] = useState(null);

  useEffect(() => {
    fetchPouchTypes();
    if (selectedPouch) {
      setPouchNumber(selectedPouch.pouch_number);
      setPouchType(selectedPouch.pouch_type);
      setPouchBarcode(selectedPouch.pouch_barcode);
      setSelectedPouchId(selectedPouch.pouch_barcode);
    }
  }, [selectedPouch]);

  const handleGenerateBarcode = async () => {
    const prefix = 'P'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setPouchBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/Pouch/create/', {
        pouch_number: pouchNumber,
        pouch_type: pouchType,
        pouch_barcode: pouchBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      await axios.post('/api/PouchStatus/create/', {
        pouch_barcode: pouchBarcode,
        status: "Unpacked",
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
   
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  

  const handleRefresh = () => {
    setPouchNumber('');
    setPouchType('');
    setPouchBarcode('');
    setSelectedPouchId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete pouch with barcode ${pouchBarcode}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/Pouch/update/${selectedPouchId}/`, {
        pouch_number: pouchNumber,
        pouch_type: pouchType,
        pouch_barcode: pouchBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      if (selectedPouchId !== pouchBarcode) {
        await axios.put(`/api/PouchStatus/update/${selectedPouchId}/`, {
          pouch_barcode: pouchBarcode,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      }
    
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/Pouch/delete/${selectedPouchId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      await axios.delete(`/api/PouchStatus/delete/${selectedPouchId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  

  const [pouchTypes, setPouchTypes] = useState([]);
  const fetchPouchTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      const response = await fetch('/api/PouchTypeName/', requestOptions); 
      if (response.ok) {
        const data = await response.json();
        setPouchTypes(data);
      } else {
        console.error('Error fetching pouch types:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching pouch types:', error);
    }
  };


  const questions = [
    {
      text: 'Pouch Number:',
      value: pouchNumber,
      handleChange: (e) => setPouchNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Pouch Type:',
      value: pouchType,
      handleChange: (e) => setPouchType(e.target.value),
      options: pouchTypes.map((type) => ({ value: type.pouch_type_name, label: type.pouch_type_name })),
      type: 'select',
      styled: true,
    },
    {
      text: 'Pouch Barcode:',
      value: pouchBarcode,
      handleChange: (e) => setPouchBarcode(e.target.value),
      type: 'text',
      isLocked: true 
    },

      ...(!pouchBarcode ? [{
        text: 'Generate Barcode',
        type: 'generateBarcode'
      }] : [])
  ];

  return (
    <DynamicForm
      title="Add New Pouch"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      handleGenerateBarcode={handleGenerateBarcode}
      selectedId={selectedPouchId}
      setDrugBarcode={setPouchBarcode}
    />
  );
};

export default PouchForm;

