import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import CarrierTable from '../components/CarrierTrack/CarrierTable';
import UnpackCarrierCombined from '../components/CarrierTrack/UnpackCarrierCombined';



const CarrierUnpackPage = () => {
    const token = localStorage.getItem("token");
    const [refreshTable, setRefreshTable] = useState(false);  // State to trigger table refresh

    // Function to be called by child components upon successful form submission
    const handleRefreshTable = () => {
        setRefreshTable(prev => !prev);  // Toggle the state to trigger a refresh
    };

    return (
        <div className='main-content'>
            <Header token={token}/>
            
            <UnpackCarrierCombined token={token} onFormSubmit={handleRefreshTable}/>
            <CarrierTable token={token} key={refreshTable}/>  {/* Use key prop to trigger re-render */}
        </div>
    );
};

export default CarrierUnpackPage;

