// src/Redux/slices/permissionSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState: [],
  reducers: {
    setPermissions: (state, action) => {
      return action.payload;
    },
  },
});

export const { setPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;

