import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const VehicleForm = ({ token, selectedVehicle }) => {
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleBarcode, setVehicleBarcode] = useState('');
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);

  useEffect(() => {
    if (selectedVehicle) {
      setVehicleNumber(selectedVehicle.vehicle_number);
      setVehicleType(selectedVehicle.vehicle_type);
      setVehicleBarcode(selectedVehicle.vehicle_barcode);
      setSelectedVehicleId(selectedVehicle.vehicle_barcode);
    }
  }, [selectedVehicle]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const vehicleResponse = await axios.post('/api/Vehicle/create/', {
        vehicle_number: vehicleNumber,
        vehicle_type: vehicleType,
        vehicle_barcode: vehicleBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (vehicleResponse.status === 201) {
        window.location.reload();
      } else {
        console.error('Error creating vehicle:', vehicleResponse.statusText);
      }
    } catch (error) {
      console.error('Error creating vehicle:', error);
    }
  };
  
  const handleRefresh = () => {
    setVehicleNumber('');
    setVehicleType('');
    setVehicleBarcode('');
    setSelectedVehicleId(null);
  };

  const handleUpdate = async () => {
    try {
      const vehicleResponse = await axios.put(`/api/Vehicle/update/${selectedVehicleId}/`, {
        vehicle_number: vehicleNumber,
        vehicle_type: vehicleType,
        vehicle_barcode: vehicleBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (vehicleResponse.status === 200) {
        window.location.reload();
      } else {
        console.error('Error updating vehicle:', vehicleResponse.statusText);
      }
    } catch (error) {
      console.error('Error updating vehicle:', error);
    }
  };
  

  const handleDelete = async () => {
    try {
      const vehicleResponse = await axios.delete(`/api/Vehicle/delete/${selectedVehicleId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (vehicleResponse.status === 200) {
        window.location.reload();
      } else {
        console.error('Error deleting vehicle:', vehicleResponse.statusText);
      }
    } catch (error) {
      console.error('Error deleting vehicle:', error);
    }
  };
  

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete vehicle with barcode ${vehicleBarcode}?`)) {
      handleDelete();
    }
  };

  const handleGenerateBarcode = async () => {
    const prefix = 'V'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setVehicleBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  

  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const response = await axios.get('/api/VehicleType/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = response.data;
        setVehicleTypeOptions(data.map(item => ({ value: item.vehicle_type_name, label: item.vehicle_type_name })));
      } catch (error) {
        console.error('Error fetching vehicle types:', error);
      }
    };
  
    fetchVehicleTypes();
  }, [token]); 



  const questions = [
    {
      text: 'Vehicle Number',
      value: vehicleNumber,
      handleChange: (e) => setVehicleNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Vehicle Type',
      value: vehicleType,
      handleChange: (e) => setVehicleType(e.target.value),
      type: 'select',
      styled: true, // Use StyledDropdown
      options: vehicleTypeOptions, // Use the state for dynamic dropdown options
    },
    {
      text: 'Vehicle Barcode',
      value: vehicleBarcode,
      handleChange: (e) => setVehicleBarcode(e.target.value),
      type: 'text',
      isLocked: true 
    },
    ...(!vehicleBarcode ? [{
      text: 'Generate Barcode',
      type: 'generateBarcode'
    }] : [])
  ];

  return (
    <div className="form-container">
      <DynamicForm
        title="Manage Vehicle"
        questions={questions}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleUpdate={handleUpdate}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleGenerateBarcode={handleGenerateBarcode} 
        setSelectedId={setVehicleBarcode}  
        selectedId={selectedVehicleId}
      />
    </div>
  );
};

export default VehicleForm;
