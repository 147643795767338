import React, { useState } from 'react';
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm'; 

const EngineerResponseForm = ({ token, reportId, onReload }) => { 
  const [responseTime, setResponseTime] = useState('');
  const [allocatedPerson, setAllocatedPerson] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/ErrorReport/update/${reportId}/`, {
        response_time: responseTime,
        PersonAllocated_User_Barcode: allocatedPerson, 
        status: 'Under Investigation' 
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Response time, allocated person, and status set.');
    } catch (error) {
      console.error('Error updating report:', error);
    }

    if (onReload) {
      onReload();
    }
  };

  const handleRefresh = () => {
    setResponseTime('');
    setAllocatedPerson('');
  };

  const questions = [
    {
      type: 'datetime-local',
      text: 'Response Time:',
      value: responseTime,
      handleChange: (e) => setResponseTime(e.target.value),
    },
    {
      type: 'text',
      text: 'Allocated Person:',
      value: allocatedPerson,
      handleChange: (e) => setAllocatedPerson(e.target.value),
    }
  ];

  return (
    <DynamicForm
      title="Engineer Response"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      showDeleteButton={false}
    />
  );
};

export default EngineerResponseForm;
