import React, { useState, useEffect } from 'react';
import UserPositionForm from './UserPositionForm';

const UserPositionTable = ({ token }) => {
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);

  useEffect(() => {
    getPositions();
  }, []);

  const getPositions = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      const response = await fetch('/api/UserPosition/', requestOptions);
      if (response.ok) {
        const data = await response.json(); // make sure 'data' is declared and initialized here
        setPositions(data);
      } else {
      }
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  };
  

  const handleRowClick = (position) => {
    setSelectedPosition(position);
  };
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="position-container">
        <div className="table-list" style={{ flex: 1 }}>
          <div className="form-container">
            <div style={{ flex: 1 }}>
              <UserPositionForm selectedPosition={selectedPosition} token={token} />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Position Name</th>
              </tr>
            </thead>
            <tbody>
              {positions.map((position, index) => (
                <tr key={index} onClick={() => handleRowClick(position)}>
                  <td>{position.name}</td>  {/* Make sure the attribute here matches the one in your data */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserPositionTable;
