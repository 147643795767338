import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/header';
import StorageUnitTable from '../components/EditTables/StorageUnitTable'; // Updated import
import StorageBinTable from '../components/EditTables/StorageBinTable'; // Updated import
import '../static/CSS/Storagedropdown.css';

const StorageEditPage = () => {
  const token = localStorage.getItem("token");
  const [storageUnits, setStorageUnits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedStorageUnit, setSelectedStorageUnit] = useState(null);
  const [selectedBin, setSelectedBin] = useState(null);

  useEffect(() => {
    fetchStorageUnits();
  }, []);

  useEffect(() => {
    const uniqueLocations = [...new Set(storageUnits.map(unit => unit.location))].filter(location => location);
    setLocations(uniqueLocations);
  }, [storageUnits]);

  const fetchStorageUnits = async () => {
    try {
      const response = await axios.get('/api/storage-unit/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStorageUnits(response.data);
    } catch (error) {
      console.error("Error fetching storage units:", error);
    }
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    setSelectedStorageUnit(null); // Reset selected storage unit when location changes
  };

  const handleStorageUnitChange = (event) => {
    const unit = storageUnits.find(unit => unit.unit_barcode === event.target.value);
    setSelectedStorageUnit(unit);
  };

  const filteredStorageUnits = selectedLocation ? storageUnits.filter(unit => unit.location === selectedLocation) : [];

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <h2>Edit Storage Configuration</h2>
        <StorageUnitTable 
          token={token} 
          selectedStorageUnit={selectedStorageUnit}
          // Assuming StorageUnitTable might need a function to handle updates
        />
        <StorageBinTable
          token={token}
          selectedBin={selectedBin}
          storageUnits={filteredStorageUnits}
          // Assuming StorageBinTable might need a function to handle updates
        />
      </div>
    </div>
  );
};

export default StorageEditPage;
