import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const QualityAssessment = ({ token, reloadFlag, pouchContents, setPouchContents, checkedItems, setCheckedItems }) => {
  const pouchNumber = useSelector((state) => state.user.pouchNumber);
  const pouch_barcode = useSelector((state) => state.user.pouchBarcode);
  const [selectAllPresent, setSelectAllPresent] = useState(false);
  const [selectAllIncorrect, setSelectAllIncorrect] = useState(false);
  const [showQAedPopup, setShowQAedPopup] = useState(false);

  useEffect(() => {
    const fetchPouchContents = async () => {
      try {
        const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouch_barcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
   
        const isAlreadyQAed = response.data.some(content => content.Datetime_qa);
            
    
        if (isAlreadyQAed) {
          setShowQAedPopup(true);
        }
    
        setPouchContents(response.data);
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };

    if (pouchNumber) {
      fetchPouchContents();
    } else {
      setPouchContents([]);
    }
  }, [pouchNumber, token, reloadFlag]);
  
  useEffect(() => {
  }, [showQAedPopup]);
  

  const isExpiringSoon = (expiryDate) => {
    const currentDate = new Date();
    const oneMonthLater = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    const parsedExpiryDate = new Date(expiryDate);
    return parsedExpiryDate <= oneMonthLater;
  };

  // modify the handleCheckboxChange function
  const handleCheckboxChange = (event) => {
    setCheckedItems(prevCheckedItems => ({ ...prevCheckedItems, [event.target.name]: event.target.checked }));
  }

  // modify the handleTdClick function
  const handleTdClick = (checkboxName) => {
    setCheckedItems(prevCheckedItems => ({ ...prevCheckedItems, [checkboxName]: !prevCheckedItems[checkboxName] }));
  }

  const handleSelectAllChange = (column) => {
    setCheckedItems(prevCheckedItems => {
      const newCheckedItems = { ...prevCheckedItems };
  
      pouchContents.forEach((content) => {
        newCheckedItems[`${column}-${content.unique_id}`] = column === 'present' ? !selectAllPresent : !selectAllIncorrect;
      });
  
      return newCheckedItems;
    });
  
    if (column === 'present') {
      setSelectAllPresent(!selectAllPresent);
    } else if (column === 'incorrect') {
      setSelectAllIncorrect(!selectAllIncorrect);
    }
  };
  

  return (
    <div className="QA-card">
      {showQAedPopup && (
        <div className="popup">
          <p>This has already been QAed. Do you want to continue?</p>
          <button onClick={() => setShowQAedPopup(false)}>Yes</button>
          <button onClick={() => window.location.href = '/unpacking'}>No</button>
        </div>
      )}
      <div className="card-body">
        {pouchContents.length ? (
          <table>
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Batch Number</th>
                <th>Expiry Date</th>
                <th>
                  Present
                </th>
                <th>
                  Incorrect
                </th>
              </tr>
            </thead>
            <tbody>
              {pouchContents.map((content, i) => (
                <tr key={`${content.unique_id}`}
                  style={{
                    backgroundColor: isExpiringSoon(content.Expiry_Date) ? 'yellow' :
                      checkedItems[`present-${content.unique_id}`] && !checkedItems[`incorrect-${content.unique_id}`] ? 'green' :
                        checkedItems[`incorrect-${content.unique_id}`] ? 'red' : 'transparent'
                  }}>
                  <td>{content.Medicine_Name}</td>
                  <td>{content.Batch_Number}</td>
                  <td>{new Date(content.Expiry_Date).toLocaleDateString()}</td>
                  <td onClick={() => handleTdClick(`present-${content.unique_id}`)}>
                    <input
                      type="checkbox"
                      name={`present-${content.unique_id}`}
                      checked={checkedItems[`present-${content.unique_id}`] || false}
                      onChange={handleCheckboxChange}
                    />
                  </td>
                  <td onClick={() => handleTdClick(`incorrect-${content.unique_id}`)}>
                    <input
                      type="checkbox"
                      name={`incorrect-${content.unique_id}`}
                      checked={checkedItems[`incorrect-${content.unique_id}`] || false}
                      onChange={handleCheckboxChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="account-card-item">
            <span className="account-card-label">This pouch is empty.</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default QualityAssessment;
