import React from 'react';
import Header from '../../components/header';
import PouchMedicineDataStores from '../../components/Reports/Price'


const PriceReport = () => {
  const token = localStorage.getItem('token');

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <PouchMedicineDataStores token={token}/>
        </div>
      </div>
  );
};

export default PriceReport;