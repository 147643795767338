import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const NSMedicineRemoveForm = ({ selectedMedicineBarcode, selectedBatchUniqueIds, token, onUpdate }) => {
    const [amount, setAmount] = useState('');
    const [medicineDetails, setMedicineDetails] = useState({});
    const [pouchAmount, setPouchAmount] = useState(0);
    const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
    const pouchNumber = useSelector((state) => state.user.pouchNumber);
    const userBarcode = useSelector((state) => state.user.userBarcode);
    const name = useSelector((state) => state.user.name);

    // Fetch medicine details
    useEffect(() => {
        if (selectedMedicineBarcode) {
            axios.get(`/api/MedicineBatch/${selectedMedicineBarcode}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                setMedicineDetails(response.data);
            }).catch(error => console.error('Error fetching medicine details:', error));
        }
    }, [selectedMedicineBarcode, token]); // Only depends on selectedMedicineBarcode
    

    // Fetch pouch contents
    useEffect(() => {
        if (pouchBarcode && selectedMedicineBarcode) {
            axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                const medicineItems = response.data.filter(item => item['Drug_Barcode'] === selectedMedicineBarcode);
                const totalAmount = medicineItems.reduce((sum, item) => sum + item.total_amount, 0);
                setPouchAmount(totalAmount);
            }).catch(error => console.error('Error fetching pouch contents:', error));
        }
    }, [pouchBarcode, selectedMedicineBarcode, token]); // Depends only on pouchBarcode and selectedMedicineBarcode
       
    const handleSubmit = async (e) => {
        e.preventDefault();

        const inputAmount = parseInt(amount);
        if (isNaN(inputAmount) || inputAmount <= 0 || inputAmount > pouchAmount) {
            alert("Invalid amount.");
            return;
        }

        try {
            await axios.post('/api/StockHoldingHistory/create/', {
                location: pouchBarcode,
                user_barcode: userBarcode,
                drug_barcode: selectedMedicineBarcode,
                transaction: 'Pouch to Stock',
                amount: inputAmount,
                datetime_transaction: new Date().toISOString(),
                comments: `Unpacked by ${name}`,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

          // Update each record in Pouch History
          let remainingAmount = inputAmount;
          for (const id of selectedBatchUniqueIds) {
            if (remainingAmount <= 0) break; // Stop the loop if the required amount is already processed
        
            await axios.put(`/api/update_pouch_history/${id}/`, {
                user_unpacked: userBarcode,
                datetime_unpacked: new Date().toISOString(),
                status: 'Unpacked',
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        
            remainingAmount -= 1; // Decrease the remaining amount after each successful update
        }

          alert("Medicine removed successfully");
          // Reset form
          setAmount('');
          if (onUpdate) onUpdate();
      } catch (error) {
          console.error("Error processing removal:", error);
          alert("Error processing removal");
      }
  };

    return (
        <div className="medicine-return-container">
            <div className="form-container">
                <div className="medicine-return-card">
                    <div className="medicine-return-card-header">
                        <h2>Remove Medicine</h2>
                    </div>
                    <div className="medicine-return-card-body">
                        <form onSubmit={handleSubmit} className="medicine-return-form">
                            <div className="medicine-return-form-group">
                                <label className="medicine-return-label">Medicine Name:</label>
                                <span className="medicine-return-value">{medicineDetails.medicine_name}</span>
                            </div>
                            <div className="medicine-return-form-group">
                                <label className="medicine-return-label">Pouch Number:</label>
                                <span className="medicine-return-value">{pouchNumber}</span>
                            </div>
                            <div className="medicine-return-form-group">
                                <label className="medicine-return-label">Amount in Pouch:</label>
                                <span className="medicine-return-value">{}</span>
                            </div>
                            <div className="medicine-return-form-group-row">
                                <label htmlFor="amount" className="medicine-return-label">Amount to Remove:</label>
                                <input
                                    type="number"
                                    id="amount"
                                    className="medicine-return-input inline-input"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    max={pouchAmount}
                                />
                            </div>
                            <div className="medicine-return-form-buttons">
                                <button type="submit" className="button">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
    }    

export default NSMedicineRemoveForm;
