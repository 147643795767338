import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';


const MedicineSummary = ({ token }) => {
  const [data, setData] = useState([]);
  const [medicineName, setMedicineName] = useState('');
  const [allMedicineNames, setAllMedicineNames] = useState([]);
  const [filteredMedicineNames, setFilteredMedicineNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch all medicine names when component mounts
    const fetchAllMedicineNames = async () => {
      const response = await axios.get('api/Medicine/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const medicineNames = response.data.map(item => item.medicine_name);
      setAllMedicineNames(medicineNames);
      setFilteredMedicineNames(medicineNames);
    };

    fetchAllMedicineNames();
  }, [token]);

  useEffect(() => {
    // Filter medicine names based on search term
    const filteredNames = allMedicineNames.filter(name => name && name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredMedicineNames(filteredNames);
  }, [searchTerm, allMedicineNames]);

  const fetchMedicineSummary = async () => {
    const response = await axios.get(`api/medicine_summary/${medicineName}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
     });
     
     const formattedData = response.data.map(item => ({
        'Pouch Number': item.Pouch_Number || '',
        'Batch Number': item.Batch_Number || '',
        'Medicine Name': item.Medicine_Name || '',
      }));
  
      // Log the formatted data for the table
      console.log("Formatted Data for Table:", formattedData);
    setData(formattedData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchMedicineSummary();
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Medicine Summary');
    XLSX.writeFile(wb, 'MedicineSummary.xlsx');
  };

  const initialColumns = ['Pouch Number', 'Batch Number', 'Medicine Name'];
  const allColumns = ['Pouch Number', 'Batch Number', 'Medicine Name'];

  return (
    <div>
        <div className="Form-card">
            <div className="Form-card-body">
                <form onSubmit={handleFormSubmit} className="Form-form-container">
                    <h3 className='Form-Title'>Medicine Summary</h3>
                    <div className="Form-form-row">
                        <label>
                            Search Medicine:
                            <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </label>
                    </div>
                    <div className="Form-form-row">
                        <label>
                            Medicine Name:
                            <select value={medicineName} onChange={(e) => setMedicineName(e.target.value)}>
                                {filteredMedicineNames.map(name => (
                                    <option key={name} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="Form-form-row-button-container">
                        <button className="button" type="submit">Fetch Data</button>
                        {/* Add other buttons as needed */}
                    </div>
                </form>
            </div>
        </div>
        <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
        <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
);
};

export default MedicineSummary;
