import React, { useState, useEffect } from 'react';
import axios from 'axios';

import DynamicForm from '../DynamicForm/DynamicForm';

const MedicineBagForm = ({ token, selectedMedicineBag }) => {
  const [medicineBagNumber, setMedicineBagNumber] = useState('');
  const [medicineBagType, setMedicineBagType] = useState('');
  const [medicineBagBarcode, setMedicineBagBarcode] = useState('');
  const [selectedMedicineBagId, setSelectedMedicineBagId] = useState(null);
  const [medicineBagTypeOptions, setMedicineBagTypeOptions] = useState([]);


  useEffect(() => {
    if (selectedMedicineBag) {
      setMedicineBagNumber(selectedMedicineBag.medicine_bag_number);
      setMedicineBagType(selectedMedicineBag.medicine_bag_type);
      setMedicineBagBarcode(selectedMedicineBag.medicine_bag_barcode);
      setSelectedMedicineBagId(selectedMedicineBag.medicine_bag_barcode);
    }
  }, [selectedMedicineBag]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/MedicineBags/create/', {
        medicine_bag_number: medicineBagNumber,
        medicine_bag_type: medicineBagType,
        medicine_bag_barcode: medicineBagBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      await axios.post('/api/MedicineBagStatus/create/', {
        medicine_bag_barcode: medicineBagBarcode,
        status: "Unpacked",
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  

  const handleRefresh = () => {
    setMedicineBagNumber('');
    setMedicineBagType('');
    setMedicineBagBarcode('');
    setSelectedMedicineBagId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete Medicine Bag with Barcode: ${medicineBagBarcode}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/MedicineBags/update/${selectedMedicineBagId}/`, {
        medicine_bag_number: medicineBagNumber,
        medicine_bag_type: medicineBagType,
        medicine_bag_barcode: medicineBagBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      // Update MedicineBagStatus if barcode changed
      if (selectedMedicineBagId !== medicineBagBarcode) {
        await axios.put(`/api/MedicineBagStatus/update/${selectedMedicineBagId}/`, {
          medicine_bag_barcode: medicineBagBarcode,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      }
  
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/MedicineBags/delete/${selectedMedicineBagId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      await axios.delete(`/api/MedicineBagStatus/delete/${selectedMedicineBagId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  

  const handleGenerateBarcode = async () => {
    const prefix = 'B'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setMedicineBagBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  

  useEffect(() => {
    // Fetch dropdown options from API
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('api/dropdown_options/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const medicineBagTypes = response.data.filter(item => item.Dropdown_Name === 'Medicine_Bag_Type');
        setMedicineBagTypeOptions(medicineBagTypes.map(item => item.Dropdown_Options));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };
    
    fetchDropdownOptions();
  }, [token]);

  const questions = [
    {
      text: 'Medicine Bag Number',
      value: medicineBagNumber,
      handleChange: (e) => setMedicineBagNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Medicine Bag Type',
      value: medicineBagType,
      handleChange: (e) => setMedicineBagType(e.target.value),
      type: 'select',
      styled: true,
      options: medicineBagTypeOptions.map((type) => ({ value: type, label: type })),
    },
    {
      text: 'Medicine Bag Barcode',
      value: medicineBagBarcode,
      handleChange: (e) => setMedicineBagBarcode(e.target.value),
      type: 'text',
    },
    {
      type: 'generateBarcode',
      text: 'Generate Barcode',
    }
  ];

  return (
    <div className="form-container">
      <DynamicForm
        title="Add New Medicine Bag"
        questions={questions}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleUpdate={handleUpdate}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleGenerateBarcode={handleGenerateBarcode} 
        setDrugBarcode={setMedicineBagBarcode}  
        selectedId={selectedMedicineBagId}
        showSearchBar={true}
      />
    </div>
  );
};

export default MedicineBagForm;
