import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserDetail } from '../../Redux/slices/userSlice'; 

import LocationForm from '../Station Carrier Inputs/StationInput';
import MedicineBag_inputs from '../Station Medicine_Bag Inputs/Medicine_bagInput';
import Carrier_inputs from '../Station Carrier Inputs/Carrier_inputs';
import '../../static/CSS/UserDetails.css'; 
import CombinedPouchNumberInput from '../../components/PouchAdd/CombinedPouchNumberInput';

const UserDetails = ({ token }) => {
    const [showComponent, setShowComponent] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
  
    const handleRefresh = (details) => {
      dispatch(clearUserDetail({ details }));
    };
    const handleToggleComponent = (component) => {
      setShowComponent(prev => prev === component ? null : component);
    };
  return (
    <div className="user-details">
        <div className="user-title">Detail Check</div> {/* Add this line for the title */}

        {/* User detail */}
        <div className="user-detail">
          <p>User: {user.name}</p>
          <a href="/login" className="button">Logout</a>
          <button className='button' onClick={() => handleRefresh('username')}>Refresh</button>
        </div>

        {/* Station detail */}
        <div className="user-detail">
          <p>Station: {user.station}</p>
          <button className='button' onClick={() => handleToggleComponent('station')}>Update</button>
          <button className='button' onClick={() => handleRefresh(['station', 'station_barcode'])}>Refresh</button>
        </div>
        {showComponent === 'station' && <LocationForm token={token} />}
        
      {/* Pouch number detail */}
      <div className="user-detail">
        <p>Pouch Number: {user.pouchNumber}</p>
        <button className='button' onClick={() => handleToggleComponent('pouchNumber')}>Update</button>
        <button className='button' onClick={() => handleRefresh(['pouchNumber', 'pouchType', 'pouchBarcode'])}>Refresh</button>
    </div>
    {showComponent === 'pouchNumber' && (
        <CombinedPouchNumberInput token={token} />
    )}

        {/* Carrier detail */}
        <div className="user-detail">
          <p>Carrier: {user.carrier}</p>
          <button className='button' onClick={() => handleToggleComponent('carrier')}>Update</button>
          <button className='button' onClick={() => handleRefresh(['carrier', 'carrierBarcode'])}>Refresh</button>
        </div>
        {showComponent === 'carrier' && (
                  <Carrier_inputs token={token} /> 
   )}

        {/* Medicine bag detail */}
        <div className="user-detail">
          <p>Medicine Bag: {user.Medicine_bag}</p>
          <button className='button' onClick={() => handleToggleComponent('medicineBag')}>Update</button>
          <button className='button' onClick={() => handleRefresh(['Medicine_bag', 'Medicine_bagBarcode'])}>Refresh</button>
        </div>
        {showComponent === 'medicineBag' && <MedicineBag_inputs token={token} />}
      </div>
  );
};

export default UserDetails;
