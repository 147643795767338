import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const MedicinePouchTypeForm = ({ token, selectedMedicineType  }) => {
  const [pouchTypeName, setPouchTypeName] = useState('');
  const [medicineName, setMedicineName] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [redTagAmount, setRedTagAmount] = useState('');
  const [selectedMedicineTypeId, setSelectedMedicineTypeId] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [pouchTypeNames, setPouchTypeNames] = useState([]);
  const [consumables, setConsumables] = useState([]); 

  useEffect(() => {
    if (selectedMedicineType) {
      setPouchTypeName(selectedMedicineType['Pouch Type Name']);
      setMedicineName(selectedMedicineType['Medicine Name']);
      setMaxAmount(selectedMedicineType['Max Amount']);
      setMinAmount(selectedMedicineType['Min Amount']);
      setRedTagAmount(selectedMedicineType['Red Tag Amount']);
      setSelectedMedicineTypeId(selectedMedicineType.Unique_id);
    }
}, [selectedMedicineType]);



useEffect(() => {
  const fetchMedicinesAndConsumables = async () => {
    try {
      const [medicinesResponse, consumablesResponse] = await Promise.all([
        axios.get('/api/Medicine/', {
          headers: { 'Authorization': `Bearer ${token}` },
        }),
        axios.get('/api/ConsumableBatch/', {
          headers: { 'Authorization': `Bearer ${token}` },
        }),
      ]);

      setMedicines(medicinesResponse.data);
      setConsumables(consumablesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchMedicinesAndConsumables();
}, [token]);


const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    await axios.post('/api/PouchType/create/', {
      pouch_type_name: pouchTypeName,
      medicine_name: medicineName,
      max_amount: maxAmount,
      min_amount: minAmount,
      red_tag_amount: redTagAmount,
    }, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    window.location.reload();
  } catch (error) {
    console.error('Error creating pouch type:', error);
  }
};



  
const handleUpdate = async () => {
  try {
    await axios.put(`/api/PouchType/update/${selectedMedicineTypeId}/`, {
      pouch_type_name: pouchTypeName,
      medicine_name: medicineName,
      max_amount: maxAmount,
      min_amount: minAmount,
      red_tag_amount: redTagAmount,
    }, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    window.location.reload();
  } catch (error) {
    console.error('Error updating pouch type:', error);
  }
};


  
  
const handleDelete = async () => {
  try {
    await axios.delete(`/api/PouchType/delete/${selectedMedicineTypeId}/`, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    window.location.reload();
  } catch (error) {
    console.error('Error deleting pouch type:', error);
  }
};


  
 
  const handleRefresh = () => {
    setPouchTypeName('');
    setMedicineName('');
    setMaxAmount('');
    setMinAmount('');
    setRedTagAmount('');
    setSelectedMedicineTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${medicineName}?`)) {
      handleDelete();
    }
  }  

  // Combine medicines and consumables for the dropdown
  const combinedOptions = [
    ...medicines.map(medicine => ({
      value: medicine.medicine_name, 
      label: `${medicine.medicine_name} (Medicine)`,
      type: 'medicine'
    })),
    ...consumables.map(consumable => ({
      value: consumable.consumable_name, 
      label: `${consumable.consumable_name} (Consumable)`,
      type: 'consumable'
    })),
  ];

// Use combinedOptions in your DynamicForm's questions



  const questions = [
    {
      text: 'Medicine/Consumable Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setMedicineName(e.target.value),
      value: medicineName,
      options: combinedOptions,
    },
    {
      text: 'Pouch Type Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setPouchTypeName(e.target.value),
      value: pouchTypeName,
      options: pouchTypeNames.map((pouchTypeName) => ({ value: pouchTypeName.pouch_type_name, label: pouchTypeName.pouch_type_name })),
    },
    {
      text: 'Max Amount',
      type: 'number',
      handleChange: (e) => setMaxAmount(e.target.value),
      value: maxAmount,
    },
    {
      text: 'Min Amount',
      type: 'number',
      handleChange: (e) => setMinAmount(e.target.value),
      value: minAmount,
    },
    {
      text: 'Red Tag Amount',
      type: 'number',
      handleChange: (e) => setRedTagAmount(e.target.value),
      value: redTagAmount,
    }
  ];

  return (
    <DynamicForm
      title="Add New Pouch Type"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedMedicineTypeId}
    />
  );
};

export default MedicinePouchTypeForm;

