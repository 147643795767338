import React, { useState } from 'react';
import Header from '../components/header';
import ErrorReportForm from '../components/ErrorReporting/EngineerCompletionForm'; // Import your Error Report Form component
import EngineerResponseForm from '../components/ErrorReporting/EngineerResponseForm'; // Import your Engineer Response Form component
import EngineerOutcomeForm from '../components/ErrorReporting/ErrorReportingForm'; // Import your Engineer Outcome Form component
import ErrorReportTable from '../components/ErrorReporting/ErrorReportTable'; // Import your Error Report Table component

const token = localStorage.getItem("token");


const ErrorHandlingPage = () => {
  const [reloadFlag, setReloadFlag] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null); 

  const handleReload = () => {
    setReloadFlag(!reloadFlag);
  };

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="tables-container">
          <ErrorReportTable 
              token={token} 
              onReload={reloadFlag} 
              setSelectedReportId={setSelectedReportId} 
              selectedReportId={selectedReportId}
            />        
        </div>
        <div className="form-container">
          <EngineerOutcomeForm token={token} onReload={handleReload} />
        </div>
        <div className="form-container">
          <EngineerResponseForm token={token} onReload={handleReload} reportId={selectedReportId} />  {/* Pass it here */}
        </div>
        <div className="form-container">
          <ErrorReportForm token={token} onReload={handleReload} reportId={selectedReportId}/>
        </div>
      </div>
    </div>
  );
};

export default ErrorHandlingPage;
