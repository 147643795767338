import React from 'react';
import Header from '../components/header'; // Make sure the path is correct
import UserPositionTable from '../components/EditTables/UserPositionTable'; // Update the path to where your UserPositionTable component is located

const token = localStorage.getItem("token");

function EditUserPosition() {

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <UserPositionTable token={token} />
      </div>
    </div>
  );
}

export default EditUserPosition;
