import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";

const ErrorReportTable = ({ token, onReload, setSelectedReportId, selectedReportId }) => {  
    const [errorReports, setErrorReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    getErrorReports();
  }, [onReload]); 

  const getErrorReports = async () => {
    try {
      const response = await fetch('/api/ErrorReport/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setErrorReports(data);
    } catch (error) {
      console.error('Error fetching error reports:', error);
    }
  };

  const initialColumns = ['Description', 'Area', 'Date Reported', 'Status'];
  const allColumns = ['Description', 'Area', 'Date Reported', 'Response Time', 'Allocated Person', 'Reason', 'Outcome', 'Status', 'Completed Date'];

  const tableData = Array.isArray(errorReports) ? errorReports.map(report => ({
    'Description': report.description,
    'Area': report.area,
    'Date Reported': new Date(report.date_reported).toLocaleDateString(),
    'Status': report.status,
    'Response Time': new Date(report.response_time).toLocaleDateString(), 
    'Allocated Person': report.PersonAllocated_User_Barcode, 
    'Reason': report.reason,
    'Outcome': report.outcome,
    'Completed Date': report.completed_datetime ? new Date(report.completed_datetime).toLocaleDateString() : 'N/A'  // Check for null before converting to Date
})) : [];
  

  const handleRowSelect = (selectedRow) => {
    setSelectedReport(selectedRow);
    if (setSelectedReport) {
      setSelectedReport(selectedRow);
    }
    setSelectedReportId(selectedRow['Unique ID']);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="error-report-container">
        <div className="ErrorReport-list">
          <EditableTable
            selectedReport={selectedReport}
            selectedReportId={selectedReportId}  // <-- Use the prop here
            setSelectedReportId={setSelectedReportId}
            token={token}
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns}
            allColumns={allColumns}
            data={tableData}
            showSelectButton={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorReportTable;
