import axios from 'axios';

const useProductLocationSubmit = (token) => {
  // Update parameters to accept Barcode and Location_Barcode directly
  const submitProductLocation = async (Barcode, Location_Barcode, onSuccess, onError) => {
    const url = `/api/ProductLocation/create/`;

    // Adjust field names and formats according to the serializer's expectations
    const dataWithDatetime = {
      barcode: Barcode,  // Adjusted to snake_case
      location_barcode: Location_Barcode,  // Adjusted to snake_case
      datetime: new Date().toISOString(),  // Ensure format is acceptable
    };


    try {
      const response = await axios.post(url, dataWithDatetime, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        onSuccess(`Product location created successfully.`);
      } else {
        onError(`Error creating product location: status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error creating product location:`, error);
      onError(`Error creating product location: ${error.response.data}`);
    }
  };

  return submitProductLocation;
};

export default useProductLocationSubmit;
