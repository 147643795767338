import React from 'react';
import Header from '../components/header'; 
import DropdownOptionsEditor from '../components/Settings/NotPresentReasons';
import MedicineBagType from '../components/Settings/MedicineBagType'; 
import RolePermissionsPage from '../components/Settings/RolePermissionsPage '; 
import Editpages from './Editpages'
import FileUploader from '../components/Global import export/FileUploader'; // Assuming FileUploader is in the same directory
import TemplateDownloader from '../components/Global import export/Templatedownload';

const token = localStorage.getItem("token");

const Settings = () => {
  return (
    <div className='main-content'>
      <Header />
      <h1>Settings</h1>
      <div className="settings-container"> 
        <DropdownOptionsEditor token={token}/>
        <MedicineBagType token={token}/> 
      </div>
      <RolePermissionsPage token={token} />
      <h1>Edit</h1>
      <Editpages token={token} />
      <h1>Global Import and Export</h1>
        <div>
          <FileUploader token={token} />
        </div>
        <div>
          <TemplateDownloader token={token} />
        </div>
    </div>
  );
};

export default Settings;
