import axios from 'axios';



const useUnpackItemAndLocationSubmit = (token, user) => {
  const submitProductLocation = async (productBarcode, Location_Barcode, onSuccess, onError) => {
    console.log('submitProductLocation called', { productBarcode, Location_Barcode });

    const url = `/api/ProductLocation/create/`;
    const dataWithDatetime = {
      barcode: productBarcode,
      location_barcode: Location_Barcode,
      datetime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(url, dataWithDatetime, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('submitProductLocation response', response); // Correct placement of the log statement
      if (response.status === 201) {
        onSuccess && onSuccess(`Product location for item created successfully.`);
      } else {
        onError && onError(`Error creating product location for item: status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error creating product location for item:`, error);
      onError && onError(`Error creating product location for item: ${error.response?.data}`);
    }
  };

  const unpackItem = async (productBarcode, containerBarcode, quantity = 1) => {
    console.log('unpackItem called', { productBarcode, containerBarcode, quantity });
    let fetchUrl = '';
    let updateUrlTemplate = '';
    let itemStatusField = '';
    let itemBarcodeField = '';

    // Determine URLs and fields based on container type
    if (containerBarcode.startsWith('B')) {
      fetchUrl = '/api/packed_products_in_medicine_bag/';
      updateUrlTemplate = '/api/Medicine_BagHistory/update/{unique_id}/';
      itemStatusField = 'MedicineBag_Status';
      itemBarcodeField = 'MedicineBag_Barcode';
    } else if (containerBarcode.startsWith('C')) {
      fetchUrl = '/api/packed_products_in_carrier/';
      updateUrlTemplate = '/api/CarrierHistory/update/{unique_id}/';
      itemStatusField = 'Carrier_Status';
      itemBarcodeField = 'Carrier_Barcode';
    } else if (containerBarcode.startsWith('V')) {
      fetchUrl = '/api/packed_products_in_vehicle/';
      updateUrlTemplate = '/api/VehicleHistory/update/{unique_id}/';
      itemStatusField = 'Vehicle_Status';
      itemBarcodeField = 'Vehicle_Barcode';
    } else {
      console.error('Unsupported item type for unpacking.', { containerBarcode });
      return;
    }

    // Fetch items to unpack
    try {
      const response = await axios.get(fetchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Items fetched for unpacking', response.data);

      // Filter items that match criteria and limit by quantity
      const itemsToUnpack = response.data
        .filter(item => item.Product_Barcode === productBarcode && item[itemBarcodeField] === containerBarcode && item[itemStatusField] === "Packed")
        .slice(0, quantity);

      console.log('Items to unpack', itemsToUnpack);

      // Perform unpacking updates for each item
      for (const item of itemsToUnpack) {
        const updateUrl = updateUrlTemplate.replace('{unique_id}', item.Unique_ID);
        const dataToUpdate = {
          Station_Unpacked: user.station_barcode,
          datetime_Unpacked: new Date().toISOString(),
          User_Unpacked: user.userBarcode,
          [itemStatusField]: "Unpacked",
        };
        console.log('Updating unpacked item', { updateUrl, dataToUpdate });
        await axios.put(updateUrl, dataToUpdate, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
      }

      console.log(`${itemsToUnpack.length} items unpacked successfully.`);
    } catch (error) {
      console.error(`Error in unpackItem:`, error);
    }
  };

  const Updatestationstock = async (productBarcode, binBarcode, containerBarcode, onSuccess, onError) => {
    const data = {
      product_barcode: productBarcode,
      bin_barcode: binBarcode,
      action: 'add',
      quantity_changed: '1',
      user: user.userBarcode,
      comments: `added from ${containerBarcode}`,
    };

    console.log('Making POST request to: /api/add-station-stock-history/ with data:', data);

    try {
      const response = await axios.post(`/api/add-station-stock-history/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        console.log('Success response:', response.data); // Log the success response data
        onSuccess && onSuccess(`Stock history added successfully.`);
      } else {
        console.error(`Error adding stock history: status code ${response.status}`);
        onError && onError(`Error adding stock history: status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error adding stock history:`, error);
      onError && onError(`Error adding stock history: ${error.response?.data}`);
    }
  };
  // Streamlined function to perform unpacking, product location submission, and stock update
  const performUnpackAndUpdate = async (productBarcode, containerBarcode, binBarcode, quantity) => {
    try {
      await unpackItem(productBarcode, containerBarcode, quantity);
      await submitProductLocation(productBarcode, binBarcode);
      await Updatestationstock(productBarcode, binBarcode, containerBarcode);
      console.log('Unpacking, product location submission, and stock update process completed successfully.');
    } catch (error) {
      console.error('Error during unpack and update process:', error);
    }
  };

  return { performUnpackAndUpdate };
};

export default useUnpackItemAndLocationSubmit;
