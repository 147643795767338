import React, { useState } from 'react';
import ExpiredStockRemoval from './ExpiredStockRemoval';

const ExpiredButton = ({ token }) => {
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(!showForm);
  }

  return (
    <div className="card">
        <div className="card-body">
            <div className="account-card-item">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                <button className='button' onClick={handleClick}>Remove Expired Stock</button>
                </div>
                {showForm && <ExpiredStockRemoval token={token}/>}
            </div>
        </div>
    </div>
  );
}

export default ExpiredButton;
