// src/store.js
import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import permissionReducer from './slices/permissionSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// Create a root reducer
const rootReducer = combineReducers({
  user: userReducer,
  permissions: permissionReducer
});

// Configurations for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'permissions']  // you can also choose to include other reducer states
};

// Create a persisted root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Middleware configurations
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ['persist/PERSIST'],
  },
});

// Create the store with the persisted root reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

// Create a persistor object
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };
