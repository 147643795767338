import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import MedicineBatchForm from "./MedicineBatchForm";

const MedicineBatchTable = ({token}) => {
  let [medicines, setMedicinesBatch] = useState([]);
  const [selectedMedicine, setSelectedMedicineBatch] = useState(null);
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);

  useEffect(() => {
    getMedicinesBatch();
  }, []);

  let getMedicinesBatch = async () => {
    try {
      let response = await fetch('/api/MedicineBatch/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let data = await response.json();
      setMedicinesBatch(data);
    } catch (error) {
      console.error('Error fetching medicines:', error);
    }
};


  const initialColumns = ['Medicine name', 'Batch Number', 'Expiry Date', 'Medicine Pricing', 'Supplier', 'Pack Size'];
  const allColumns = ['Supplier', 'Pack Size', 'Drug Barcode', 'Unique_id','Medicine name', 'batch_number', 'expiry_date', 'medicine_pricing'];

  const tableData = Array.isArray(medicines) ? medicines.map(medicine => ({
    'Medicine name': medicine.medicine_name,
    'Batch Number': medicine.batch_number,
    'Expiry Date': new Date(medicine.expiry_date).toLocaleDateString(),
    'Medicine Pricing': medicine.medicine_pricing,
    'Drug Barcode': medicine.drug_barcode,
    'Pack Size': medicine.pack_size,
    'Supplier': medicine.supplier,
    'Unique_id': medicine.unique_id,
})) : [];


  const handleRowSelect = (selectedRow) => {
    setSelectedMedicineBatch(selectedRow);
    setSelectedMedicineId(selectedRow.Unique_id);
  };

  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

        <div className="form-container">
          <MedicineBatchForm selectedMedicine={selectedMedicine} token={ token } />
        </div>        
        <div className="medicine-container">
        <div className="Medicine-list">
          <EditableTable 
            selectedMedicine={selectedMedicine} 
            selectedMedicineId={selectedMedicineId} 
            setSelectedMedicineId={setSelectedMedicineId} 
            token={token} 
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns} // Add this
            allColumns={allColumns} // Add this
            data={tableData}
          />        
        </div>
      </div>
    </div>
  );
  
};

export default MedicineBatchTable;
