import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import '../../static/CSS/MedicineNotAvalible.css';

function MedicineNotPresent({ token }) {
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);
  const pouchNumber = useSelector((state) => state.user.pouchNumber);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const [removedStockData, setRemovedStockData] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);



  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('api/dropdown_options/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const notPresentReasons = response.data.filter(item => item.Dropdown_Name === 'Not_Present_Reason');
        setDropdownOptions(notPresentReasons.map(item => item.Dropdown_Options));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };
    fetchDropdownOptions();
  }, [token]);



  useEffect(() => {
    const fetchPouchContents = async () => {
      try {
        const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const dataWithTransactionType = response.data.map((item) => ({
          ...item,
          transactionType: '',
          amountToRemove: 0,
          comment: '',
        }));
        setFilteredTableData(dataWithTransactionType);
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };
  
    if (pouchNumber) {
      fetchPouchContents();
    } else {
      setFilteredTableData([]);
    }
  }, [pouchBarcode, token, updateKey, pouchNumber]);
  

  const handleSelectMedicine = (medicine) => {
    const newSelectedMedicine = selectedMedicine === medicine.unique_id ? null : medicine.unique_id;
    setSelectedMedicine(newSelectedMedicine);
  };
  



  async function fetchRemovedStock() {
    const response = await axios.get("/api/RemovedStock/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    setRemovedStockData(response.data);
  }
  
  useEffect(() => {
    fetchRemovedStock();
  }, []);
  
  const handleSubmit = async (item, e) => {
    e.preventDefault();
  
    // Check if the amount to remove is greater than the amount available
    if (item.amountToRemove > item.amount) {
      const confirmContinue = window.confirm(
        'The inputted amount is more than the amount in the pouch. Do you want to continue?'
      );
      if (!confirmContinue) {
        return;
      }
    }
  
    try {
  
      // Update pouch history
      await axios.put(`/api/update_pouch_history/${item.unique_id}/`, {
        user_unpacked: userBarcode,
        amount: item.amountToRemove,
        datetime_unpacked: new Date().toISOString(),
        status: 'Unpacked',
        comments: item.comment,  
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Update stock holding history
      await axios.post('/api/StockHoldingHistory/create/', {
        location: stationBarcode,
        user_barcode: userBarcode,
        drug_barcode: item.Drug_Barcode,
        transaction: item.transactionType,
        amount: item.amountToRemove,
        datetime_transaction: new Date().toISOString(),
        comments: item.comment,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Refresh the table after submitting
      setUpdateKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error('Error removing medicine:', error);
      alert('Error removing medicine');
    }
  };
  

  return (
    <div className="medicine-absent-container">
      <h1 className='Pouch-Number'>Pouch Number: {pouchNumber}</h1>
      {filteredTableData.map((item) => (
        <div key={item.unique_id} className={`medicine-item ${selectedMedicine === item.unique_id ? 'selected' : ''}`}>
          <div className="medicine-info" onClick={() => handleSelectMedicine(item)}>
            <div className="medicine-title">{item.Medicine_Name}</div>
            <div className="medicine-batch">{item.Batch_Number}</div>
          </div>
          {selectedMedicine === item.unique_id && (
            <form onSubmit={(e) => handleSubmit(item, e)} className="medicine-form">
              <div className="medicine-info">
                <span>Expiry Date: {new Date(item.Expiry_Date).toLocaleDateString()}</span>
              </div>
              <div className="medicine-actions">
                <select
                  id={`transactionType-${item.unique_id}`}
                  name="transactionType"
                  value={item.transactionType}
                  onChange={(e) => {
                    const updatedData = [...filteredTableData];
                    const dataIndex = updatedData.findIndex((dataItem) => dataItem.unique_id === item.unique_id);
                    updatedData[dataIndex] = { ...item, transactionType: e.target.value };
                    setFilteredTableData(updatedData);
                  }}
                >
                  <option value="" disabled>Select</option>
                  {dropdownOptions.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="medicine-comment">
                <input
                  type="text"
                  id={`comment-${item.unique_id}`}
                  name="comment"
                  value={item.comment}
                  placeholder="Enter comment"
                  onChange={(e) => {
                    const updatedData = [...filteredTableData];
                    const dataIndex = updatedData.findIndex((dataItem) => dataItem.unique_id === item.unique_id);
                    updatedData[dataIndex] = { ...item, comment: e.target.value };
                    setFilteredTableData(updatedData);
                  }}
                />
              </div>
              <div className="medicine-submit">
                <button className="button" type="submit">Submit</button>
                <button className="button" type="button" onClick={() => setSelectedMedicine(null)}>Cancel</button>
              </div>
            </form>
          )}
        </div>
      ))}
    </div>
);
}
export default MedicineNotPresent;
