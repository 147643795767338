import React, { useState } from 'react';
import Header from '../components/header';
import CarrierTable from '../components/CarrierTrack/CarrierTable';
import CarrierAllProductForm from '../components/CarrierTrack/AllProductadd/CarrierAllProductForm'; // Updated import

const token = localStorage.getItem("token");

const CarrierPackPage = () => {
    const [reloadFlag, setReloadFlag] = useState(false);
    const handleReload = () => {
      setReloadFlag(!reloadFlag);

    };

    return (
        <div className='main-content'>
            <Header token={token}/>

            <CarrierAllProductForm token={token} onReload={handleReload} />

            <CarrierTable key={Date.now()} token={token} reloadFlag={reloadFlag} />
        </div>
    );
};

export default CarrierPackPage;