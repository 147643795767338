import React from 'react';
import LandingPageHeader from '../../components/Landingscreen/LandingPageHeader';
import HeaderImage from '../../static/images/Header for the webpage.PNG';
import '../../static/CSS/LandingPageHeader.css';

const ContactPage = () => {
  return (
    <div className="contact-page">
      <img src={HeaderImage} alt="Precise PouchTrack Header" className="landing-page-header-image" />
      <LandingPageHeader />

      <section className="contact-section">
        <h1>Contact Us</h1>
        <div className="section-content">
          <div>
            <p>Here you can find all the ways to get in touch with us.</p>
            <div className="contact-details">
              <p>Email: <a href="mailto:Nickdeane@precisepouchtrack.com">Nickdeane@precisepouchtrack.com</a></p>
              <p>Website: <a href="http://www.precisepouchtrack.com" target="_blank" rel="noopener noreferrer">www.precisepouchtrack.com</a></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;

