// src/components/Reports/PouchHistoryDataWithDate.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';



const PouchHistoryDataWithDate = ({ token }) => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchPouchHistoryDataWithDate = async () => {
    const response = await axios.get(`api/pouch_history_data_withDate/?start_date=${startDate}&end_date=${endDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Unique ID': item.Unique_ID || '',
      'Pouch Number': item.Pouch_Number || '',
      'Station Name': item.Station_Name || '',
      'Batch Number': item.Batch_Number || '',
      'Medicine Name': item.Medicine_Name || '',
      'Amount': item.amount || '',
      'Packer Username': item.Packer_Username || '',
      'Datetime Packed': item.datetime_packed || '',
      'QA Username': item.QA_Username || '',
      'Datetime QA': item.datetime_qa || '',
      'Unpacker Username': item.Unpacker_Username || '',
      'Datetime Unpacked': item.datetime_unpacked || '',
      'Status': item.status || '',
      'Comments': item.comments || '',
    }));

    setData(formattedData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchPouchHistoryDataWithDate();
  };

  useEffect(() => {
    fetchPouchHistoryDataWithDate();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pouch History Data With Date');
    XLSX.writeFile(wb, 'PouchHistoryDataWithDate.xlsx');
  };

  const initialColumns = ['Unique ID', 'Pouch Number', 'Station Name', 'Batch Number', 'Medicine Name', 'Amount', 'Packer Username', 'Datetime Packed', 'QA Username', 'Datetime QA', 'Unpacker Username', 'Datetime Unpacked', 'Status', 'Comments'];
  const allColumns = ['Unique ID', 'Pouch Number', 'Station Name', 'Batch Number', 'Medicine Name', 'Amount', 'Packer Username', 'Datetime Packed', 'QA Username', 'Datetime QA', 'Unpacker Username', 'Datetime Unpacked', 'Status', 'Comments'];


  return (
    <div>
        <div className="Form-card">
            <div className="Form-card-body">
                <form onSubmit={handleFormSubmit} className="Form-form-container">
                    <h3 className='Form-Title'>Pouch History Data With Date</h3>
                    
                    <div className="Form-form-row">
                        <label>
                            Start Date:
                            <input className="date-input" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </label>
                    </div>

                    <div className="Form-form-row">
                        <label>
                            End Date:
                            <input className="date-input" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </label>
                    </div>

                    <div className="Form-form-row-button-container">
                        <button className="button" type="submit">Fetch Data</button>
                        {/* Add other buttons as needed */}
                    </div>
                </form>
            </div>
        </div>

        <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
        <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
);
};

export default PouchHistoryDataWithDate;