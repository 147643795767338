import Header from '../components/header'
import MedicineBagTable from '../components/EditTables/MedicineBagTable';  
import './EditUsers';  

const token = localStorage.getItem("token");

function EditMedicineBag() { 
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <MedicineBagTable token={token} />      
        </div>
    </div>
  );
}

export default EditMedicineBag;  