import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StyledDropdown from '../Styling components/StyledDropdown'
import { useSelector } from 'react-redux';

const CompleteOrders = ({ token, selectedOrder }) => {
  const [orders, setOrders] = useState([]);
  const [availableBatches, setAvailableBatches] = useState([]);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [selectedBatches, setSelectedBatches] = useState([{ batch: "", amount: 0 }]);
  const [amount, setAmount] = useState(0);
  const [completionSummary, setCompletionSummary] = useState(null);
  const [totalBatchAmount, setTotalBatchAmount] = useState(0); 
  const [isOrderComplete, setIsOrderComplete] = useState(false); // New state variable
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  


// Existing useEffect hooks
useEffect(() => {
  if (selectedOrder && selectedOrder.unique_id) {

    // Log this state change

    setSelectedOrderDetails(selectedOrder);

    // Add this line to fetch available batches when selectedOrder is updated
    if (selectedOrder["Item Ordered"]) {
      fetchAvailableBatches(selectedOrder["Item Ordered"]);
    }
  } else {
    setSelectedOrderDetails(null);
  }
}, [selectedOrder]);



  const fetchOrders = async () => {
    // Check for updates (only fetch if orders array is empty)
    if (orders.length === 0) {
      try {
        const response = await axios.get('/api/orders/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
      // Missing catch block starts here
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
      // Missing catch block ends here
    }
  };
  

  const fetchAvailableBatches = async (medicineName) => {
    // Check for updates (fetch only if availableBatches for this medicineName is not already loaded)
    if (!availableBatches.some(item => item.Medicine_Name === medicineName)) {
      try {
        const response = await axios.get(`/api/pouch_medicine_data_stores/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredBatches = response.data.filter(item => {
          return item.Medicine_Name === medicineName;
        });
        setAvailableBatches([...availableBatches, ...filteredBatches]);
      } catch (error) {
        console.error('Error fetching available batches:', error);
      }
    }
  };
  

  const addBatchDropdown = () => {
    setSelectedBatches([...selectedBatches, { batch: "", amount: 0 }]);
  };

  const updateSelectedBatch = (index, field, newValue) => {
    const newBatches = [...selectedBatches];
    newBatches[index][field] = newValue;
    setSelectedBatches(newBatches);
  };
  

  const handleCompleteOrder = async () => {
    const order = selectedOrderDetails;
    const summaryDrugs = []; 
    
    for (const selectedBatch of selectedBatches) {
      const batchInfo = availableBatches.find(b => b.Batch_Number === selectedBatch.batch);
      const batch = batchInfo ? { Batch_Number: batchInfo.Batch_Number, Total_Amount: batchInfo.Total_Amount } : null;
  
      if (batch && parseInt(batch.Total_Amount) >= selectedBatch.amount) {
        // Here you would typically update the database, but we are skipping it as per your request
        summaryDrugs.push({
          drugName: selectedOrder["Item Ordered"],
          batch: selectedBatch.batch,
          amount: selectedBatch.amount,
        });
      } else {
        alert('Not enough stock for the selected batch.');
      }
    }
  

    
    // Calculate the total batch amount sum
    const totalAmount = summaryDrugs.reduce((acc, curr) => acc + curr.amount, 0);
    

    
    setTotalBatchAmount(totalAmount);
  

    setCompletionSummary({
      drugs: summaryDrugs,
      totalAmount: selectedOrder["Amount"],
      status: 'Pending' // Set the initial status to Pending
    });
    setIsOrderComplete(true); 
  };
  

const handleConfirmCorrect = async () => {
  let firstSelectedBatch = null;

  if (selectedBatches.length > 0) {
    firstSelectedBatch = selectedBatches[0].batch;
  }

  let drugBarcode = null;
  try {
    const response = await axios.get(`/api/MedicineBatchByBatchNumber/${firstSelectedBatch}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      drugBarcode = response.data.drug_barcode;
    } else {
    }
  } catch (error) {
    console.error('Error fetching medicines:', error);
  }

  const amount = selectedBatches.reduce((acc, curr) => acc + curr.amount, 0);

  const updatedCompletionSummary = {
    order_status: 'Made up for delivery',
    Made_up_by: userBarcode,
    Madup_datetime: new Date().toISOString(),
    Actual_amount_sent: amount
  };

  if (selectedOrderDetails && selectedOrderDetails.unique_id) {
    try {
      const response = await axios.patch(`/api/orders/update/${selectedOrderDetails.unique_id}/`, updatedCompletionSummary, {  // Changed from axios.put to axios.patch
        headers: {
            Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error updating order:', error);
    }
  } else {
    console.error('Selected order or unique_id is undefined');
  }

  let payload = null;
  try {
    payload = {
      "location": stationBarcode,
      "user_barcode": userBarcode,
      "drug_barcode": drugBarcode,
      "transaction": "Removed to station",
      "amount": amount,
      datetime_transaction: new Date().toISOString(),
      "comments": `Order ${selectedOrderDetails.unique_id}`
    };

    const response = await axios.post(`/api/StockHoldingHistory/create/`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Error:', error);
  }
};



  
  

  return (
    <div className="complete-orders-container form-container">
      <h2 className="Title">Complete Orders</h2>
      {/* Display Selected Order Details */}
      {selectedOrder && (
        <div className="selected-order-details">
          <p><strong>Drug:</strong> {selectedOrder["Item Ordered"]}</p>
          <p><strong>Location:</strong> {selectedOrder["Ordering to"]}</p>
          <p><strong>Amount Needed:</strong> {selectedOrder["Amount"]}</p>
        </div>
      )}
      {/* Commented out due to undefined variables and functions. Uncomment and define them as necessary. */}
      {/*
      <div className="dropdown-container input-field">
        <label className={`dropdown-label ${selectedOrderId ? 'active' : ''}`}>
          Select Order
        </label>
        <select
          className="styled-dropdown"
          value={selectedOrderId || ''}
          onChange={handleOrderSelect}
        >
          <option value="">Select Order</option>
          {orderOptions.map((option, index) => (
            <option key={index} value={option.unique_id}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      */}
      {selectedBatches.map((batchInfo, index) => (
        <div key={index} className="batch-and-amount-container">
          <StyledDropdown
            options={availableBatches.map(b => ({ label: b.Batch_Number, value: b.Batch_Number }))}
            value={batchInfo.batch || ''}
            onChange={e => updateSelectedBatch(index, 'batch', e.target.value)}
            placeholder={batchInfo.batch ? '' : 'Select Batch'}
          />
          {/* Display Total_Amount */}
          {batchInfo.batch && (
            <p>
              Total Amount in Batch: {
                availableBatches.find(b => b.Batch_Number === batchInfo.batch).Total_Amount
              }
            </p>
          )}
          <div className="input-field">
            <label className={`dropdown-label ${batchInfo.amount ? 'active' : ''}`}>
              Enter Amount
            </label>
            <input
              type="number"
              className="styled-input"
              value={batchInfo.amount}
              onChange={e => updateSelectedBatch(index, 'amount', parseInt(e.target.value, 10))}
              placeholder="Amount"
            />
          </div>
          </div>
            ))}
            {completionSummary && (
              <div className="completion-summary">
                <h3>Completion Summary</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Drug Name</th>
                      <th>Batch</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {completionSummary.drugs.map((drug, index) => (
                      <tr key={index}>
                        <td>{drug.drugName}</td>
                        <td>{drug.batch}</td>
                        <td>{drug.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Total Batch Amount: {totalBatchAmount}</p> {/* Displaying the total batch amount */}
                <p>Total Order Amount: {completionSummary.totalAmount}</p>
                <p>Status: {completionSummary.status}</p>
              </div>
            )}
      {!isOrderComplete && (
        <>
          <button onClick={addBatchDropdown} className="button">Add Another Batch</button>
          <button onClick={handleCompleteOrder} className="button">Mark as Complete</button>
        </>
      )}
      
      {isOrderComplete && (
        <button onClick={handleConfirmCorrect} className="button">Confirm Correct</button>
      )}
    </div>
  );
  
};


export default CompleteOrders;
