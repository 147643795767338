import React from 'react';
import { useNavigate } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

const CookieConsentWrapper = () => {
  const navigate = useNavigate();

  const handleDecline = () => {
    alert('Sorry, you cannot login without accepting cookies.');
    navigate(-1); 
    document.cookie = "userCookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  return (
    <CookieConsent
    location="bottom"
    buttonText="Accept all cookies"
    declineButtonText="Reject cookies"
    onDecline={handleDecline}
    cookieName="userCookieConsent"
    style={{ background: "white", color: "#4e503b" }} // Changed background to white and text color
    buttonStyle={{ color: "#000", fontSize: "13px", borderRadius: "15px" }} // Black font for buttons, rounded corners
    declineButtonStyle={{ fontSize: "13px", color: "#000", borderRadius: "15px" }} // Black font for decline button, rounded corners
    expires={150}
    enableDeclineButton
  >
    Cookies on the Precise website and services. Cookies are files saved on your phone, tablet, or computer when you visit a website. We use cookies to store information about how you use the Precise website and services, such as the pages you visit. For more information, view our cookie statement. (Opens in a new window)
    <br />
    <strong>Essential cookies:</strong> These cookies enable basic functions like page navigation and access to secure areas of the website. Our website cannot function properly without these cookies and they can only be deactivated by changing your browser preferences.
  </CookieConsent>
  );
};

export default CookieConsentWrapper;
