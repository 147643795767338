// In MedicineBagUnpackPage component
import React, { useState } from 'react';
import Header from '../components/header';
import MedicineBagTable from '../components/Medicine_Bag/MedicineBagTable';
import CombinedMedicineBagUnpack from '../components/Medicine_Bag/CombinedMedicineBagUnpack';

const token = localStorage.getItem("token");

const MedicineBagUnpackPage = () => {
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    // Function to toggle the refresh trigger
    const toggleRefresh = () => {
        setRefreshTrigger(!refreshTrigger);
    };

    return (
        <div className='main-content'>
            <Header token={token}/>
            <CombinedMedicineBagUnpack token={token} onRefresh={toggleRefresh} />
            <MedicineBagTable token={token} refreshTrigger={refreshTrigger}/>  
        </div>
    );
};

export default MedicineBagUnpackPage;

