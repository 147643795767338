import React, { useState } from 'react';
import EditUserOnly from '../EditTables/EditUserOnly';
import '../../static/CSS/UserDetails.css';

const UserDetails = ({ user, token }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false); // Declare this state variable
  const [newPassword, setNewPassword] = useState(''); // Declare this state variable
  const [confirmPassword, setConfirmPassword] = useState(''); // Declare this state variable

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const response = await fetch(`/api/User/reset_password/${user.userBarcode}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        new_password: newPassword,
        confirm_password: confirmPassword,
      }),
    });
  
    if (response.ok) {
      alert('Password reset successfully');
    } else {
      alert('Error resetting password');
    }
  };
  
  return (
    <div>
      <div className="user-details">
        <div className="user-details-header">
          <h2>User Details</h2>
          <button className='button' onClick={() => setShowEdit(!showEdit)}>
            {showEdit ? 'Hide Edit' : 'Edit User'}
          </button>
          <button className='button' onClick={() => setShowPasswordReset(!showPasswordReset)}>
            {showPasswordReset ? 'Hide Reset' : 'Reset Password'}
          </button>

        </div>
        <div className="user-details-content">
          <div className="user-details-item">
            <label>Name: </label>
            <span>{user.name}</span>
          </div>
          <div className="user-details-item">
            <label>Position: </label>
            <span>{user.position}</span>
          </div>
          <div className="user-details-item">
            <label>User Barcode: </label>
            <span>{user.userBarcode}</span>
          </div>
          <div className="user-details-item">
            <label>Station: </label>
            <span>{user.station}</span>
          </div>
          <div className="user-details-item">
            <label>Pouch Number: </label>
            <span>{user.pouchNumber}</span>
          </div>
          <div className="user-details-item">
            <label>Pouch Type: </label>
            <span>{user.pouchType}</span>
          </div>
          <div className="user-details-item">
            <label>Carrier: </label>
            <span>{user.carrier}</span>
          </div>
          {showPasswordReset && (
            <form onSubmit={handlePasswordReset}>
              <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
              <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              <button type="submit">Submit</button>
            </form>
          )}

        </div>
      </div>
      {showEdit && <EditUserOnly user={user} token={token} />}
    </div>
  );
};

export default UserDetails;
