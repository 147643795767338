import Header from '../components/header'
import MedicineTable from '../components/EditTables/MedicineTable';


const token = localStorage.getItem("token");

 
function EditMedicine() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <MedicineTable token={token} />     
      </div>
    </div>
  );
}

export default EditMedicine;
