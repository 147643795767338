import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useProductLocationSubmit from './ProductLocationSubmit';
import LocationForm from '../Station Carrier Inputs/StationInput'; 
import { useSelector } from 'react-redux';

const ProductLocationForm = ({ token }) => {
  const [barcode, setBarcode] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [medicineBags, setMedicineBags] = useState([]);
  const [medicineBatch, setMedicineBatch] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [pouches, setPouches] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [consumableBatch, setConsumableBatch] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [databaseItems, setDatabaseItems] = useState([]);
  const [searchBarcode, setSearchBarcode] = useState('');
  const [showLocationForm, setShowLocationForm] = useState(false);
  const stationName = useSelector((state) => state.user.station);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const [itemFilter, setItemFilter] = useState(''); // New state for the item filter query

  const submitProductLocation = useProductLocationSubmit(token);

  // Fetch Medicine Bags
  useEffect(() => {
    const fetchMedicine_bags = async () => {
      try {
        const response = await axios.get('/api/MedicineBags/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setMedicineBags(response.data);
        }
      } catch (error) {
        console.error('Error fetching medicine bags:', error);
      }
    };
    fetchMedicine_bags();
  }, [token]);

  // Fetch Carriers
  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const response = await axios.get('/api/Carrier/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setCarriers(response.data);
        }
      } catch (error) {
        console.error('Error fetching carriers:', error);
      }
    };
    fetchCarriers();
  }, [token]);

  // Fetch Pouches
  useEffect(() => {
    const fetchPouches = async () => {
      try {
        const response = await axios.get('/api/Pouch/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setPouches(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouches:', error);
      }
    };
    fetchPouches();
  }, [token]);

  // Fetch Vehicles
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get('/api/Vehicle/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setVehicles(response.data);
        }
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };
    fetchVehicles();
  }, [token]);

  // Fetch Consumable Batch
  useEffect(() => {
    const fetchConsumableBatch = async () => {
      try {
        const response = await axios.get('/api/ConsumableBatch/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setConsumableBatch(response.data);
        }
      } catch (error) {
        console.error('Error fetching consumable batch:', error);
      }
    };
    fetchConsumableBatch();
  }, [token]);

    // Fetch Medicine Batch
    useEffect(() => {
      const fetchMedicineBatch = async () => {
          try {
          const response = await axios.get('/api/MedicineBatch/', {
              headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              },
          });
          if (response.status === 200) {
              setMedicineBatch(response.data);
  
          }
          } catch (error) {
          console.error('Error fetching Medicine Batch:', error);
          }
      };
      fetchMedicineBatch();
      }, [token]);



  // Handle barcode search within the loaded data
  useEffect(() => {
    const searchAllDatabases = () => {
      let allItems = [
        ...medicineBags.map(bag => ({ barcode: bag.medicine_bag_barcode, label: bag.medicine_bag_number, type: 'Medicine Bag' })),
        ...carriers.map(carrier => ({ barcode: carrier.carrier_barcode, label: carrier.carrier_number, type: 'Carrier' })),
        ...pouches.map(pouch => ({ barcode: pouch.pouch_barcode, label: pouch.pouch_number, type: 'Pouch' })),
        ...vehicles.map(vehicle => ({ barcode: vehicle.vehicle_barcode, label: vehicle.vehicle_number, type: 'Vehicle' })),
        ...consumableBatch.map(batch => ({ barcode: batch.product_barcode, label: batch.consumable_name, type: 'Consumable Batch' })),
        ...medicineBatch.map(drug => ({ barcode: drug.drug_barcode, label: drug.medicine_name, type: 'Medicine Batch' })),

      ];

      const matchedItem = allItems.find(item => item.barcode === searchBarcode);
      if (matchedItem) {
        setSearchResult(`Found in ${matchedItem.type}: ${matchedItem.label}`);
        setSelectedDatabase(matchedItem.type); // Update selected database based on the found item
        setSelectedItem(matchedItem.barcode); // Update selected item to the found item's barcode
      } else {
        setSearchResult('No item found with that barcode in any database.');
        setSelectedDatabase(''); // Reset selected database
        setSelectedItem(''); // Reset selected item
      }
    };

    if (searchBarcode) searchAllDatabases();
    else setSearchResult('');
  }, [searchBarcode, medicineBags, carriers, pouches, vehicles, consumableBatch, medicineBatch]);

  // Update the items dropdown when the selected database changes
  useEffect(() => {
    let items = [];
    switch (selectedDatabase) {
      case 'MedicineBags':
        items = medicineBags.map(bag => ({ id: bag.medicine_bag_barcode, label: bag.medicine_bag_number }));
        break;
      case 'Carriers':
        items = carriers.map(carrier => ({ id: carrier.carrier_barcode, label: carrier.carrier_number }));
        break;
      case 'Pouches':
        items = pouches.map(pouch => ({ id: pouch.pouch_barcode, label: pouch.pouch_number }));
        break;
      case 'Vehicles':
        items = vehicles.map(vehicle => ({ id: vehicle.vehicle_barcode, label: vehicle.vehicle_number }));
        break;
      case 'ConsumableBatch':
        items = consumableBatch.map(batch => ({ id: batch.product_barcode, label: batch.consumable_name }));
        break;
      case 'MedicineBatch':
        items = medicineBatch.map(drug => ({ id: drug.drug_barcode, label: drug.medicine_name }));
        break;
      default:
        items = [];
    }
  
    // This is the corrected part: filter the items based on the itemFilter query
    const filteredItems = items.filter(item => item.label.toLowerCase().includes(itemFilter.toLowerCase()));
  
    setDatabaseItems(filteredItems);
    // If the filter is cleared, it should reset to the full list for the selected database
    // This is handled implicitly by including itemFilter in the useEffect's dependency array
  }, [selectedDatabase, medicineBags, carriers, pouches, vehicles, consumableBatch, medicineBatch, itemFilter]); // Include itemFilter in the dependency array  
  const toggleLocationForm = () => setShowLocationForm(!showLocationForm);
  
  // Function to reset the form
  const resetForm = () => {
    setSelectedDatabase('');
    setSelectedItem('');
    setDatabaseItems([]);
    setSearchBarcode('');
    setSearchResult('');
    // Optionally reset other states as needed
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Determine the barcode to submit based on search or selection
    const submitBarcode = searchBarcode || selectedItem; // This assumes selectedItem holds the barcode when selected from dropdown
  
    // Ensure we have a barcode to submit, otherwise call onError with a message
    if (!submitBarcode) {
      console.error("No barcode provided for submission.");
      return;
    }
  
    // Use stationBarcode directly from the Redux store as the location barcode
    submitProductLocation(submitBarcode, stationBarcode,
      (message) => console.log(message),
      (error) => console.error(error)
    );
  };

  return (
    <div className="medicine-upload-card">
      <h2 className="medicine-upload-header">Product Location Upload</h2>
      <div className="medicine-upload-body">
        <form onSubmit={handleSubmit} className="medicine-upload-form">
          {/* Barcode Search input */}
          <div className="medicine-upload-item">
            <label htmlFor="searchBarcode" className="medicine-upload-label">Barcode Search:</label>
            <input
              type="text"
              id="searchBarcode"
              className="medicine-upload-input"
              value={searchBarcode}
              onChange={(e) => setSearchBarcode(e.target.value)}
              disabled={selectedDatabase !== ''} // Disable if a database is selected
            />
          </div>
  
          {searchResult && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-value">{searchResult}</span>
            </div>
          )}
  
          {/* Database selection dropdown, shown only if search barcode is not used */}
          {searchBarcode === '' && (
            <div className="medicine-upload-item">
              <label htmlFor="database" className="medicine-upload-label">Select Database:</label>
              <select
                id="database"
                className="medicine-upload-select"
                value={selectedDatabase}
                onChange={(e) => {
                  setSelectedDatabase(e.target.value);
                  setSelectedItem(''); // Reset selected item when changing database
                }}
              >
                <option value="">Select...</option>
              <option value="MedicineBags">Medicine Bags</option>
              <option value="Carriers">Carriers</option>
              <option value="Pouches">Pouches</option>
              <option value="Vehicles">Vehicles</option>
              <option value="ConsumableBatch">Consumables</option>
              <option value="MedicineBatch">Medicines</option>

            </select>
            </div>
          )}
  
         {/* Conditional rendering for Select Item Dropdown */}
         {selectedDatabase && searchBarcode === '' && (
            <>
              {/* Input field for filtering the items */}
              <div className="medicine-upload-item">
                <label htmlFor="itemFilter" className="medicine-upload-label">Filter Items:</label>
                <input
                  type="text"
                  id="itemFilter"
                  className="medicine-upload-input"
                  placeholder="Type to filter items..."
                  value={itemFilter}
                  onChange={(e) => setItemFilter(e.target.value)}
                />
              </div>

              {/* Filtered Select Item Dropdown */}
              <div className="medicine-upload-item">
                <label htmlFor="databaseItems" className="medicine-upload-label">Select Item:</label>
                <select
                  id="databaseItems"
                  className="medicine-upload-select"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                >
                  <option value="">Select...</option>
                  {databaseItems.map(item => (
                    <option key={item.id} value={item.id}>{item.label}</option>
                  ))}
                </select>
              </div>
            </>
          )}
  
          <div className="medicine-upload-item">
            <span className="medicine-upload-label">Current Station:</span>
            <span className="medicine-upload-value">{stationName}</span>
          </div>
          <div className="button-row">
            <button type="submit" className="medicine-upload-button submit-button">Submit</button>
            <button type="button" onClick={resetForm} className="medicine-upload-button refresh-button">Refresh</button>
            <button type="button" onClick={toggleLocationForm} className="medicine-upload-button change-location-button">Change Location</button>
            </div>
            <div>
          {showLocationForm && <LocationForm token={token} onClose={() => setShowLocationForm(false)} />}

          </div>
        </form>
      </div>
    </div>
  );
  
  
};

export default ProductLocationForm;
