import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Pie } from 'react-chartjs-2';

// Register the required components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const PouchTypesPieChart = ({ token }) => {
    const [chartData, setChartData] = useState(null);

    const colors = {
        'Hypo': 'Green',
        'Arrest': 'Red',
        'Resp': 'Blue',
        'Cardiac': 'Orange',
        'Specialist': 'Yellow',
        'Fluids': 'Black'
    };

    const fetchPouchTypesCount = async () => {
        try {
            const response = await axios.get('/api/pouch_types_count/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            const data = response.data;

            if (data && Array.isArray(data)) {
                const labels = data.map(item => item.pouch_type);
                const counts = data.map(item => item.count);
                const backgroundColors = labels.map(label => colors[label] || 'Grey');

                setChartData({
                    labels,
                    datasets: [{
                        data: counts,
                        backgroundColor: backgroundColors
                    }]
                });
            }
        } catch (error) {
            console.error('Error fetching pouch types count:', error);
        }
    };

    useEffect(() => {
        fetchPouchTypesCount();
    }, []);

    // Render the chart only if chartData is not null
    return (
        <div className="visualization-card">
            <h2 className="visualization-header">Pouch Types Packed</h2>
            <div className="visualization-body">
                {chartData && <Pie data={chartData} className="pie-chart-container" />}
            </div>
        </div>
    );
};

export default PouchTypesPieChart;
