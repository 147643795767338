import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';

const MedicineBagTable = ({ token, refreshTrigger }) => {  // Use refreshTrigger as the prop
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);
  const medicineBagBarcode = user.Medicine_bagBarcode;

  useEffect(() => {
    const fetchPackedPouches = async () => {
      try {
        const response = await axios.get(`api/packed_products_in_medicine_bag?Medicine_bag_Barcode=${medicineBagBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error('Error fetching packed pouches:', error);
      }
    };

    if (medicineBagBarcode) {
      fetchPackedPouches();
    }
  }, [token, refreshTrigger, medicineBagBarcode]);  // Include refreshTrigger in the dependency array

  const displayColumns = ['Product_Detail', 'Product_Type', 'MedicineBag_Status', 'medicine_bag_number'];
  const columnTitles = {

    'Product_Detail': 'Product Number',
    'Product_Type': 'Product Type',
    'MedicineBag_Status': 'Medicine Bag Status',
    'medicine_bag_number': 'Medicine Bag Number'
  };

  return (
    <div>
      <Table data={data} columns={displayColumns} columnTitles={columnTitles} />
    </div>
  );
};

export default MedicineBagTable;
