import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../../static/CSS/Pouchfiller.css';
import SubmitHandlePackComplete from './SubmitHandlePackComplete';

const PouchFiller = ({ token, reloadFlag }) => {
  const [pouchData, setPouchData] = useState([]);
  const pouch_number = useSelector((state) => state.user.pouchNumber);
  const pouch_type = useSelector((state) => state.user.pouchType);
  const pouch_barcode = useSelector((state) => state.user.pouchBarcode);
  const [allPacked, setAllPacked] = useState(false);
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  useEffect(() => {
    const fetchPouchData = async () => {
      try {
        const response = await axios.get(`/api/pouch_remaining_data/${pouch_barcode}/${pouch_type}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPouchData(response.data);
        checkAllPacked(response.data);
      } catch (error) {
        console.error('Error fetching pouch remaining data:', error);
      }
    };

    if (pouch_barcode) {
      fetchPouchData();
    }
  }, [pouch_barcode, pouch_type, token, reloadFlag]);

  const checkAllPacked = (data) => {
    const allZero = data.every(item => item.Remaining_Amount === 0);
    setAllPacked(allZero);
  };



  return (
    <div className="card">
      <div className="card-header">
        <h2>Pouch Filler</h2>
        {pouch_number && <div>Pouch Number: {pouch_number}</div>}
      </div>
      <div className="card-body">
        {pouch_number && pouchData.length ? (
          <table className="table table-bordered table-striped table-responsive-md">
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Remaining Amount</th>
                <th>Earliest Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {pouchData.map((medicine) => {
                const { Medicine_Name, Remaining_Amount, Earliest_Expiry_Date } = medicine;
                const rowClass = Remaining_Amount < 0 ? 'row-overfilled' : Remaining_Amount === 0 ? 'row-zero' : '';

                return (
                  <tr key={Medicine_Name} className={rowClass}>
                    <td>{Medicine_Name}</td>
                    <td>{Remaining_Amount}</td>
                    <td>{Earliest_Expiry_Date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No data available or pouch number not found.</p>
        )}
      </div>
      <div className={`status-text ${allPacked ? 'text-green' : 'text-orange'}`}>
        {allPacked ? 'Set as Packed' : 'Check and Pack'}
      </div>
      <SubmitHandlePackComplete 
          pouchBarcode={pouch_barcode} 
          token={token} 
          pouchData={pouchData}
        />
    </div>

  );
};

export default PouchFiller;