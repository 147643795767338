import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import LocationForm from '../components/Station Carrier Inputs/StationInput';
import Carrier_inputs from '../components/Station Carrier Inputs/Carrier_inputs';
import { useSelector } from 'react-redux';
import headerImage from '../static/images/Scanner.png';
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem("token");

const CarrierLoad = () => {
  const { name, carrier, station } = useSelector((state) => state.user);
  const [showLocationForm, setShowLocationForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
  }, [name]);
  const handlePackClick = () => {
    navigate("/CarrierPackPage");
  };

  const handleMoveClick = () => {
    navigate("/CarrierMovepage");
  };

  const handleUnpackClick = () => {
    navigate("/CarrierUnpackPage");
  };
  return (
    <div className='main-content'>
      <Header />

      <div className="packing-header-container">
        <div className="packing-header-box">
          <div className="text-content">
            <h2 className='packing-title'>Carrier Operations</h2>
            <p className="user-greeting">Hi {name}</p>
            <button className="button-select-station" onClick={() => setShowLocationForm(!showLocationForm)}>Select Station</button>

          </div>

          <img src={headerImage} alt="Header" className="header-image"/>

        </div>
      </div>






      <div className="detail-content-wrapper">
        <div className="detail-header-box">
          <div className="unpacking-detail">
            <span className="detail-label">Station Name:</span>
            <span className="detail-value">{station}</span>
          </div>
          <div className="unpacking-detail">
            <span className="detail-label">Carrier:</span>
            <span className="detail-value">{carrier ? carrier : 'No carrier selected'}</span>
          </div>

          <button className='button-pack' onClick={handlePackClick}>Pack Carrier</button>
          <button className='button-store' onClick={handleMoveClick}>Move Carrier</button>
          <button className='button-unpack' onClick={handleUnpackClick}>Unpack Carrier</button>

        </div>

        <div className="unpacking-actions">
          <Carrier_inputs token={token} />
        </div>
        {showLocationForm && (
          <div className="unpacking-actions">
            <LocationForm token={token} />
          </div>
        )}
      </div>

    </div>
  );
};

export default CarrierLoad;
