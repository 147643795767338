import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const MedicineBatchForm = ({ token, selectedMedicine }) => {
  const [medicine_name, setMedicineName] = useState('');
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const [batch_number, setBatchNumber] = useState('');
  const [expiry_date, setExpiryDate] = useState('');
  const [medicine_pricing, setMedicinePricing] = useState('');
  const [drug_barcode, setDrugBarcode] = useState('');
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);
  const [supplier, setSupplier] = useState('');
  const [pack_size, setPackSize] = useState('');
  const [medicines, setMedicines] = useState([]);


  useEffect(() => {
    fetchMedicines();
    if (selectedMedicine) {
      setMedicineName(selectedMedicine['Medicine name'],);
      setBatchNumber(selectedMedicine['Batch Number']);
      if (selectedMedicine['Expiry Date']) {
        const [day, month, year] = selectedMedicine['Expiry Date'].split('/');
        const expiryDateObj = new Date(`${year}-${month}-${day}`);
        if (!isNaN(expiryDateObj)) {
            const formattedExpiryDate = expiryDateObj.toISOString().split('T')[0];
            setExpiryDate(formattedExpiryDate);
        } else {
            console.error('Invalid expiry date:', selectedMedicine['Expiry Date']);
        }
    }
    
      setMedicinePricing(selectedMedicine['Medicine Pricing']);
      setDrugBarcode(selectedMedicine['Drug Barcode']);
      setPackSize(selectedMedicine['Pack Size']);
      setSupplier(selectedMedicine['Supplier']);
      setSelectedMedicineId(selectedMedicine.Unique_id);
    }
  }, [selectedMedicine]);


  const handleGenerateBarcode = async () => {
    const prefix = 'M'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setDrugBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/MedicineBatch/create/', {
        medicine_name: medicine_name,
        batch_number: batch_number,
        expiry_date: expiry_date,
        medicine_pricing: medicine_pricing,
        drug_barcode: drug_barcode,
        added_by: userBarcode,
        date_added: new Date().toISOString().split('T')[0],
        supplier: supplier,
        pack_size: pack_size,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      console.log('Medicine batch created');
      window.location.reload();
    } catch (error) {
      console.error('Error creating medicine batch:', error);
    }
  };
  
  
  
  

    const handleRefresh = () => {
        setMedicineName('');
        setBatchNumber('');
        setExpiryDate('');
        setMedicinePricing('');
        setDrugBarcode('');
        setSupplier('');
        setPackSize('');
        setSelectedMedicineId(null);
    };

    const handleDeleteConfirmation = () => {
        if (window.confirm(`Are you sure you want to delete batch number ${batch_number} for ${medicine_name}?`)) {
        handleDelete();
        }
    };

    const handleUpdate = async () => {
      try {
        await axios.put(`/api/MedicineBatch/update/${selectedMedicineId}/`, {
          medicine_name: medicine_name,
          batch_number: batch_number,
          expiry_date: expiry_date,
          medicine_pricing: medicine_pricing,
          drug_barcode: drug_barcode,
          added_by: userBarcode,
          date_added: new Date().toISOString().split('T')[0],
          supplier: supplier,
          pack_size: pack_size,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        console.log('Medicine batch updated');
        window.location.reload();
      } catch (error) {
        console.error('Error updating medicine batch:', error);
      }
    };
    
    
  
  
    const handleDelete = async () => {
      try {
        await axios.delete(`/api/MedicineBatch/delete/${selectedMedicineId}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        window.location.reload();
      } catch (error) {
        console.error('Error deleting medicine batch:', error);
      }
    };
    
    

    
    const fetchMedicines = async () => {
      try {
        const response = await fetch('/api/Medicine/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data)) {
            setMedicines(data);
          } else {
            console.error('Expected an array but got:', data);
          }
        } else {
          console.error('Error fetching medicines:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching medicines:', error);
      }
    };
    

    return (
      <DynamicForm
        title="Add New Medicine Batch"
        questions={[
          {
            type: 'select',
            text: 'Medicine Name',
            styled: true,
            options: medicines.map(medicine => ({ value: medicine.medicine_name, label: medicine.medicine_name })),
            value: medicine_name,
            handleChange: (e) => setMedicineName(e.target.value)
          },
          {
            type: 'text',
            text: 'Batch Number',
            value: batch_number,
            handleChange: (e) => setBatchNumber(e.target.value),
          },
          {
            type: 'date',
            text: 'Expiry Date',
            value: expiry_date,
            handleChange: (e) => setExpiryDate(e.target.value),
          },
          {
            type: 'text',
            text: 'Medicine Pricing',
            value: medicine_pricing,
            handleChange: (e) => setMedicinePricing(e.target.value),
          },
          {
            type: 'text',
            text: 'Drug Barcode',
            value: drug_barcode,
            handleChange: (e) => setDrugBarcode(e.target.value),
            isLocked: true 
          },
          {
            type: 'text',
            text: 'Supplier',
            value: supplier,
            handleChange: (e) => setSupplier(e.target.value),
          },
          {
            type: 'number',
            text: 'Pack Size',
            value: pack_size,
            handleChange: (e) => setPackSize(e.target.value),
          },
   
          ...(!drug_barcode ? [{
            text: 'Generate Barcode',
            type: 'generateBarcode'
          }] : [])
        ]}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleUpdate={handleUpdate}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleGenerateBarcode={handleGenerateBarcode} 
        setDrugBarcode={setDrugBarcode} 
        selectedId={selectedMedicineId}
        showSearchBar={true}
      />
    );
  }

export default MedicineBatchForm;