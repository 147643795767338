import React, { useState, useEffect } from 'react';
import PouchTypeForm from "./PouchTypeForm";

const PouchTypeTable = ({token}) => {
  let [pouchTypes, setPouchTypes] = useState([]);
  const [selectedPouchType, setSelectedPouchType] = useState(null);

  useEffect(() => {
    getPouchTypes();
  }, []);

  let getPouchTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      let response = await fetch('/api/PouchTypeName/', requestOptions);
      let data = await response.json();
      setPouchTypes(data);
    } catch (error) {
      console.error('Error fetching pouch types:', error);
    }
  };

  const handleRowClick = (pouchType) => {
    setSelectedPouchType(pouchType);
  };

  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        
        <div className="table-list" style={{ flex: 1 }}>
        <div className="form-container">
        <div style={{ flex: 1 }}>
        <PouchTypeForm selectedPouchType={selectedPouchType}  token={ token } />
      </div>
      </div>
          <table>
            <thead>
              <tr>
                <th>Pouch Type Name</th>
              </tr>
            </thead>
            <tbody>
            {Array.isArray(pouchTypes) && pouchTypes.map((pouchType, index) => (
                <tr key={index} onClick={() => handleRowClick(pouchType)}>
                  <td>{pouchType.pouch_type_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default PouchTypeTable;
