import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PouchesPackedByDateChart = ({ token }) => {
    const [chartData, setChartData] = useState(null);

    const fetchPouchesPackedByDate = async () => {
        try {
            const response = await axios.get('/api/pouches_packed_by_date/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data;
            const labels = data.map(item => item.packed_date);
            const pouchCounts = data.map(item => item.pouch_count);

            setChartData({
                labels: labels,
                datasets: [{
                    label: 'Pouches Packed',
                    data: pouchCounts,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.1 // Adds a slight curve to the line
                }]
            });
        } catch (error) {
            console.error('Error fetching pouches packed by date:', error);
        }
    };

    useEffect(() => {
        fetchPouchesPackedByDate();
    }, []);

    return (
        <div className="visualization-card">
            <h2 className="visualization-header">Pouches Packed by Date</h2>
            <div className="visualization-body">
                {chartData ? <Line data={chartData} className="pie-chart-container" /> : <p>Loading...</p>}
            </div>
        </div>
    );
};

export default PouchesPackedByDateChart;
