import Header from '../components/header'
import VehicleTable from '../components/EditTables/VehicleTable'; // Import the VehicleTable component
import './EditUsers'; // Assuming this is your CSS import

const token = localStorage.getItem("token");

function EditVehicle() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <VehicleTable token={token} />    
        </div>
    </div>
  );
}

export default EditVehicle;
