import Header from '../components/header'
import CarrierTable from '../components/EditTables/CarrierTable';
import './EditUsers';

const token = localStorage.getItem("token");

function EditCarrier() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <CarrierTable token={token} />     
        </div>
    </div>
  );
}

export default EditCarrier;