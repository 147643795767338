import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const CarrierPackedContents = ({ token }) => {
  const [data, setData] = useState([]);
  const [carrierNumber, setCarrierNumber] = useState('');
  const [allCarrierNumbers, setAllCarrierNumbers] = useState([]);
  const [filteredCarrierNumbers, setFilteredCarrierNumbers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const fetchAllCarrierNumbers = async () => {
      try {
        const response = await axios.get('/api/packed_pouches_in_carrier/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Response from /api/packed_pouches_in_carrier/:", response.data);
        const carrierNumbers = [...new Set(response.data.map(item => item.Carrier_Number))];
        setAllCarrierNumbers(carrierNumbers);
        setFilteredCarrierNumbers(carrierNumbers);
      } catch (error) {
        console.error('Error fetching carrier numbers:', error);
      }
    };

    fetchAllCarrierNumbers();
  }, [token]);

  useEffect(() => {
    if (searchTerm) {
      const filteredNumbers = allCarrierNumbers.filter(number => number.includes(searchTerm));
      setFilteredCarrierNumbers(filteredNumbers);
    } else {
      setFilteredCarrierNumbers(allCarrierNumbers);
    }
  }, [searchTerm, allCarrierNumbers]);

  useEffect(() => {
    const fetchDataForCarrierNumber = async () => {
      try {
        const response = await axios.get(`/api/carrier_contents_with_number/${carrierNumber}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
        console.log("Data for selected carrier:", response.data);
      } catch (error) {
        console.error('Error fetching data for carrier:', error);
      }
    };

    if (carrierNumber) {
      fetchDataForCarrierNumber();
    }
  }, [carrierNumber, token]);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Carrier Packed Contents');
    XLSX.writeFile(wb, 'CarrierPackedContents.xlsx');
  };


  const handleFetchData = (e) => {
    e.preventDefault();
    if (carrierNumber) {
      setShowTable(true);
    }
  };

  const initialColumns = ['Unique_ID', 'Pouch_Number', 'Carrier_Status', 'Carrier_Number'];
  const allColumns = ['Unique_ID', 'Pouch_Barcode', 'Pouch_Number', 'Carrier_Status', 'Carrier_Barcode', 'Carrier_Number'];

  return (
    <div>
      <div className="Form-card">
        <div className="Form-card-body">
          <form className="Form-form-container" onSubmit={handleFetchData}>
            <h3 className='Form-Title'>Carrier Packed Contents</h3>
            <div className="Form-form-row">
              <label htmlFor="carrierSearch">Carrier Number Search</label>
              <input 
                id="carrierSearch"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Carrier Number"
              />
            </div>
            <div className="Form-form-row">
              <label>
                Select Carrier Number:
                <select 
                  value={carrierNumber} 
                  onChange={(e) => setCarrierNumber(e.target.value)}
                >
                  <option value="" disabled>Select Carrier Number</option>
                  {filteredCarrierNumbers.map(number => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="Form-form-row">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      {showTable && (
        <div>
          <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
          <button className='button' onClick={downloadExcel}>Download Excel</button>
        </div>
      )}
    </div>
  );
};

export default CarrierPackedContents;
