import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setStation, setStationBarcode } from '../../Redux/slices/userSlice';
import StyledDropdown from "../Styling components/StyledDropdown"

const StationInput = ({ token }) => {
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState('');
  const [barcodeSearch, setBarcodeSearch] = useState('');
  const [foundStation, setFoundStation] = useState(null); // New state for station found via barcode search
 
  const dispatch = useDispatch();

  // Fetch stations on component mount
  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get('/api/Station/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        setStations(response.data);
      } catch (error) {
        console.error('Error fetching stations:', error);
      }
    };

    fetchStations();
  }, [token]);

  // Handle automatic lookup when barcodeSearch changes
  useEffect(() => {
    const handleBarcodeSearch = async () => {
      if (!barcodeSearch) {
        setFoundStation(null); // Clear found station if barcode search is empty
        return;
      }

      try {
        const response = await axios.get(`/api/StationByBarcode/${barcodeSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setFoundStation(response.data); // Store found station data
          setSelectedStation(response.data.station_name); // Update input display
        } else {
          setFoundStation(null);
        }
      } catch (error) {
        console.error('No station found with that barcode:', error);
        setFoundStation(null);
        setSelectedStation('');
      }
    };

    handleBarcodeSearch();
  }, [barcodeSearch, token]);

  const handleResetForm = () => {
    setSelectedStation('');
    setBarcodeSearch('');
    setFoundStation(null); 

  };


  const handleStationChange = (e) => {
    setSelectedStation(e.target.value);
    setBarcodeSearch('');
    setFoundStation(null); 
  };

  const handleStationSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Use foundStation if barcode search was used, otherwise find the station in the list
    const stationToSet = foundStation || stations.find(station => station.station_name === selectedStation);
    if (stationToSet) {
      // Update Redux store
      dispatch(setStation(stationToSet.station_name));
      dispatch(setStationBarcode(stationToSet.station_barcode));

      // Update local storage
      localStorage.setItem("stationName", stationToSet.station_name);
      localStorage.setItem("stationBarcode", stationToSet.station_barcode);


    }
  };


  const dropdownOptions = stations.map(station => ({
    value: station.station_name,
    label: station.station_name,
  }));

  return (
    <div className="packing-app">
      <div className="packing-input-container">
        <h2>Station Selection</h2>
        <div className="packing-main-content">
          <form onSubmit={handleStationSubmit}>
            {selectedStation === '' && (
              <StyledDropdown
                options={dropdownOptions}
                value={selectedStation}
                onChange={handleStationChange}
                placeholder="Select Station"
                disabled={!!barcodeSearch} // Disable dropdown if barcode search has input
              />
            )}
            {selectedStation === '' ? (
              <input
                type="text"
                value={barcodeSearch}
                onChange={(e) => setBarcodeSearch(e.target.value)}
                placeholder="Search by Barcode"
                disabled={!!selectedStation} // Disable if a station is selected from the dropdown
              />
            ) : (
              <div>Selected Station: {selectedStation}</div> // Display selected station name
            )}
            <button type="submit" className="card-button">Set Station</button>
            <button type="button" onClick={handleResetForm} className="card-button">Refresh</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StationInput;
