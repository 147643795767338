import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StorageBinForm = ({ token, selectedBin, storageUnits, onFormSubmit }) => {
  const [binBarcode, setBinBarcode] = useState('');
  const [binName, setBinName] = useState('');
  const [selectedStorageUnitBarcode, setSelectedStorageUnitBarcode] = useState(''); // Updated state
  const [capacity, setCapacity] = useState('');
  const [reorderLevel, setReorderLevel] = useState(0);

  useEffect(() => {
    if (selectedBin) {
      setBinBarcode(selectedBin.bin_barcode);
      setBinName(selectedBin.bin_name);
      setSelectedStorageUnitBarcode(selectedBin.storage_unit_barcode); // Use unit barcode instead of ID
      setCapacity(selectedBin.capacity);
      setReorderLevel(selectedBin.Reorder_Level || 0); // Initialize reorder level from selected bin
    }
  }, [selectedBin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      bin_barcode: binBarcode,
      bin_name: binName,
      storage_unit_barcode: selectedStorageUnitBarcode, // Send storage unit barcode
      capacity: parseInt(capacity, 10), // Ensure numeric fields are sent as numbers
      Reorder_Level: parseInt(reorderLevel, 10), // Ensure numeric fields are sent as numbers
    };
  
  
    const url = selectedBin ? `/api/bin/update/${binBarcode}/` : '/api/bin/create/';
  
    try {
      const response = await axios({
        method: selectedBin ? 'put' : 'post',
        url: url,
        data: formData,
        headers: { Authorization: `Bearer ${token}` },
      });
  
      alert('Bin processed successfully');
      onFormSubmit(); 
    } catch (error) {
      console.error('Error processing bin:', error);
      alert('Error processing bin');
    }
  };

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete bin with barcode ${binBarcode}?`)) {
      try {
        await axios.delete(`/api/bin/delete/${binBarcode}/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        alert('Bin deleted successfully');
        onFormSubmit(); // Callback to refresh the component or navigate
      } catch (error) {
        console.error('Error deleting bin:', error);
        alert('Error deleting bin');
      }
    }
  };

  const handleGenerateBarcode = async () => {
    const prefix = 'SB'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setBinBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  const resetForm = () => {
    setBinBarcode('');
    setBinName('');
    setSelectedStorageUnitBarcode('');
    setCapacity('');
    setReorderLevel(0); // Assuming 0 is the default value for reorder level
  };
  

  return (
    <div className="form-container">
      <h2>{selectedBin ? 'Edit Bin' : 'Add New Bin'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="binBarcode">Bin Barcode:</label>
          <input
            id="binBarcode"
            type="text"
            value={binBarcode}
            onChange={(e) => setBinBarcode(e.target.value)}
            required
          />
          {!selectedBin && <button type="button" onClick={handleGenerateBarcode}>Generate Barcode</button>}
        </div>
        <div>
          <label htmlFor="binName">Bin Name:</label>
          <input
            id="binName"
            type="text"
            value={binName}
            onChange={(e) => setBinName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="storageUnit">Storage Unit:</label>
          <select
            id="storageUnit"
            value={selectedStorageUnitBarcode}
            onChange={(e) => setSelectedStorageUnitBarcode(e.target.value)}
            required
          >
            <option value="">Select a Storage Unit</option>
            {storageUnits.map((unit) => (
              <option key={unit.id} value={unit.id}>{unit.unit_name}</option> // Using unit.id for value
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="capacity">Capacity:</label>
          <input
            id="capacity"
            type="number"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="reorderLevel">Reorder Level:</label>
          <input
            id="reorderLevel"
            type="number"
            value={reorderLevel}
            onChange={(e) => setReorderLevel(e.target.value)}
            required
          />
        </div>
        <div>
          <button type="submit" className="submit-btn">{selectedBin ? 'Update Bin' : 'Add Bin'}</button>
          {selectedBin && <button type="button" onClick={handleDelete} className="delete-btn">Delete Bin</button>}
          <button type="button" onClick={resetForm} className="refresh-btn">Refresh</button>
        </div>
      </form>
      {selectedBin && <button onClick={handleDelete} className="delete-btn">Delete Bin</button>}
    </div>
  );
};

export default StorageBinForm;

