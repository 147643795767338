import React from 'react';
import ReactDOM from 'react-dom/client'; // Corrected import path
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/store';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement); // Use the createRoot method

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
