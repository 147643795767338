import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../static/CSS/NotPresentReasons.css';

const MedicineBagType = ({ token }) => {
  const [options, setOptions] = useState([]); // Will now store objects including `Dropdown_Options` and `Unique_ID`
  const [newOption, setNewOption] = useState('');
  const headers = { 'Authorization': `Bearer ${token}` };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get('api/dropdown_options/', { headers });
        const medicineBagTypes = response.data.filter(item => item.Dropdown_Name === 'Medicine_Bag_Type');
        setOptions(medicineBagTypes);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleAddOption = async () => {
    try {
      const response = await axios.post('api/dropdown_options/create/', {
        Dropdown_Options: newOption,
        Dropdown_Name: 'Medicine_Bag_Type',
      }, { headers });

      if (response.status === 201) {
        setOptions([...options, response.data]);
        setNewOption('');
      }
    } catch (error) {
      console.error('Error adding new option:', error);
    }
  };

  const handleDeleteOption = async (uniqueID) => {
    try {
      const response = await axios.delete(`api/dropdown_options/delete/${uniqueID}`, { headers });
      if (response.status === 204) {
        setOptions(options.filter(item => item.Unique_ID !== uniqueID));
      }
    } catch (error) {
      console.error('Error deleting option:', error);
    }
  };

  return (
    <div className="not-present-reasons">
      <h2>Medicine Bag Types</h2>
      <ul className="options-list">
        {options.map((optionObj, index) => (
          <li key={index}>
            {optionObj.Dropdown_Options} <button className="delete-button" onClick={() => handleDeleteOption(optionObj.Unique_ID)}>Delete</button>
          </li>
        ))}
      </ul>
      <div className="add-option">
        <input className="option-input" type="text" value={newOption} onChange={(e) => setNewOption(e.target.value)} />
        <button className="add-button" onClick={handleAddOption}>Add Option</button>
      </div>
    </div>
  );
};

export default MedicineBagType;
