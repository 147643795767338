// MedicineBagPackPage.js

import React, { useState } from 'react';
import Header from '../components/header';
import MedicinebagPackCombined from '../components/Medicine_Bag/MedicinebagPackCombined';
import MedicineBagTable from '../components/Medicine_Bag/MedicineBagTable';
import PouchDetailsCard from '../components/Display/PouchDetailsCard';
import '../static/CSS/flex-container.css';

const MedicineBagPackPage = () => {
    const [reloadFlag, setReloadFlag] = useState(false);
    const [pouchBarcode, setPouchBarcode] = useState('');

    const handleReload = () => {
        setReloadFlag(!reloadFlag);
    };

    return (
        <div className='main-content'>
            <Header token={localStorage.getItem("token")}/>
            <div className="flex-container" style={{ display: 'flex', alignItems: 'start', gap: '20px' }}>
                <MedicinebagPackCombined 
                    token={localStorage.getItem("token")} 
                    onReload={handleReload}
                    setPouchBarcodeExport={setPouchBarcode}
                />
                <PouchDetailsCard 
                    token={localStorage.getItem("token")}
                    pouchBarcode={pouchBarcode}
                />
            </div>
            <MedicineBagTable token={localStorage.getItem("token")} refreshTrigger={reloadFlag}/>
        </div>
    );
};

export default MedicineBagPackPage;

