import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const PouchTypeForm = ({ token, selectedPouchType }) => {
  const [pouchTypeName, setPouchTypeName] = useState('');
  const [selectedPouchTypeId, setSelectedPouchTypeId] = useState(null);

  useEffect(() => {
    if (selectedPouchType) {
      setPouchTypeName(selectedPouchType.pouch_type_name);
      setSelectedPouchTypeId(selectedPouchType.pouch_type_name);
    }
  }, [selectedPouchType]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/PouchTypeName/create/', {
        pouch_type_name: pouchTypeName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating pouch type:', error);
    }
  };
  

  const handleRefresh = () => {
    setPouchTypeName('');
    setSelectedPouchTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${pouchTypeName}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/PouchTypeName/update/${selectedPouchTypeId}/`, {
        pouch_type_name: pouchTypeName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly included
        },
      });
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating pouch type:', error);
    }
  };
  

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/PouchTypeName/delete/${selectedPouchTypeId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 204) {
        window.location.reload();
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error deleting pouch type:', error);
    }
  };
  
  
  const questions = [
    {
      type: 'text',
      text: 'Pouch Type Name',
      value: pouchTypeName,
      handleChange: (e) => setPouchTypeName(e.target.value),
    },
  ];

  return (
    <DynamicForm
      title="Add New Pouch Type Name"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedPouchTypeId}
    />
  );
};
export default PouchTypeForm;
