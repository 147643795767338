import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useProductLocationSubmit from './ProductLocationSubmit';
import LocationForm from '../Station Carrier Inputs/StationInput'; 
import '../../static/CSS/Locationform.css';

const SpecificLocationForm = ({ token, type }) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [searchBarcode, setSearchBarcode] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const stationName = useSelector((state) => state.user.station);
  const stationBarcode = useSelector((state) => state.user.station_barcode);    
  const [showLocationForm, setShowLocationForm] = useState(false);
  const submitProductLocation = useProductLocationSubmit(token);

    
    const getApiEndpoint = (type) => {
    

      switch (type) {
        // Ensure these endpoints are correct and match your API's structure
        case 'medicineBags': return '/api/MedicineBags/';
        case 'pouches': return '/api/Pouch/';
        case 'vehicles': return '/api/Vehicle/';
        case 'carriers': return '/api/Carrier/';
        case 'consumableBatch': return '/api/ConsumableBatch/';
        default: return '';
      }
    };
  
    const mapItems = (data, type) => {

        switch (type) {
          case 'medicineBags':
            return data.map(item => ({
              id: item.medicine_bag_barcode,
              label: item.medicine_bag_number,
              barcode: item.medicine_bag_barcode
            }));
          case 'pouches':
            return data.map(item => ({
              id: item.pouch_barcode,
              label: item.pouch_number,
              barcode: item.pouch_barcode
            }));
          case 'vehicles':
            return data.map(item => ({
              id: item.vehicle_barcode,
              label: item.vehicle_number,
              barcode: item.vehicle_barcode
            }));
          case 'carriers':
            return data.map(item => ({
              id: item.carrier_barcode,
              label: item.carrier_number,
              barcode: item.carrier_barcode
            }));
          case 'consumableBatch':
            return data.map(item => ({
              id: item.product_barcode,
              label: item.consumable_name,
              barcode: item.product_barcode
            }));
          default:
            return []; // Return an empty array if the type does not match any case
        }
      };
  
      useEffect(() => {
        const fetchItems = async () => {
          const apiEndpoint = getApiEndpoint(type);
          try {
            const response = await axios.get(apiEndpoint, {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
              setItems(mapItems(response.data, type));
            }
          } catch (error) {
            console.error(`Error fetching ${type}:`, error);
          }
        };
    
        if (type) fetchItems();
      }, [token, type]);
    
      useEffect(() => {
    }, [items]);

    useEffect(() => {
        // Moved inside useEffect to ensure it captures the latest state
        if (searchBarcode) {
            const matchedItem = items.find(item => item.barcode === searchBarcode);
            if (matchedItem) {
                setSearchResult(`Found: ${matchedItem.label}`);
                setSelectedItem(matchedItem.id);
            } else {
                setSearchResult('No item found with that barcode.');
                setSelectedItem('');
            }
        } else {
            setSearchResult('');
            setSelectedItem('');
        }
    }, [searchBarcode, items]);

    const resetForm = () => {
        setSelectedItem('');
        setSearchBarcode('');
        setSearchResult('');
        // Optionally reset other states as needed
      };


      const handleSubmit = (e) => {
        e.preventDefault();
      
        const submitBarcode = searchBarcode || selectedItem; // This assumes selectedItem holds the barcode when selected from dropdown
      
        if (!submitBarcode || !stationBarcode) {
          console.error("No barcode or station barcode provided for submission.");
          return;
        }
      
        submitProductLocation(submitBarcode, stationBarcode,
          (message) => console.log("Submission successful:", message),
          (error) => console.error("Submission failed:", error)
        );
        resetForm()
      };


      const toggleLocationForm = () => {
        console.log('Toggling location form');
        setShowLocationForm(!showLocationForm);
    };
  
      return (
        <div className="medicine-upload-card">
          <h2 className="medicine-upload-header">Product Location Upload - {type ? type.charAt(0).toUpperCase() + type.slice(1) : 'All'}</h2>
          <div className="medicine-upload-body">
            <form onSubmit={handleSubmit} className="medicine-upload-form">
              <div className="medicine-upload-item">
                <label htmlFor="searchBarcode" className="medicine-upload-label">Barcode Search:</label>
                <input
                  type="text"
                  id="searchBarcode"
                  className="medicine-upload-input"
                  value={searchBarcode}
                  onChange={(e) => {
                      setSearchBarcode(e.target.value);
                      setSelectedItem(''); // Clear selected item when starting a new search
                  }}
                  disabled={!!selectedItem} // Disable if an item is selected
                />
              </div>
    
              {searchResult && (
                <div className="medicine-upload-item">
                  <span className="medicine-upload-value">{searchResult}</span>
                </div>
              )}
    
              <div className="medicine-upload-item">
                <label htmlFor="selectedItem" className="medicine-upload-label">Select Item:</label>
                <select
                  id="selectedItem"
                  className="medicine-upload-select"
                  value={selectedItem}
                  onChange={(e) => {
                      setSelectedItem(e.target.value);
                      setSearchBarcode(''); // Clear barcode search when item is selected
                      setSearchResult(''); // Clear search result
                  }}
                >
                  <option value="">Select...</option>
                  {items.map(item => (
                    <option key={item.id} value={item.id}>{item.label}</option>
                  ))}
                </select>
              </div>
    
              <div className="medicine-upload-item">
            <span className="medicine-upload-label">Current Station:</span>
            <span className="medicine-upload-value">{stationName}</span>

          </div>

          <div className="button-row">
                <button type="submit" className="medicine-upload-button submit-button">Submit</button>
                <button type="button" onClick={resetForm} className="medicine-upload-button refresh-button">Refresh</button>
                <button type="button" onClick={toggleLocationForm} className="medicine-upload-button change-location-button">Change Location</button>
              </div>
            </form>
            {showLocationForm && <div style={{ marginTop: '20px' }}></div>}
            {showLocationForm && <LocationForm token={token} />}
          </div>
        </div>
      );
    };
    
    export default SpecificLocationForm;