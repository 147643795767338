// src/pages/LandingPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import LandingPageHeader from '../../components/Landingscreen/LandingPageHeader';
import HeaderImage from '../../static/images/Header for the webpage.PNG';
import PTlogo from '../../static/images/PTlogo.png';
import PTwoodart from '../../static/images/PTwoodart.png';
import PTart from '../../static/images/PTart1.png';
import PTech from '../../static/images/PTech.png';
import PTechWhite from '../../static/images/PTech white.png';
import '../../static/CSS/LandingPageHeader.css';

const LandingPage = () => {
  let navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <div className="landing-page">
      <img src={HeaderImage} alt="Precise PouchTrack" className="landing-page-header-image" />

      <LandingPageHeader />

      <section className="welcome-section">
        <h1>Welcome to Precise Software</h1>
        <div className="section-content">
          <p>Leading the way in medicine packing and tracking technology.</p>
          <img src={PTlogo} alt="PT Logo" className="pt-logo" />
        </div>
      </section>

      <section className="about-section">
        <h2>About Precise PouchTrack</h2>
        <div className="section-content">
          <div>
            <p>Our product provides a comprehensive solution for managing and tracking medications and consumables through an integrated service. Utilising the latest in barcode and QR code technology, we trace items from their entry into our service to their administration to patients, ensuring efficiency and safety.</p>
            <p>Our platform is fully customisable, designed to meet the needs of each user. We continuously explore the latest technological advancements to enhance our system's effectiveness and ensure seamless tracking up to the point of patient care.</p>
          </div>
          <img src={PTwoodart} alt="PT Wood Art" className="pt-woodart" />
        </div>
      </section>

      <section className="features-section">
        <h2>Features</h2>
        <div className="section-content">
          <div>
            <p>Fast, effective, and reliable tracking with barcode and QR code technology. Reduction of confirmation bias and a system that is fully customisable for all users. Our service integrates with platforms like Omnicell and ePCR and is compatible across PC, iOS, and Android devices.</p>
          </div>
          <img src={PTart} alt="PT Art" className="pt-art" />
        </div>
      </section>

      <section className="contact-section">
        <h2>Contact Us</h2>
        <div className="section-content">
          <div>
            <p>To learn more about Precise PouchTrack or to get in touch with our team, please visit our <a href="/contact">Contact Page</a>.</p>
          </div>
          <img src={PTech} alt="PT Tech" className="pt-tech" />
        </div>
      </section>

      <footer className="landing-page-footer">
        <div className="footer-content">
          <p>For more information, visit our <a href="/contact">Contact Page</a>.</p>
          <img src={PTechWhite} alt="PT Tech White" className="pt-tech-white" />
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
