import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';

const MedicineBagExpiry = ({ token }) => {
  const [data, setData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showSection, setShowSection] = useState(false);
  const user = useSelector((state) => state.user);
  const medicineBagBarcode = user.Medicine_bagBarcode;
  const medicineBag = user.Medicine_bag;

  // Function to format date as YYYY MMM DD
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }).format(date);
  };

  useEffect(() => {
    const fetchMedicineDetails = async () => {
      try {
        const response = await axios.get(`api/medicine_bag_medicine_detail_data/${medicineBagBarcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          // Format Expiry_Date for each item for display
          const formattedData = response.data.map(item => ({
            ...item,
            Expiry_Date: formatDate(item.Expiry_Date), // Use formatted date for display
          }));

          setData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching medicine details:', error);
      }
    };

    if (medicineBagBarcode) {
      fetchMedicineDetails();
    }
  }, [token, medicineBagBarcode]);

  const displayColumns = ['Medicine_Name', 'Batch_Number', 'Expiry_Date', 'Pouch_Number']; // Keep using Expiry_Date for display
  const columnTitles = {
    'Medicine_Name': 'Medicine Name',
    'Batch_Number': 'Batch Number',
    'Expiry_Date': 'Expiry Date', // Display formatted expiry date
    'Pouch_Number': 'Pouch Number',
  };

  return (
    <div>
      <h2>Current Medicines in Medicine Bag: {medicineBag}</h2>
      <Table 
        data={data}
        columns={displayColumns}
        columnTitles={columnTitles}
        onRowSelect={setSelectedRowData}
      />
      <button className='button' onClick={() => setShowSection(!showSection)}>
        {showSection ? 'Hide Section' : 'Show Section'}
      </button>
   </div>
  );
};

export default MedicineBagExpiry;
