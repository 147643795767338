import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import MedicinePouchTypeForm from "./MedicinePouchTypeForm";

const MedicinePouchTypeTable = ({token}) => {
  let [medicineTypes, setMedicineTypes] = useState([]);
  const [selectedMedicineType, setSelectedMedicineType] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);



  useEffect(() => {
    getMedicineTypes();
  }, []);

  let getMedicineTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      let response = await fetch('/api/PouchType/', requestOptions);
      let data = await response.json();
      setMedicineTypes(data);
    } catch (error) {
      console.error('Error fetching medicine types:', error);
    }
  };

  const handleRowSelect = (selectedRow) => {
    setSelectedMedicine(selectedRow['Medicine Name']);
    setSelectedMedicineType(selectedRow);
    setSelectedMedicineId(selectedRow.Unique_id);
  };
  


  const initialColumns = ['Pouch Type Name', 'Medicine Name', 'Max Amount', 'Min Amount', 'Red Tag Amount'];
  const allColumns = ['Unique_id', 'Pouch Type Name', 'Medicine Name', 'Max Amount', 'Min Amount', 'Red Tag Amount'];

  const tableData = medicineTypes.map(type => ({
    'Pouch Type Name': type.pouch_type_name || '',
    'Medicine Name': type.medicine_name || '',
    'Max Amount': type.max_amount || '',
    'Min Amount': type.min_amount || '',
    'Red Tag Amount': type.red_tag_amount || '',
    'Unique_id': type.unique_id,
  }));

 

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
      <div style={{ flex: 1 }}>
      <div className="form-container">
        <MedicinePouchTypeForm selectedMedicineType={selectedMedicineType} setMedicineTypes={setMedicineTypes} token={token} />
      </div>      
      </div>  
      <div className="medicine-container">
      <div className="Medicine-list" style={{ flex: 1 }}>
        <EditableTable 
            selectedMedicine={selectedMedicine} 
            selectedMedicineId={selectedMedicineId} 
            setSelectedMedicineId={setSelectedMedicineId} 
            token={token} 
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns} // Add this
            allColumns={allColumns} // Add this
            data={tableData}
          />

          </div>
      </div>

    </div>
  );
};

export default MedicinePouchTypeTable;
