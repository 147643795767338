import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import '../../static/CSS/StockCheck.css'

const fetchMedicineDataStores = async (token) => {
    const response = await axios.get('/api/pouch_medicine_data_stores/', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

const StockCheck = ({ token }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [displayData, setDisplayData] = useState([]);
    const [enteredCount, setEnteredCount] = useState({});
    const { data, isLoading, error } = useQuery('medicineDataStores', () => fetchMedicineDataStores(token));

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleRandom = () => {
        const shuffledData = [...data].sort(() => 0.5 - Math.random());
        setDisplayData(shuffledData.slice(0, 5));
    }

    if (isLoading) return 'Loading...';
    if (error) return 'An error has occurred: ' + error.message;

    const filteredData = data.filter(item => 
        item.Medicine_Name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        item.Batch_Number.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCountChange = (id, count) => {
        setEnteredCount({
            ...enteredCount,
            [id]: count
        });
    }
    
    const handleSubmitCount = (id, totalAmount) => {
        if (enteredCount[id] !== totalAmount) {
            alert(`Count mismatch! Off by: ${totalAmount - enteredCount[id]}`);
        }
    }

return (
    <div className="stock-check-container">
        <div className="stock-check-search-container">
            <h2 className="stock-check-search-label">Search:</h2>
            <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
            <button className='stock-check-button stock-check-random-button' onClick={handleRandom}>Random</button>
        </div>
        <div className="stock-check-card-container">
        {(displayData.length > 0 ? displayData : filteredData).map(item => {
            const uniqueId = `${item.Medicine_Name}-${item.Batch_Number}`;
            return (
                <div key={uniqueId} className="stock-check-card">
                    <h2 className="stock-check-card-header">{item.Medicine_Name}</h2>
                    <div className="stock-check-card-body">
                        <div className="stock-check-card-item">
                            <span className="stock-check-card-label">Batch Number:</span>
                            <span className="stock-check-card-value stock-check-bordered">{item.Batch_Number}</span>
                        </div>
                        <div className="stock-check-card-item">
                            <span className="stock-check-card-label">Total Amount:</span>
                            <span className="stock-check-card-value stock-check-bordered">{item.Total_Amount}</span>
                        </div>
                        <div className="stock-check-input-container">
                        <input type="number" placeholder="Enter count" value={enteredCount[uniqueId] || ''} onChange={(e) => handleCountChange(uniqueId, parseInt(e.target.value))} />
                        <button className='stock-check-button' onClick={() => handleSubmitCount(uniqueId, item.Total_Amount)}>Submit</button>
                        </div>
                    </div>
                </div>
            )
        })}
        </div>
    </div>
);

    
}

export default StockCheck;
