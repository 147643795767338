import React, { useState, useEffect } from 'react';
import PouchForm from "./PouchForm";

const PouchTable = ({token}) => {
  let [pouches, setPouches] = useState([]);
  const [selectedPouch, setSelectedPouch] = useState(null);

  useEffect(() => {
    getPouches();
  }, []);

  let getPouches = async () => {
    try {

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      let response = await fetch('/api/Pouch/', requestOptions);
      let data = await response.json();
      setPouches(data);
    } catch (error) {
      console.error('Error fetching pouches:', error);
    }
  };

  const handleRowClick = (pouch) => {
    setSelectedPouch(pouch);
  };

  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        <div className="table-list" style={{ flex: 1 }}>
        <div className="form-container">
        <div style={{ flex: 1 }}>
        <PouchForm selectedPouch={selectedPouch}  token={ token } />
      </div>
      </div>
          <table>
            <thead>
              <tr>
                <th>Pouch Number</th>
                <th>Pouch Type</th>
                <th>Pouch Barcode</th>
              </tr>
            </thead>
            <tbody>
            {Array.isArray(pouches) && pouches.map((pouch, index) => (
                <tr key={index} onClick={() => handleRowClick(pouch)}>
                  <td>{pouch.pouch_number}</td>
                  <td>{pouch.pouch_type}</td>
                  <td>{pouch.pouch_barcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
};

export default PouchTable;
