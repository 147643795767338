import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const FileUploader = ({ token }) => {
  const [selectedModel, setSelectedModel] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(''); // State to store file name

  const databases = [
    'ConsumableBatch',
    'MedicineBag',
    'Carrier',
    'Medicine',
    'MedicineBatch',
    'pouch'
  ];

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name); // Update the file name
  };

  const generateBarcode = async () => {
    try {
      const response = await axios.get('api/generate_unique_barcode/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return response.data.barcode;
    } catch (error) {
      console.error('Error generating barcode:', error);
      throw error;
    }
  };

  const processFile = async (fileData) => {
    // Logic to add barcode where needed and remove single quotes
    for (const row of fileData) {
      for (const key in row) {
        if (key.toLowerCase().includes('barcode') && !row[key]) {
          row[key] = await generateBarcode();
        }
        // Remove single quotes from the start and end of string values
        if (typeof row[key] === 'string' && row[key].startsWith("'") && row[key].endsWith("'")) {
          row[key] = row[key].slice(1, -1); // Remove the first and last character
        }
      }
    }
    return fileData;
  };
  const handleUpload = async () => {
    if (!selectedModel || !file) {
      alert('Please select a model and a file to upload.');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const processedData = await processFile(jsonData);
  
      try {
        const response = await axios.post(`api/file_upload/${selectedModel}/`, processedData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (response.status === 200) {
          alert('Upload successful: ' + response.data.message);
        } else {
          alert('Upload failed: Server responded with status ' + response.status);
        }
      } catch (error) {
        if (error.response) {
          alert('Upload failed: ' + error.response.data.error || error.response.statusText);
        } else if (error.request) {
          alert('Upload failed: No response received from server');
        } else {
          alert('Upload failed: ' + error.message);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };
  

  return (
    <div className="medicine-upload-card">
      <div className="medicine-upload-header">
        <h3>Upload File</h3>
      </div>
      <div className="medicine-upload-body">
        <div className="medicine-upload-form">
          <div className="medicine-upload-item">
            <label className="medicine-upload-label">Select a Model</label>
            <select className="medicine-upload-value" value={selectedModel} onChange={e => setSelectedModel(e.target.value)}>
              <option value="">Select a Model</option>
              {databases.map(db => (
                <option key={db} value={db}>{db}</option>
              ))}
            </select>
          </div>
          <div className="medicine-upload-item">
            <label className="medicine-upload-button" htmlFor="file-input">
              Choose File
            </label>
            <input id="file-input" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
            {fileName && <div className="file-name">{fileName}</div>} {/* Display the file name */}
          </div>
          <button className="medicine-upload-button" onClick={handleUpload}>Upload</button>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
