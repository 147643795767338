import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const PouchMedicineDataStores = ({ token }) => {
  const [data, setData] = useState([]);

  const fetchPouchMedicineDataStores = async () => {
    const response = await axios.get('api/pouch_medicine_data_stores/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Name': item.Name || '',
      'Station Name': item.Station_Name || '',
      'Medicine Name': item.Medicine_Name || '',
      'Batch Number': item.Batch_Number || '',
      'Expiry Date': item.Expiry_Date || '',
      'Total Amount': item.Total_Amount || '',
      'Price': item.Price || '',
      'Total Cost': item.Total_Cost || '',
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchPouchMedicineDataStores();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pouch Medicine Data Stores');
    XLSX.writeFile(wb, 'PouchMedicineDataStores.xlsx');
  };

  const initialColumns = ['Name', 'Station Name', 'Medicine Name', 'Batch Number', 'Expiry Date', 'Total Amount', 'Price', 'Total Cost'];
  const allColumns = ['Name', 'Station Name', 'Medicine Name', 'Batch Number', 'Expiry Date', 'Total Amount', 'Price', 'Total Cost'];

  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default PouchMedicineDataStores;
