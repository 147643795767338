import React from 'react';
import EditableTable from '../Table/Table';

const SelectableOrdersTable = ({ orders, onRowSelect }) => {
  const initialColumns = ['User', 'Station', 'Ordering to', 'Item Ordered', 'Amount', 'Date of Transaction', 'Status'];
  
  const tableData = orders.map(order => ({
    'User': order.user_name,
    'Station': order.station,
    'Ordering to': order.ordering_to,
    'Item Ordered': order.Item_Name,
    'Amount': order.amount,
    'Date of Transaction': new Date(order.datetime_order).toLocaleDateString(),
    'Status': order.order_status,
  }));
  
  const handleRowClick = (order) => {
    onRowSelect(order);
  };
  

  return (
    <div className="order-history-section">
      <h2>Order History</h2>
      <EditableTable
        initialColumns={initialColumns}
        allColumns={initialColumns}
        data={tableData}
        onRowSelect={onRowSelect}
      />
    </div>
  );
};

export default SelectableOrdersTable;
