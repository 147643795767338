import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';
import MedicineBagPack from '../Medicine_Bag/MedicineBagPack'; 
import NSMedicineBagPack from '../Medicine_Bag/NSMedicine_BagPack';
import DrugAdministrationForm from '../DrugAdministration/DrugAdministrationForm';

const MedicineBadDrugDetailedTable = ({ token }) => {
  const [data, setData] = useState([]);
  const [showNSMedicineBagPack, setShowNSMedicineBagPack] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const user = useSelector((state) => state.user);
  const medicineBagBarcode = user.Medicine_bagBarcode;
  const medicineBag = user.Medicine_bag;
  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    const fetchMedicineDetails = async () => {
      try {
        const response = await axios.get(`api/medicine_bag_medicine_detail_data/${medicineBagBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error('Error fetching medicine details:', error);
      }
    };

    if (medicineBagBarcode) {
      fetchMedicineDetails();
    }
  }, [token, medicineBagBarcode]);

  const displayColumns = ['Medicine_Name', 'Batch_Number', 'Expiry_Date', 'Pouch_Number'];
  const columnTitles = {
    'Medicine_Name': 'Medicine Name',
    'Batch_Number': 'Batch Number',
    'Expiry_Date': 'Expiry Date',
    'Pouch_Number': 'Pouch Number',
  };

  return (
    <div>
      <h2>Current Medicines in Medicine Bag: {medicineBag}</h2>
      <div style={{ textAlign: 'center' }}>
      <DrugAdministrationForm token={token} selectedRowData={selectedRowData} />
      </div>
      <Table 
        data={data} 
        columns={displayColumns} 
        columnTitles={columnTitles} 
        onRowSelect={setSelectedRowData}  
      />
 
      <button className='button' onClick={() => setShowSection(!showSection)}>
        {showSection ? 'Hide Section' : 'Show Section'}
      </button>

      {showSection && (
        <>
          <button className='button' onClick={() => setShowNSMedicineBagPack(!showNSMedicineBagPack)}>
            {showNSMedicineBagPack ? 'Switch to MedicineBagPack' : 'Switch to NSMedicineBagPack'}
          </button>
          {showNSMedicineBagPack ? <NSMedicineBagPack token={token}/> : <MedicineBagPack token={token}/>}
        </>
      )}
    </div>
  );
};

export default MedicineBadDrugDetailedTable;
