import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import StationForm from './StationForm';

const StationTable = () => {
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStationId, setSelectedStationId] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    getStations();
  }, []);

  const getStations = async () => {
    try {
      const response = await fetch('/api/Station/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error fetching stations: ' + response.statusText);
      }
  
      const data = await response.json();
  
      if (Array.isArray(data)) {
        setStations(data);
      } else {
        console.error('Error: stations data is not an array');
        setStations([]);
      }
    } catch (error) {
      console.error('Error fetching stations:', error);
      setStations([]);
    }
  };

  const initialColumns = ['Station Name', 'Station Type', 'Station Barcode'];
  const allColumns = ['Station Name', 'Station Type', 'Station Barcode'];

  const tableData = stations.map(station => ({
    'Station Name': station.station_name,
    'Station Type': station.station_type,
    'Station Barcode': station.station_barcode,
    'Unique_id': station.unique_id,
  }));

  const handleRowSelect = (selectedRow) => {
    setSelectedStation(selectedRow);
    setSelectedStationId(selectedRow.Unique_id);
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        <div className="table-list" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flex: 1, alignSelf: 'center' }}>
            <StationForm selectedStation={selectedStation} token={token} />
          </div>
          <EditableTable
            token={token}
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns}
            allColumns={allColumns}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
};

export default StationTable;
