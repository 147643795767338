import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom'; 
import StyledDropdown from '../Styling components/StyledDropdown';
import NSMedicineRemoveForm from './NSMedicineRemoveForm';

const NSMedicineRemove = ({ token, onUpdate }) => {
  const [medicineBarcode, setMedicineBarcode] = useState('');
  const [medicineName, setMedicineName] = useState('');
  const [amount, setAmount] = useState('');
  const [pouchAmount, setPouchAmount] = useState(null);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  const [selectedUniqueId, setSelectedUniqueId] = useState(null);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const navigate = useNavigate();
  const [medicines, setMedicines] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState("");
  const [uniqueIds, setUniqueIds] = useState([]); // add this line
  const [selectedBatchUniqueIds, setSelectedBatchUniqueIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const amountRef = useRef(amount);  // Create a ref for amount
  const pouchAmountRef = useRef(pouchAmount); 
  const [selectedMedicineBarcode, setSelectedMedicineBarcode] = useState(''); // New state for selected medicine barcode
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  useEffect(() => {
    amountRef.current = amount;  // Update the ref whenever amount changes
  }, [amount]);

  useEffect(() => {
  }, [selectedBatchUniqueIds]);

  useEffect(() => {
    pouchAmountRef.current = pouchAmount;  // Update the ref whenever pouchAmount changes
  }, [pouchAmount]);


  const handleSelection = (uniqueId) => {
    setSelectedUniqueId(uniqueId);
  };

 
  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const response = await axios.get('/api/MedicineBatch/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setMedicines(response.data);
        }
      } catch (error) {
        console.error('Error fetching medicines:', error);
      }
    };
  
    fetchMedicines();
  }, [token]);
  
  const handleSelectChange = async (e) => {
    setIsLoading(true);
  
    // Use .toLowerCase() for case-insensitive comparison and trim whitespace
    const selectedMedicineName = e.target.value.toLowerCase().trim();
  
    try {
      const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        // Adjust the filtering condition to handle new lines and whitespace
        const medicineItems = response.data.filter(item =>
          item['Medicine_Name'].toLowerCase().replace(/\s+/g, ' ').trim() === selectedMedicineName
        );
  

        // Grouping items by batch number
        const groupedData = medicineItems.reduce((acc, item) => {
          const key = item.Batch_Number;
          if (!acc[key]) {
            acc[key] = {
              ...item,
              Amount: 0,
              Unique_IDs: [],
            };
          }
          acc[key].Amount += 1;
          acc[key].Unique_IDs = [...acc[key].Unique_IDs, item.unique_id];
          return acc;
        }, {});
  
  
        // Converting grouped data object to an array
        const filteredData = Object.values(groupedData);
  
        setFilteredTableData(filteredData);
      }
    } catch (error) {
      console.error('Error in handleSelectChange:', error);
    }
  
    setIsLoading(false);
  };
  
  
  

  
  useEffect(() => {
    const fetchPouchAmountAndContents = async () => {
      try {
        const pouchAmountResponse = await axios.get(`/api/pouch_medicine_data/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        // Filter the records for the current medicine
        const medicineItemsAmount = pouchAmountResponse.data.filter(item => item['Medicine_Name'] === medicineName);
    
        // Calculate the total amount of the current medicine in the pouch
        const totalAmount = medicineItemsAmount.reduce((sum, item) => sum + item.total_amount, 0);
  
        setPouchAmount(totalAmount);
    
        // Fetch the unique IDs of the medicine items in the pouch
        const pouchContentsResponse = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
    
        // Filter the records for the current medicine
        const medicineItems = pouchContentsResponse.data.filter(item => item['Medicine_Name'] === medicineName);
    
    
        // Extract the unique IDs
        const ids = medicineItems.map(item => item.unique_id);
    
    
        setUniqueIds(ids);
    
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };
  
    if (pouchBarcode && medicineName && medicineName !== '') {
      fetchPouchAmountAndContents();
    } else if (medicineName !== '') {
      setPouchAmount(0);
      setUniqueIds([]);
    }
  }, [pouchBarcode, medicineName, token]);
  
  const fetchPouchAmountAndContents = async () => {
    try {
        const pouchAmountResponse = await axios.get(`/api/pouch_medicine_data/${pouchBarcode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const medicineItemsAmount = pouchAmountResponse.data.filter(item => item['Medicine_Name'] === medicineName);
        const totalAmount = medicineItemsAmount.reduce((sum, item) => sum + item.total_amount, 0);

        setPouchAmount(totalAmount);

        const pouchContentsResponse = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const medicineItems = pouchContentsResponse.data.filter(item => item['Medicine_Name'] === medicineName);
        const ids = medicineItems.map(item => item.unique_id);

        setUniqueIds(ids);
    } catch (error) {
        console.error('Error fetching pouch contents:', error);
    }
};


  const filteredMedicines = medicines.filter(medicine => 
    medicine.medicine_name && medicine.medicine_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  


      useEffect(() => {
        const fetchFilteredData = async () => {
          try {
            const response = await axios.get(
              `/api/pouch_medicine_data/${pouchBarcode}/`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
      
            const filteredData = response.data.filter(
              (item) => item.Drug_Barcode === medicineBarcode
            );
      
            setFilteredTableData(filteredData);
          } catch (error) {
            console.error("Error fetching filtered data:", error);
          }
        };
      
        if (pouchBarcode && medicineBarcode) {
          fetchFilteredData();
        } else {
          setFilteredTableData([]);
        }
      }, [pouchBarcode, medicineBarcode, token]);
      
      const handleButtonClick = (batchNumber, event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const selectedBatch = filteredTableData.find(item => item.Batch_Number === batchNumber);
        if (selectedBatch) {
            setSelectedMedicineBarcode(selectedBatch.Drug_Barcode);
            setSelectedBatchUniqueIds(selectedBatch.Unique_IDs);
    
            // Assuming the first unique ID in the batch is what you want to pass
            setSelectedUniqueId(selectedBatch.Unique_IDs[0]); // Update this line
    
            setShowForm(true); // Show the form when a batch is selected
            setFilteredTableData([]); // Clear the table content
            setMedicineName(selectedBatch.Medicine_Name);
            setMedicineBarcode(selectedBatch.Drug_Barcode);
            fetchPouchAmountForBatch(selectedBatch.Medicine_Name, selectedBatch.Unique_IDs);
        }
    };
    

    const fetchPouchAmountForBatch = async (medicineName, uniqueIds) => {
      try {
          const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
              headers: { Authorization: `Bearer ${token}` },
          });
  
          let totalAmount = 0;
          response.data.forEach(item => {
              if (uniqueIds.includes(item.unique_id) && item.Medicine_Name === medicineName) {
                  totalAmount += item.Amount;
              }
          });
  
          setPouchAmount(totalAmount);
      } catch (error) {
          console.error('Error fetching pouch contents for batch:', error);
      }
  };
  
  


      
      const medicineOptions = filteredMedicines.map(medicine => ({
        label: medicine.medicine_name
      }));
    
    // Ensure this useEffect hook is properly set up to fetch data when medicineName changes
    useEffect(() => {
      if (pouchBarcode && medicineName && medicineName !== '') {
          fetchPouchAmountAndContents();
      } else if (medicineName !== '') {
          setPouchAmount(0);
          setUniqueIds([]);
      }
  }, [pouchBarcode, medicineName, token]);

    // Function to hide the form
    const hideForm = () => {
      setShowForm(false);
  };

      useEffect(() => {
        const amountInPouch = filteredTableData.reduce((total, item) => total + item.Amount, 0);
        setPouchAmount(amountInPouch);
      }, [filteredTableData]);
    
      return (
        <div className="medicine-return-container">
          <div className="form-container">
            <div className="medicine-return-card">
              <div className="medicine-return-card-header">
                <h2>Medicine to Remove</h2>
              </div>
              <div className="medicine-return-card-body">
                <form className="medicine-return-form">
                  {/* Dropdown for selecting medicine */}
                  <StyledDropdown
                    options={medicineOptions}
                    value={selectedValue}
                    onChange={handleSelectChange}
                    placeholder="Select Medicine"
                  />
                </form> {/* Close form tag here */}
              </div>
            </div>
          </div>
      
          {/* Table outside of form */}
          <div className="medicine-return-table-container">
            <div className="medicine-return-table-wrapper">
              <table className="medicine-return-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Medicine Name</th>
                    <th>Amount</th>
                    <th>Batch</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTableData.map((item) => (
                    <tr
                      key={item.Unique_ID}
                      onClick={() => handleSelection(item.Unique_ID)}
                      className={item.Unique_ID === selectedUniqueId ? "medicine-return-selected-row" : ""}
                    >
                      <td>
                      <button className="button" onClick={(e) => handleButtonClick(item.Batch_Number, e)}>
                          Select
                      </button>

                      </td>
                      <td>{item.Medicine_Name}</td>
                      <td>{item.Amount}</td>
                      <td>{item.Batch_Number}</td>
                      <td>{new Date(item.Expiry_Date).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {showForm && (
            <NSMedicineRemoveForm 
                selectedMedicineBarcode={selectedMedicineBarcode}
                selectedBatchUniqueIds={selectedBatchUniqueIds}
                token={token}
                onUpdate={() => {
                  onUpdate();
                  hideForm(); 
              }}            
            />
        )}
      </div>
    );
 }
export default NSMedicineRemove;