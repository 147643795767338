import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm';
import useSubmitStockHistory from './useSubmitStockHistory';
import ItemSelectionForm from '../ItemSelection/ItemSelectionForm'; // Make sure the path is correct
import '../../static/CSS/StorageUserDisplay.css';

const StorageStockAddForm = ({ token }) => {
  const { submitStockHistory } = useSubmitStockHistory(token);
  const [storageUnits, setStorageUnits] = useState([]);
  const [bins, setBins] = useState([]);
  const [selectedStorageUnit, setSelectedStorageUnit] = useState('');
  const [filteredBins, setFilteredBins] = useState([]);
  const [productBarcode, setProductBarcode] = useState(''); // Define productBarcode as state
  const user = useSelector((state) => state.user);
  const { name, userBarcode, station } = user || {}; // Fallback to an empty object if user is null/undefined
  const [refreshItemSelection, setRefreshItemSelection] = useState(false);
  const [selectedBinBarcode, setSelectedBinBarcode] = useState('');
  const [searchBinBarcode, setSearchBinBarcode] = useState('');
  const [showManualSelection, setShowManualSelection] = useState(false);
  const [editAction, setEditAction] = useState(false);

  useEffect(() => {
    fetchStorageUnits();
    fetchBins();
  }, []);

  const fetchStorageUnits = async () => {
    try {
      const response = await axios.get('/api/storage-unit/', {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStorageUnits(response.data);
    } catch (error) {
      console.error("Error fetching storage units:", error);
    }
  };

  const fetchBins = async () => {
    try {
      const response = await axios.get('/api/bin/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBins(response.data);
    } catch (error) {
      console.error("Error fetching bins:", error);
    }
  };

  useEffect(() => {
    const newFilteredBins = selectedStorageUnit ? bins.filter(bin => {
      // Adjusted comparison logic
      return String(bin.storage_unit) === String(selectedStorageUnit);
    }) : [];
    setFilteredBins(newFilteredBins);
  }, [selectedStorageUnit, bins]);



  const [data, setData] = useState({
    product_barcode: '',
    bin_barcode: '',
    action: 'add',
    quantity_changed: 0,
    user: userBarcode,
    comments: '',
  });



  const selectedBin = bins.find(bin => bin.bin_barcode === selectedBinBarcode);

  const selectedStorageUnitName = storageUnits.find(unit => unit.id === selectedBin?.storage_unit)?.unit_name;

  const [displayBinInfo, setDisplayBinInfo] = useState({ binName: '', storageUnitName: '' });

  useEffect(() => {
    const bin = bins.find(bin => bin.bin_barcode === selectedBinBarcode);
    if (bin) {
      const storageUnitName = storageUnits.find(unit => unit.id === bin.storage_unit)?.unit_name;
      setDisplayBinInfo({ binName: bin.bin_name, storageUnitName });
    }
  }, [selectedBinBarcode, bins, storageUnits]);


  // Function to handle item selection from ItemSelectionForm
  const handleItemSelect = (selected) => {
    if (selected && selected.product_barcode) {
      console.log("Selected product barcode:", selected.product_barcode);
  
      // Update the `data` state directly with the new `product_barcode`
      setData(prevData => ({
        ...prevData,
        product_barcode: selected.product_barcode, // Directly adding the selected product barcode to the data state
      }));
    }
  };

  const handleBinSelectionChange = (e) => {
    const selectedBarcode = e.target.value;
    setSelectedBinBarcode(selectedBarcode);
  
    // Immediately update the form data state with the selected bin's barcode
    setData(prevData => ({
      ...prevData,
      bin_barcode: selectedBarcode,
    }));
  
  };


  const handleBinSearch = (barcode) => {
    const foundBin = bins.find(bin => bin.bin_barcode === barcode);
    if (foundBin) {
      setSelectedBinBarcode(foundBin.bin_barcode);
  
      // Update the display information
      setDisplayBinInfo({
        binName: foundBin.bin_name,
        storageUnitName: storageUnits.find(unit => unit.id === foundBin.storage_unit)?.unit_name,
      });
  
      // Directly updating the form data state with the found bin's barcode
      setData(prevData => ({
        ...prevData,
        bin_barcode: foundBin.bin_barcode,
      }));
    } else {
      // Reset display info if no bin is found or input is cleared
      setDisplayBinInfo({ binName: '', storageUnitName: '' });
      // Also, consider clearing the bin_barcode from the data if the search is cleared or no bin is found
      setData(prevData => ({
        ...prevData,
        bin_barcode: '',
      }));
    }
  };


  const handleSuccess = (message) => {
    console.log(message);
    // Reset form or additional success logic here
    setData({
      product_barcode: '',
      bin_item: '',
      action: 'add',
      quantity_changed: 0,
      user: '',
      comments: '',
    });
  };

  const handleError = (message) => {
    console.error(message);
    // Additional error handling logic here
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting with data:", data); 

    submitStockHistory({ ...data, user: userBarcode }, handleSuccess, handleError);
  };

  const resetForm = () => {
    setSelectedStorageUnit('');
    setSelectedBinBarcode(''); 
    setSearchBinBarcode(''); 
    setData({
      product_barcode: '', 
      bin_barcode: '',
      action: 'add',
      quantity_changed: 0,
      user: userBarcode, 
      comments: '',
    });
    setRefreshItemSelection(prev => !prev); 
  };

  // Function to toggle manual selection
  const toggleManualSelection = () => {
    setShowManualSelection(prev => !prev);
  };

  // Function to toggle edit action
  const toggleEditAction = () => {
    setEditAction(prev => !prev);
  };


  return (
    <div className="Form-card">
      <div className="Form-card-body">
        <form onSubmit={handleSubmit} className="Form-form-container">
          <h3 className="Form-Title">Add Stock History</h3>
  
          {/* Conditional rendering based on showManualSelection state */}
          {!showManualSelection ? (
            <>
              {/* Search Bin by Barcode */}
              <div className="Form-extra-display">
                <label>Search Bin by Barcode:</label>
                <input
                    type="text"
                    placeholder="Enter bin barcode"
                    value={searchBinBarcode}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchBinBarcode(value);
                      if (value) {
                        handleBinSearch(value);
                      } else {
                        setDisplayBinInfo({ binName: '', storageUnitName: '' }); // Reset display if input is cleared
                      }
                    }}
                    onBlur={() => handleBinSearch(searchBinBarcode)}
                  />
                {/* Removed the "Search" button since the functionality is now automatic */}
                <button onClick={toggleManualSelection}>Select Manually</button>
                {displayBinInfo.binName && (
                  <div>
                    <p>Bin Name: {displayBinInfo.binName}</p>
                    <p>Storage Unit: {displayBinInfo.storageUnitName}</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {/* Manual Selection */}
              <button onClick={toggleManualSelection}>Add by Barcode</button>
  
              {/* Station Select Dropdown */}
              <div className="Form-form-row">
                <label>Station:</label>
                <select
                  value={selectedStorageUnit || ''}
                  onChange={(e) => setSelectedStorageUnit(e.target.value)}
                  required
                >
                  <option value="">Set Unit</option>
                  {storageUnits.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unit_name}
                    </option>
                  ))}
                </select>
              </div>
  
              {/* Bin Select Dropdown */}
              <div className="Form-form-row">
                <label>Bin:</label>
                  <select
                    value={selectedBinBarcode || ''}
                    onChange={handleBinSelectionChange} // Use the new handler
                    required
                  >
                    <option value="">Select Bin</option>
                    {filteredBins.map((bin) => (
                      <option key={bin.bin_barcode} value={bin.bin_barcode}>
                        {bin.bin_name}
                      </option>
                    ))}
                  </select>
              </div>
            </>
          )}

          {/* Item Selection Form */}
          <div className="Form-extra-display">
          <ItemSelectionForm 
            token={token} 
            onItemSelect={handleItemSelect} 
            refresh={refreshItemSelection} 
          />
          </div>

          {/* Quantity Input */}
          <div className="Form-form-row">
            <label>Quantity:</label>
            <input
              type="number"
              value={data.quantity_changed || 0}
              onChange={(e) => setData({ ...data, quantity_changed: e.target.value })}
              required
            />
          </div>

          {/* Remaining Form Inputs */}
          {/* Action Display/Edit */}
          <div className="Form-form-row">
            {!editAction ? (
              <>
                <label>Action:</label>
                <span>{data.action} <a href="#!" onClick={toggleEditAction}>Edit</a></span>
              </>
            ) : (
              <select
                value={data.action || ''}
                onChange={(e) => setData({ ...data, action: e.target.value })}
                required
              >
                <option value="add">Add</option>
                <option value="remove">Remove</option>
              </select>
            )}
          </div>
          <div className="user-display">
            <label>User:</label>
            <span>{name}</span>
          </div>

          {/* Comments Textarea */}
          <div className="Form-form-row">
            <label>Comments:</label>
            <textarea
              value={data.comments || ''}
              onChange={(e) => setData({ ...data, comments: e.target.value })}
              rows="5"
            />
          </div>  
  
 
          {/* Form Buttons */}
          <div className="Form-form-row-button-container">
            <button className="button" type="submit">Add</button>
            <button className="button" type="button" onClick={resetForm}>Refresh</button>
            {/* Include other buttons as needed */}
          </div>

        </form>
      </div>
    </div>
  );
};

export default StorageStockAddForm;
