import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setPouchData } from '../../Redux/slices/userSlice';
import StyledDropdown from '../Styling components/StyledDropdown';
import Textinput from '../Styling components/Textinput';
import "../../static/CSS/Pouchformandcard.css"

const CombinedPouchNumberInput = () => {
  const [inputBarcode, setInputBarcode] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPouch, setSelectedPouch] = useState(null);
  const [pouches, setPouches] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPouch = async (pouchBarcode) => {
      if (!pouchBarcode) return;

      try {
        const response = await axios.get(`api/Pouch/${pouchBarcode}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          setSelectedPouch(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouch:', error);
      }
    };

    if (!isManualEntry) {
      fetchPouch(inputBarcode);
    }
  }, [inputBarcode, isManualEntry]);

  useEffect(() => {
    const fetchPouches = async () => {
      try {
        const response = await axios.get('api/Pouch/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          setPouches(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouches:', error);
      }
    };

    if (isManualEntry) {
      fetchPouches();
    }
  }, [isManualEntry]);

  const handleInputChange = (e) => {
    setInputBarcode(e.target.value);
  };

  const handleSelectChange = (e) => {
    const pouchNumber = e.target.value;
    const selected = pouches.find(pouch => pouch.pouch_number === pouchNumber);
    setSelectedPouch(selected);
  };

  const handleAddPouchNumber = () => {
    if (selectedPouch) {
      dispatch(setPouchData({
        pouchNumber: selectedPouch.pouch_number,
        pouchType: selectedPouch.pouch_type,
        pouchBarcode: selectedPouch.pouch_barcode
      }));
      window.alert(`Pouch ${selectedPouch.pouch_number} selected`);
    }
  };

  const filteredPouches = pouches.filter(pouch =>
    pouch.pouch_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleManualEntry = () => {
    setIsManualEntry(!isManualEntry);
  };


  const user = useSelector((state) => state.user);
  const { pouchNumber, station } = user;

  return (
    <div className="cpni-container">
      <div className="cpni-medicine-card">
        <h2 className="medicine-upload-header">Pouch Selection</h2>
        <div className="medicine-upload-body">
          {!isManualEntry ? (
            <React.Fragment>
              <Textinput
                questions={[
                  {
                    type: 'text',
                    text: 'Pouch Barcode',
                    value: inputBarcode,
                    handleChange: handleInputChange,
                  }
                ]}
              />
              <div className="medicine-upload-item">
                <span className="medicine-upload-label">Pouch Number: </span>
                <span className="medicine-upload-value">
                  {selectedPouch ? selectedPouch.pouch_number : "N/A"}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Textinput
                questions={[
                  {
                    type: 'text',
                    text: 'Search pouch number...',
                    value: searchTerm,
                    handleChange: (e) => setSearchTerm(e.target.value),
                  }
                ]}
              />
              <StyledDropdown
                options={filteredPouches.map(pouch => ({ value: pouch.pouch_number, label: pouch.pouch_number }))}
                value={selectedPouch ? selectedPouch.pouch_number : ''}
                onChange={handleSelectChange}
                placeholder="Select pouch"
              />
              <div className="medicine-upload-item">
                <span className="medicine-upload-label">Pouch Number: </span>
                <span className="medicine-upload-value">
                  {selectedPouch ? selectedPouch.pouch_number : "N/A"}
                </span>
              </div>
            </React.Fragment>
          )}
          <div className="medicine-upload-item">
            <button className="medicine-upload-button" type="button" onClick={handleAddPouchNumber}>
              Add Pouch Number
            </button>
            <button className="medicine-upload-button" type="button" onClick={toggleManualEntry}>
              {isManualEntry ? 'Automatic' : 'Manual'}
            </button>
            </div>
          </div>
        </div>
        <div className="cpni-detail-box">
        <div className="cpni-detail">
          <span className="cpni-label">Current Pouch Number:</span>
          <span className="cpni-value">
            {selectedPouch ? selectedPouch.pouch_number : pouchNumber || "N/A"}
          </span>
        </div>
        <div className="cpni-detail">
          <span className="cpni-label">Station Name:</span>
          <span className="cpni-value">{station || "N/A"}</span>
        </div>
      </div>
    </div>

  );
};

export default CombinedPouchNumberInput;
