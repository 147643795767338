import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';


const StationForm = ({ token, selectedStation }) => {
  const [stationName, setStationName] = useState('');
  const [stationType, setStationType] = useState('');
  const [stationBarcode, setStationBarcode] = useState('');
  const [selectedStationId, setSelectedStationId] = useState(null);

  useEffect(() => {
    if (selectedStation) {
      setStationName(selectedStation['Station Name']);
      setStationType(selectedStation['Station Type']);
      setStationBarcode(selectedStation['Station Barcode']);
      setSelectedStationId(selectedStation['Station Barcode']);
    }
  }, [selectedStation]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/Station/create/', {
        station_name: stationName,
        station_type: stationType,
        station_barcode: stationBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Station created:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error creating station:', error);
    }
  };
  
  

  const handleRefresh = () => {
    setStationName('');
    setStationType('');
    setStationBarcode('');
    setSelectedStationId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${stationName}?`)) {
      handleDelete();
    }
  };

  

  const handleGenerateBarcode = async () => {
    const prefix = 'S'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setStationBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/Station/update/${selectedStationId}/`, {
        station_name: stationName,
        station_type: stationType,
        station_barcode: stationBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Station updated:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error updating station:', error);
    }
  };
  
  

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/Station/delete/${selectedStationId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Station deleted');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting station:', error);
    }
  };
  
  

  const questions = [
    {
      text: 'Station Name',
      value: stationName,
      handleChange: (e) => setStationName(e.target.value),
      type: 'text',
    },
    {
      text: 'Station Type',
      value: stationType,
      handleChange: (e) => setStationType(e.target.value),
      type: 'select',
      styled: true,
      options: [
        { value: "", label: "Select a station type" },
        { value: "Station", label: "Station" },
        { value: "Warehouse", label: "Warehouse" }
      ],
    },
    {
      text: 'Station Barcode',
      value: stationBarcode,
      handleChange: (e) => setStationBarcode(e.target.value),
      type: 'text',
      isLocked: true 
    },

      ...(!stationBarcode ? [{
        text: 'Generate Barcode',
        type: 'generateBarcode'
      }] : [])
  ];
  return (
    <div className="form-container">
      <DynamicForm 
      title="Add New Station"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      handleGenerateBarcode={handleGenerateBarcode}
      selectedId={selectedStationId}
      setDrugBarcode={setStationBarcode}
      />
    </div>
  );
}

export default StationForm;
