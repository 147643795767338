import React, { useState } from "react";
import '../../static/CSS/Header.css';

const Menu = ({ isOpen, toggle }) => (
  <div className={`sidebar-bottom ${isOpen ? 'open' : ''}`}>
    <ul>
      <li><a href="/LandingPageProduction">Home</a></li>
      <li><a href="/contact">Contact</a></li>
      <li><a href="/login">Login</a></li>
    </ul>
  </div>
);

const LandingPageHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <h1 className="header-title">Precise PouchTrack</h1>
      </header>
      {menuOpen && <Menu isOpen={menuOpen} toggle={toggleMenu} />}
    </>
  );
};

export default LandingPageHeader;
