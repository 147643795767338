import axios from 'axios';

const useSubmitStockHistory = (token) => {
  // Function to submit stock history
  const submitStockHistory = async (data, onSuccess, onError) => {
    const url = `/api/add-station-stock-history/`;
    console.log('Attempting to submit stock history', data); // Log the data being submitted

    try {
      console.log('Making POST request to:', url); // Log the URL being requested
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Response status:', response.status); // Log the response status
      if (response.status === 201) {
        console.log('Success response:', response.data); // Log the success response data
        onSuccess(`Stock history added successfully.`);
      } else {
        onError(`Error adding stock history: status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error adding stock history:`, error);
      console.log(`Error details:`, error.response?.data); // Log the error details
      onError(`Error adding stock history: ${error.response?.data}`);
    }
  };

  return { submitStockHistory };
};

export default useSubmitStockHistory;
