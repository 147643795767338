// StyledDropdown.js
import React from 'react';
import '../../static/CSS/StyledDropdown.css';

const StyledDropdown = ({ options, value, onChange, placeholder }) => {
  return (
    <div className="dropdown-container">
      <label className={`dropdown-label ${value ? 'active' : ''}`}>
        {placeholder}
      </label>
      <select
        className="styled-dropdown"
        value={value}
        onChange={onChange}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StyledDropdown;
