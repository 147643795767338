import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../static/CSS/Pouchdetails.css';

const PouchDetailsCard = ({ token, pouchBarcode }) => {
    const [pouchStatus, setPouchStatus] = useState('Awaiting Input...');
    const [pouchNumber, setPouchNumber] = useState('Awaiting Input...');
    const [storedIn, setStoredIn] = useState('');
    const [location, setLocation] = useState('');

    useEffect(() => {
        if (pouchBarcode) {
            fetchPouchStatus();
            fetchPouchNumber();
            checkPouchLocation();
            fetchItemLocation();
        }
    }, [pouchBarcode, token]);

    const fetchPouchStatus = async () => {
        try {
            const response = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${pouchBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data[0] && response.data[0].Pouch_Status) {
                setPouchStatus(response.data[0].Pouch_Status);
            } else {
                setPouchStatus('Status Not Available');
            }
        } catch (error) {
            console.error('Error fetching pouch status:', error);
            setPouchStatus('Error fetching status');
        }
    };

    const fetchPouchNumber = async () => {
        try {
            const response = await axios.get(`/api/Pouch/${pouchBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data && response.data.pouch_number) {
                setPouchNumber(response.data.pouch_number);
            } else {
                setPouchNumber('Number Not Available');
            }
        } catch (error) {
            console.error('Error fetching pouch number:', error);
            setPouchNumber('Error fetching number');
        }
    };

    
    const checkPouchLocation = async () => {
        if (!pouchBarcode) {
            console.log("No pouchBarcode provided, skipping location check.");
            setStoredIn ('No barcode provided');
            return;
        }
    
        try {
            // Initial check in carrier
            let response = await axios.get(`/api/packed_products_in_carrier/?Pouch_Barcode=${pouchBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            if (response.data.length > 0) {
                const matchingCarrierEntries = response.data.filter(entry => entry.Pouch_Barcode === pouchBarcode);
                if (matchingCarrierEntries.length > 0) {
                    const carrierBarcode = matchingCarrierEntries[0].Carrier_Barcode;
        
                    const carrierResponse = await axios.get(`/api/Carrierbarcode/?Carrier_Barcode=${carrierBarcode}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
        
                    if (carrierResponse.data.length > 0) {
                        const carrierDetails = carrierResponse.data.find(carrier => carrier.carrier_barcode === carrierBarcode);
                        if (carrierDetails) {
                            setStoredIn(`In Carrier: ${carrierDetails.carrier_number}`);
                            return;
                        }
                    }
                }
            }
    
            // Check if in a medicine bag
            response = await axios.get(`/api/packed_products_in_medicine_bag/?Pouch_Barcode=${pouchBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
    
            if (response.data.length > 0) {
                const medicineBagBarcode = response.data.find(entry => entry.Pouch_Barcode === pouchBarcode)?.MedicineBag_Barcode;
    
                if (medicineBagBarcode) {
                    const medicineBagResponse = await axios.get(`/api/MedicineBags/?medicine_bag_barcode=${medicineBagBarcode}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
    
    
                    const medicineBagDetails = medicineBagResponse.data.find(bag => bag.medicine_bag_barcode === medicineBagBarcode);
                    if (medicineBagDetails) {
                        setStoredIn(`Medicine Bag: ${medicineBagDetails.medicine_bag_number}`);
                        return;
                    }
                }
            }
    
            setStoredIn('Not in carriers or bags');
        } catch (error) {
            console.error('Error checking pouch location:', error);
            setStoredIn('Error checking location');
        }
    };
    
    
    const fetchItemLocation = async () => {
        try {
            const response = await axios.get(`/api/item-location/${pouchBarcode}/`, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Check if the response data array is not empty and has a Station_Name
            if (response.data.length > 0 && response.data[0].Station_Name) {
                const locationData = response.data[0]; // Access the first element of the array
                setLocation(`${locationData.Station_Name} on ${new Date(locationData.Datetime).toLocaleString()}`);
            } else {
                setLocation('Location details not available');
            }
        } catch (error) {
            console.error('Error fetching item location:', error); // Log any errors
            setLocation('Error fetching location details');
        }
    };
    
    
    
    
    return (
        <div className="pouch-details-card">
            <h3 className="pouch-details-title">Pouch Details</h3>
            <div className="pouch-detail">
                <span className="pouch-detail-key">Pouch Barcode:</span>
                <span className="pouch-detail-value">{pouchBarcode}</span>
            </div>
            <div className="pouch-detail">
                <span className="pouch-detail-key">Pouch Number:</span>
                <span className="pouch-detail-value">{pouchNumber}</span>
            </div>
            <div className="pouch-detail">
                <span className="pouch-detail-key">Status:</span>
                <span className="pouch-detail-value">{pouchStatus}</span>
            </div>
            {storedIn && (
                <div className="pouch-detail">
                    <span className="pouch-detail-key">Stored in:</span>
                    <span className="pouch-detail-value">{storedIn}</span>
                </div>
            )}
            {location && (
                <div className="pouch-detail">
                    <span className="pouch-detail-key">Location:</span>
                    <span className="pouch-detail-value">{location}</span>
                </div>
            )}
        </div>
    );
};

export default PouchDetailsCard;
