import React, { useState } from 'react';
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm';

const EngineerCompletionForm = ({ token, reportId, onReload }) => {
  const [reason, setReason] = useState('');
  const [outcome, setOutcome] = useState('');
  const [completedDatetime, setCompletedDatetime] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`/api/ErrorReport/update/${reportId}/`, {
        reason,
        outcome,
        completed_datetime: completedDatetime,
        status: 'Completed'  // Optionally set the status to 'Completed'
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Report completed.');
    } catch (error) {
      console.error('Error completing report:', error);
    }

    if (onReload) {
      onReload();
    }
  };

  const handleRefresh = () => {
    setReason('');
    setOutcome('');
    setCompletedDatetime('');
  };

  const questions = [
    {
      type: 'textarea',
      text: 'Reason for Error:',
      value: reason,
      handleChange: (e) => setReason(e.target.value),
      rows: 5
    },
    {
      type: 'textarea',
      text: 'Outcome:',
      value: outcome,
      handleChange: (e) => setOutcome(e.target.value),
      rows: 5
    },
    {
      type: 'datetime-local',
      text: 'Completed Datetime:',
      value: completedDatetime,
      handleChange: (e) => setCompletedDatetime(e.target.value),
    }
  ];

  return (
    <DynamicForm
      title="Engineer Completion"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      showDeleteButton={false}
    />
  );
};

export default EngineerCompletionForm;
