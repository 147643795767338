import Header from '../components/header'
import ConsumableBatchTable from '../components/EditTables/ConsumableBatchTable';

const token = localStorage.getItem("token");

function EditConsumableBatch() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <ConsumableBatchTable token={token} />     
        </div>
    </div>
  );
}

export default EditConsumableBatch;