import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useProductLocationSubmit from '../Location/ProductLocationSubmit';

const CombinedMedicineBagUnpack = ({ token, onRefresh  }) => {
    const user = useSelector((state) => state.user);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPouchNumbers, setFilteredPouchNumbers] = useState([]);
    const [dropdownPouchNumber, setDropdownPouchNumber] = useState('');
    const [directPouchBarcode, setDirectPouchBarcode] = useState('');
    const [pouchNumbers, setPouchNumbers] = useState([]);
    const [pouchNumber, setPouchNumber] = useState('');
    const submitProductLocation = useProductLocationSubmit(token);
    const [pouchBarcodes, setPouchBarcodes] = useState('');

    // Fetch pouch numbers for the dropdown
    useEffect(() => {
        const fetchPouchNumbers = async () => {
            try {
                const response = await axios.get('/api/packed_pouches_in_medicine_bag/', {
                    params: { Medicine_Bag_Barcode: user.medicineBagBarcode },
                    headers: { Authorization: `Bearer ${token}` },
                });
                // Update state with pouch numbers
                setPouchNumbers(response.data.map(pouch => pouch.Pouch_Number));
                // Also update state with pouch barcodes
                setPouchBarcodes(response.data.map(pouch => pouch.Pouch_Barcode));
                // Fetch pouch number if directPouchBarcode is set
                if (directPouchBarcode) {
                    const pouchData = response.data.find(pouch => pouch.Pouch_Barcode === directPouchBarcode);
                    setPouchNumber(pouchData ? pouchData.Pouch_Number : 'Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch numbers:', error);
            }
        };
        fetchPouchNumbers();
    }, [token, user.medicineBagBarcode, directPouchBarcode]);

// Unpack a single pouch and update location for both the pouch and the medicine bag
const unpackSinglePouch = async (pouchBarcode) => {
    if (!pouchBarcode) {
        alert('Please provide a valid pouch barcode.');
        return;
    }

    try {
        const response = await axios.put('/api/Medicine_BagHistory/updateSingle/', {
            Pouch_Barcode: pouchBarcode,
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            MedicineBag_Status: "Unpacked",
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            // Update location for the unpacked pouch
            submitProductLocation(
                pouchBarcode, 
                user.station_barcode,
                () => console.log(`Location updated for pouch ${pouchBarcode}`),
                (errorMsg) => console.error(`Error updating location for pouch ${pouchBarcode}: ${errorMsg}`)
            );

            alert(`Pouch unpacked successfully.`);
            onRefresh();
        } else {
            alert('Error unpacking the pouch.');
        }
    } catch (error) {
        console.error('Error unpacking the pouch:', error);
    }
};

// Unpack all pouches from the medicine bag and update locations
const unpackAll = async () => {
    const confirmUnpack = window.confirm(`Are you sure you want to unpack all contents of ${user.medicineBag} to station: ${user.station_barcode}?`);
    if (!confirmUnpack) return;

    try {
        const response = await axios.put('/api/Medicine_BagHistory/updateAll/', {
            MedicineBag_Barcode: user.Medicine_bagBarcode,
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            MedicineBag_Status: "Unpacked",
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            // Update location for each pouch
            pouchBarcodes.forEach((pouchBarcode) => {
                submitProductLocation(
                    pouchBarcode,
                    user.station_barcode,
                    () => console.log(`Location updated for pouch ${pouchBarcode}`),
                    (errorMsg) => console.error(`Error updating location for pouch ${pouchBarcode}: ${errorMsg}`)
                );
            });

            // Additionally, update location for the medicine bag itself
            const medicineBagBarcode = localStorage.getItem('Medicine_bagBarcode'); // Or however you're obtaining this
            if (medicineBagBarcode) {
                submitProductLocation(
                    medicineBagBarcode,
                    user.station_barcode,
                    () => console.log(`Location updated for medicine bag ${medicineBagBarcode}`),
                    (errorMsg) => console.error(`Error updating location for medicine bag ${medicineBagBarcode}: ${errorMsg}`)
                );
            }

            alert(`All pouches and the medicine bag location updated successfully!`);
            onRefresh(); // Assuming onRefresh properly updates the component/page
        } else {
            alert('Error unpacking all pouches.');
        }
    } catch (error) {
        console.error('Error unpacking all pouches:', error);
    }
};


    const handleSubmit = async (e) => {
        e.preventDefault();
        const pouchBarcode = directPouchBarcode || await getPouchBarcodeFromNumber(dropdownPouchNumber);
        unpackSinglePouch(pouchBarcode);
    };

    const getPouchBarcodeFromNumber = async (pouchNumber) => {
        try {
            const response = await axios.get('/api/packed_pouches_in_medicine_bag/', {
                params: { Medicine_Bag_Barcode: user.medicineBagBarcode },
                headers: { Authorization: `Bearer ${token}` },
            });
            const pouch = response.data.find(p => p.Pouch_Number === pouchNumber);
            return pouch ? pouch.Pouch_Barcode : null;
        } catch (error) {
            console.error('Error fetching pouch barcode:', error);
            return null;
        }
    };

    useEffect(() => {
        setFilteredPouchNumbers(
            pouchNumbers.filter(number => number.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [searchTerm, pouchNumbers]);

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Medicine Bag Unpacking</h2>
                <div className="packing-main-content">
                    <form onSubmit={handleSubmit}>
                        <div className="input-group">
                            <label>Direct Pouch Barcode:</label>
                            <input
                                type="text"
                                value={directPouchBarcode}
                                onChange={(e) => setDirectPouchBarcode(e.target.value)}
                                placeholder="Direct Pouch Barcode"
                            />
                            {directPouchBarcode && <p>Pouch Number: {pouchNumber}</p>} {/* Display pouch number when direct barcode is used */}

                        </div>
                        <div className="input-group">
                            <label htmlFor="pouchNumberSearch">Pouch Number:</label>
                            <input
                                id="pouchNumberSearch"
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search Pouch Number"
                            />
                            <select
                                value={dropdownPouchNumber}
                                onChange={(e) => setDropdownPouchNumber(e.target.value)}
                            >
                                <option value="">Select Pouch</option>
                                {filteredPouchNumbers.map((number, index) => (
                                    <option key={index} value={number}>{number}</option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="card-button">Unpack Pouch</button>
                    </form>
                    <button onClick={unpackAll} className="card-button">Unpack All from Medicine Bag {user.medicineBag}</button>
                </div>
            </div>
        </div>
    );
};

export default CombinedMedicineBagUnpack;
