// MedicineMovepage.js
import React from 'react';
import Header from '../../components/header';
import SpecificLocationForm from '../../components/Location/SpecificLocationForm';
const token = localStorage.getItem("token");

const MedicineMovepage = () => {
    return (
        <div className='main-content'>
            <Header token={token}/>
            <div className="content-wrapper">
                <h2>Submit Medicine Location</h2>
                <SpecificLocationForm token={token} type="carriers"/>

            </div>
        </div>
    );
};

export default MedicineMovepage;