import React, { useState } from 'react';
import Header from '../components/header';
import PouchContentDisplay from '../components/Packing/PouchContentDisplay';
import MedicineRemove from '../components/Unpacking/MedicineRemove'
import NSMedicineRemove from '../components/Unpacking/NSMedicineRemove'


const Unpack = () => {
  const token = localStorage.getItem('token');
  const [reloadFlag, setReloadFlag] = useState(false);
  const [showBarcodeInput, setShowBarcodeInput] = useState(true);

  const handleReload = () => {
    setReloadFlag(!reloadFlag);
  };

  return (
    <div className="packing-app">
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

      <div className="main-content">
        <button className="button" onClick={() => setShowBarcodeInput(!showBarcodeInput)}>
          {showBarcodeInput ? 'Switch to Manual' : 'Switch to Barcode'}
        </button>
        {showBarcodeInput ? (
        <MedicineRemove token={token} onUpdate={handleReload}/>
        ) : (
          <NSMedicineRemove token={token} onUpdate={handleReload}/>
        )}
        <PouchContentDisplay token={token} reloadFlag={reloadFlag}/> 
        <div className="PackButtonCheck">
        </div>
      </div>
      </div>
    </div>
  );
};

export default Unpack;
