// src/components/ForgotUsername.js
import React, { useState } from 'react';
import axios from 'axios';

const ForgotUsername = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/forgot_username/', {
        email,
      });

      if (response.status === 200) {
        setMessage('An email has been sent to you with your username and password reset link.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setMessage('Failed to send email. Please try again.');
    }
  };

  return (
    <div>
      <h1>Forgot Username</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {message && <div>{message}</div>}
    </div>
  );
};

export default ForgotUsername;
