import React, { useState, useEffect } from 'react';
import VehicleTypeForm from "./VehicleTypeForm"; // Updated import

const VehicleTypeTable = ({token}) => {
  const [vehicleTypes, setVehicleTypes] = useState([]); // Updated variable name
  const [selectedVehicleType, setSelectedVehicleType] = useState(null); // Updated variable name

  useEffect(() => {
    getVehicleTypes(); // Updated function name
  }, []);

  const getVehicleTypes = async () => { // Updated function name
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      let response = await fetch('/api/VehicleType/', requestOptions); // Updated API endpoint
      let data = await response.json();
      setVehicleTypes(data); // Updated state function
    } catch (error) {
      console.error('Error fetching vehicle types:', error); // Updated error message
    }
  };

  const handleRowClick = (vehicleType) => { // Updated parameter name
    setSelectedVehicleType(vehicleType); // Updated state function
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        <div className="table-list" style={{ flex: 1 }}>
          <div className="form-container">
            <div style={{ flex: 1 }}>
              <VehicleTypeForm selectedVehicleType={selectedVehicleType} token={token} /> {/* Updated component and prop */}
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Vehicle Type Name</th> {/* Updated header */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(vehicleTypes) && vehicleTypes.map((vehicleType, index) => ( // Updated condition and variable
                <tr key={index} onClick={() => handleRowClick(vehicleType)}> {/* Updated onClick handler */}
                  <td>{vehicleType.vehicle_type_name}</td> {/* Updated field */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VehicleTypeTable; // Updated export
