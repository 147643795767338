// src/components/Station Medicine_bag Inputs/Medicine_bagInput.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setMedicine_bag, setMedicine_bagBarcode } from '../../Redux/slices/userSlice';
import StyledDropdown from "../Styling components/StyledDropdown";

const Medicine_bagInput = ({ token }) => {
    const [medicine_bags, setMedicine_bags] = useState([]);
    const [selectedMedicine_bag, setSelectedMedicine_bag] = useState('');
    const [medicine_bagBarcode, setMedicine_bagBarcode] = useState('');
    const dispatch = useDispatch();
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchMedicine_bags = async () => {
            try {
                const response = await axios.get('/api/MedicineBags/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setMedicine_bags(response.data);
                }
            } catch (error) {
                console.error('Error fetching medicine_bags:', error);
            }
        };

        fetchMedicine_bags();
    }, [token]);

    const handleMedicine_bagChange = (e) => {
        setSelectedMedicine_bag(e.target.value);
    };

    const handleMedicine_bagBarcodeChange = (e) => {
        const enteredBarcode = e.target.value;
        setMedicine_bagBarcode(enteredBarcode);

        const filteredMedicine_bags = medicine_bags.filter(medicine_bag => {
            return medicine_bag && medicine_bag.medicine_bag_barcode && medicine_bag.medicine_bag_barcode.includes(enteredBarcode);
        });

        setMedicine_bags(filteredMedicine_bags);

        if (filteredMedicine_bags.length > 0 && filteredMedicine_bags[0].medicine_bag_number) {
            setSelectedMedicine_bag(filteredMedicine_bags[0].medicine_bag_number);
        }
    };

    const dropdownOptions = medicine_bags.map(medicine_bag => {
        const medicine_bagNumber = medicine_bag && medicine_bag.medicine_bag_number ? medicine_bag.medicine_bag_number : "Unknown";
        return {
            value: medicine_bagNumber,
            label: medicine_bagNumber,
        };
    });

    const handleMedicine_bagSubmit = (e) => {
        e.preventDefault();
        const selectedMedicine_bagObject = medicine_bags.find(medicine_bag => medicine_bag.medicine_bag_number === selectedMedicine_bag);
        if (selectedMedicine_bagObject) {
            dispatch({ type: 'user/setMedicine_bag', payload: selectedMedicine_bagObject.medicine_bag_number });
            dispatch({ type: 'user/setMedicine_bagBarcode', payload: selectedMedicine_bagObject.medicine_bag_barcode });

            setSuccessMessage(`Medicine_bag ${selectedMedicine_bagObject.medicine_bag_number} saved!`);

            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Medicine bag Selection</h2>
                <div className="packing-main-content">
                    <form onSubmit={handleMedicine_bagSubmit}>
                        <div className="input-group">
                            <label>Medicine bag Barcode</label>
                            <input 
                                type="text" 
                                value={medicine_bagBarcode} 
                                onChange={handleMedicine_bagBarcodeChange}
                                placeholder="Enter Medicine bag Barcode" 
                            />
                        </div>
                        <StyledDropdown
                            options={dropdownOptions}
                            value={selectedMedicine_bag}
                            onChange={handleMedicine_bagChange}
                            placeholder="Select Medicine bag"
                        />
                        {successMessage && <p className="success-message">{successMessage}</p>}
                        <button type="submit" className="card-button">Set Medicine bag</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Medicine_bagInput;
