import React, { useState, useEffect } from 'react';
import UserForm from "./UserForm";
import EditableTable from "../Table/Table";

const UserTable = ({ token }) => {
  let [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  
  
  useEffect(() => {
    getUsers();
  }, []);

  let getUsers = async () => {
    try {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
  
      let response = await fetch('/api/User/', requestOptions);
      let data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const initialColumns = ['User Name', 'Position', 'Station', 'Email', 'Username', 'User Barcode'];
  const allColumns = ['Unique_id', 'User Name', 'Position', 'Station', 'Email', 'Username', 'User Barcode'];

  const tableData = users.map(user => ({
    'User Name': user.name || '',
    'Position': user.position,
    'Station': user.station,
    'Email': user.email,
    'Username': user.username,
    'User Barcode': user.user_barcode,
    'Unique_id': user.unique_id,
  }));


  const handleRowSelect = (selectedRow) => {
    setSelectedUser(selectedRow);
    setSelectedUserId(selectedRow.Unique_id);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

      
      <div className="form-container" style={{ flex: 1 }}>
        <UserForm selectedUser={selectedUser} token={token} />
      </div>        
      <div className="medicine-container">
      <div className="Medicine-list" style={{ flex: 1 }}>
        <EditableTable 
            selectedMedicine={setSelectedUser} 
            selectedMedicineId={selectedUserId} 
            setSelectedMedicineId={setSelectedUser} 
            token={token} 
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns} // Add this
            allColumns={allColumns} // Add this
            data={tableData}
          />   
      </div> 
      </div>

    </div>
  );
};

export default UserTable;
