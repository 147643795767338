import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const PackedPouchesInMedicineBag = ({ token }) => {
  const [data, setData] = useState([]);

  const fetchDataForPackedPouches = async () => {
    const response = await axios.get('api/packed_pouches_in_medicine_bag/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Unique ID': item.Unique_ID || '',
      'Pouch Barcode': item.Pouch_Barcode || '',
      'Pouch Number': item.Pouch_Number || '',
      'Medicine Bag Status': item.MedicineBag_Status || '',
      'Medicine Bag Barcode': item.MedicineBag_Barcode || '',
      'Medicine Bag Number': item.medicine_bag_number || ''
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchDataForPackedPouches();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PackedPouchesInMedicineBag');
    XLSX.writeFile(wb, 'PackedPouchesInMedicineBag.xlsx');
  };

  const initialColumns = [
    'Unique ID', 'Pouch Barcode', 'Pouch Number', 'Medicine Bag Status', 'Medicine Bag Barcode', 'Medicine Bag Number'
  ];
  const allColumns = initialColumns; // If you have additional columns, add them here.
  
  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default PackedPouchesInMedicineBag;
