import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';


const MedicineForm = ({ selectedMedicine, selectedMedicineId, setSelectedMedicineId, token }) => {
  const [medicineName, setMedicineName] = useState('');
  const [dose, setDose] = useState('');
  const [maxLevel, setMaxLevel] = useState('');
  const [reorderLevel, setReorderLevel] = useState('');
  const [packByVial, setPackByVial] = useState('');


  useEffect(() => {
    if (selectedMedicine) {
      setMedicineName(selectedMedicine['Medicine Name']);
      setDose(selectedMedicine.Dose);
      setMaxLevel(selectedMedicine['Max Level']);
      setReorderLevel(selectedMedicine['Reorder Level']);
      setPackByVial(selectedMedicine['Pack by Vial']);
    }
  }, [selectedMedicine]);
  

  const questions = [
    {
      text: 'Medicine Name:',
      type: 'text',
      value: medicineName,
      handleChange: (e) => setMedicineName(e.target.value),
    },
    {
      text: 'Dose:',
      type: 'text',
      value: dose,
      handleChange: (e) => setDose(e.target.value),
    },
    {
      text: 'Max Level:',
      type: 'text',
      value: maxLevel,
      handleChange: (e) => setMaxLevel(e.target.value),
    },
    {
      text: 'Reorder Level:',
      type: 'text',
      value: reorderLevel,
      handleChange: (e) => setReorderLevel(e.target.value),
    },
    {
      text: 'Pack by Vial:',
      type: 'text',
      value: packByVial,
      handleChange: (e) => setPackByVial(e.target.value),
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let response;
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
  
      if (selectedMedicineId) {
        // Update the medicine data
        response = await axios.put(`/api/Medicine/update/${selectedMedicineId}/`, {
          medicine_name: medicineName,
          dose: dose,
          max_level: maxLevel,
          reorder_level: reorderLevel,
          pack_by_vial: packByVial,
        }, config);
      } else {
        // Add a new medicine
        response = await axios.post('/api/Medicine/create/', {
          medicine_name: medicineName,
          dose: dose,
          max_level: maxLevel,
          reorder_level: reorderLevel,
          pack_by_vial: packByVial,
        }, config);
      }
  
      console.log('Medicine operation successful:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error in medicine operation:', error);
    }
  };
  
  
  

  const handleRefresh = () => {
    setMedicineName('');
    setDose('');
    setMaxLevel('');
    setReorderLevel('');
    setPackByVial('');
    setSelectedMedicineId(null);
  };
  

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${medicineName}?`)) {
      handleDelete();
    }
  };


  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/Medicine/update/${selectedMedicineId}/`, {
        medicine_name: medicineName,
        dose: dose,
        max_level: maxLevel,
        reorder_level: reorderLevel,
        pack_by_vial: packByVial,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Medicine updated:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error updating medicine:', error);
    }
  };
  
  
  const handleDelete = async () => {
    try {
      await axios.delete(`/api/Medicine/delete/${selectedMedicineId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Medicine deleted');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting medicine:', error);
    }
  };
  
  



  return (
    <DynamicForm
      title="Add New or Edit Medicine"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedMedicineId}
      showSearchBar={true}
    />
  );
};
export default MedicineForm;