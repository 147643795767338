import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import EditableTable from '../Table/Table'; 
import StyledDropdown from '../Styling components/StyledDropdown'
import Textinput from '../Styling components/Textinput'


const Orders = ({ token }) => {
  const [medicines, setMedicines] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(''); // Add this state to track selected pouch status
  const [orderAmount, setOrderAmount] = useState(0);
  const [orderStatus, setOrderStatus] = useState('');
  const [stockStatus, setStockStatus] = useState('');
  const [pouchTypes, setPouchTypes] = useState([]);
  const [selectedPouchType, setSelectedPouchType] = useState('');
  const [orderType, setOrderType] = useState('medicine'); // Add this state to track the order type
  const [deliveryAddress, setDeliveryAddress] = useState(''); // Add this state to track the delivery address
  const userName = useSelector((state) => state.user.name);
  const stationName = useSelector((state) => state.user.station);
  const [selectedOrderId, setSelectedOrderId] = useState(null);


  useEffect(() => {
    // Fetch available medicines
    const fetchMedicines = async () => {
      try {
        const response = await axios.get('/api/Medicine/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMedicines(response.data);
      } catch (error) {
        console.error('Error fetching medicines:', error);
      }
    };

    // Fetch existing orders
    const fetchOrders = async () => {
      try {
        const response = await axios.get('/api/orders/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchMedicines();
    fetchOrders();
  }, [token]);

  useEffect(() => {
    if (!stationName) {
      alert('No station selected. Please select a station.');
    }
  }, [stationName]);



  useEffect(() => {
    // Fetch stock holding data when a medicine or pouch type is selected
    const fetchStockHolding = async () => {
      try {
        let response;
        if (orderType === 'medicine') {
          response = await axios.get(`/api/stores_levels_name/${selectedMedicine}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data.length > 0 && response.data[0].Total_Amount > 0) {
            setStockStatus(`In stock: ${response.data[0].Total_Amount}`);
          } else {
            setStockStatus('Not currently in stock.');
          }
        } else if (orderType === 'pouch') {
          response = await axios.get(`/api/pouch_type_summary/${selectedPouchType}/${selectedStatus}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const totalItems = response.data.length; // Count the number of rows in the response data
          if (totalItems > 0) {
            setStockStatus(`In stock: ${totalItems}`); // Update the stock status with the count
          } else {
            setStockStatus('Not currently in stock.');
          }
        }
      } catch (error) {
        console.error('Error fetching stock holding:', error);
      }
    };
    
    if (selectedMedicine || selectedPouchType) {
      fetchStockHolding();
    }
    
  }, [selectedMedicine, selectedPouchType, orderType, token]);
  
  const handleRefresh = () => {
    setOrderAmount(0);
    setDeliveryAddress('');

  };
  
  useEffect(() => {
    // Fetch available pouch types
    const fetchPouchTypes = async () => {
      try {
        const response = await axios.get('/api/Pouch/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const pouchTypes = [...new Set(response.data.map(pouch => pouch.pouch_type))];
        setPouchTypes(pouchTypes);
      } catch (error) {
        console.error('Error fetching pouch types:', error); // Log the error
        if (error.response) {
          console.error('Server response error:', error.response); // Log the server response if available
        }
      }
    };
  
    fetchPouchTypes();
  }, [token]);
  
  const handleOrderTypeChange = () => {
    // Toggle between medicine and pouch
    setOrderType(orderType === 'medicine' ? 'pouch' : 'medicine');
  };


  const handleOrder = async () => {
   // Check if requested amount is less than total amount in store
   const selectedMedicineData = medicines.find(medicine => medicine.Medicine_Name === selectedMedicine);
  // Check if fields are filled in
  if (!selectedMedicine || !orderAmount || !deliveryAddress) {
    alert('Please fill in all fields.');
    return;
  }
  if (selectedMedicineData && orderAmount > selectedMedicineData.Total_Amount) {
    alert('Medicine not available currently. There will be a delay in your order.');
    setOrderStatus('Medicine not available currently. There will be a delay in your order.');
  } else {
      // Create a new order
      try {
        const response = await axios.post('/api/orders/create/', {
          user_name: userName, 
          station: stationName, 
          ordering_to: deliveryAddress, 
          Item_Name: orderType === 'medicine' ? selectedMedicine : selectedPouchType,
          amount: orderAmount,
          datetime_order: new Date(),
          order_status: 'Pending',
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders([...orders, response.data]);
        setOrderStatus('Order placed successfully.');
      } catch (error) {
        console.error('Error placing order:', error);
      }
    }
  };

  const initialColumns = ['User', 'Station', 'Ordering to', 'Item Ordered', 'Amount', 'Date of Transaction', 'Status'];
  const allColumns = ['User', 'Station', 'Ordering to', 'Item Ordered', 'Amount', 'Date of Transaction', 'Status'];
  
  // Convert orders to a format suitable for the EditableTable component
  const tableData = orders.map(order => ({
    'User': order.user_name,
    'Station': order.station,
    'Ordering to': order.ordering_to,
    'Item Ordered': order.Item_Name,
    'Amount': order.amount,
    'Date of Transaction': new Date(order.datetime_order).toLocaleDateString(),
    'Status': order.order_status,
    'unique_id': order.unique_id, 
  }));

  const handleRowSelect = (selectedRow) => {
    setSelectedMedicine(selectedRow);
    setSelectedOrderId(selectedRow.Unique_id);  // <-- The issue might be here. Javascript is case-sensitive.
  };


  return (
    <div className="orders-container">
      <div className="orders-packing-input-container card">
        <div className="form-container">
          <h2 className="orders-heading">Orders</h2>
          <button onClick={handleOrderTypeChange} className="button">
            {orderType === 'medicine' ? 'Switch to Pouch' : 'Switch to Medicine'}
          </button>

          {/* Warning message displayed if stationName is empty */}
          {stationName ? null : (
            <p className="warning-message">No station selected. Please select a station.</p>
          )}

          {orderType === 'medicine' ? (
            <StyledDropdown
              options={medicines.map(medicine => ({ label: medicine.medicine_name, value: medicine.medicine_name }))}
              value={selectedMedicine}
              onChange={e => setSelectedMedicine(e.target.value)}
              placeholder="Select Medicine"
            />
          ) : (
            <>
              <StyledDropdown
                options={['Packed', 'Partially QAed', 'QAed'].map(status => ({ label: status, value: status }))}
                value={selectedStatus}
                onChange={e => setSelectedStatus(e.target.value)}
                placeholder="Select Pouch Status"
              />
              <StyledDropdown
                options={pouchTypes.map(pouchType => ({ label: pouchType, value: pouchType }))}
                value={selectedPouchType}
                onChange={e => setSelectedPouchType(e.target.value)}
                placeholder="Select Pouch Type"
              />
            </>
          )}
          <p>{stockStatus}</p>

          <Textinput
            questions={[
              {
                type: "number",
                value: orderAmount,
                handleChange: e => setOrderAmount(e.target.value),
                text: "Order Amount"
              },
              {
                type: "text",
                value: deliveryAddress,
                handleChange: e => setDeliveryAddress(e.target.value),
                text: "Delivery Address"
              }
            ]}
          />
          <button onClick={handleOrder} className="button">Order</button>
          <button onClick={handleRefresh} className="button">Refresh</button>
          <p>{orderStatus}</p>
        </div>
      </div>
    </div>
  );
};

export default Orders;