import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const CarrierForm = ({ token, selectedCarrier }) => {
  const [carrierNumber, setCarrierNumber] = useState('');
  const [carrierType, setCarrierType] = useState('');
  const [carrierBarcode, setCarrierBarcode] = useState('');
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);

  useEffect(() => {
    if (selectedCarrier) {
      setCarrierNumber(selectedCarrier.carrier_number);
      setCarrierType(selectedCarrier.carrier_type);
      setCarrierBarcode(selectedCarrier.carrier_barcode);
      setSelectedCarrierId(selectedCarrier.carrier_barcode);
    }
  }, [selectedCarrier]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/Carrier/create/', {
        carrier_number: carrierNumber,
        carrier_type: carrierType,
        carrier_barcode: carrierBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      await axios.post('/api/CarrierStatus/create/', {
        carrier_barcode: carrierBarcode,
        status: "Unpacked",
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      window.location.reload();
    } catch (error) {
      console.error('Error creating carrier or CarrierStatus:', error);
    }
  };
  
  

  const handleRefresh = () => {
    setCarrierNumber('');
    setCarrierType('');
    setCarrierBarcode('');
    setSelectedCarrierId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete carrier with barcode ${carrierBarcode}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/Carrier/update/${selectedCarrierId}/`, {
        carrier_number: carrierNumber,
        carrier_type: carrierType,
        carrier_barcode: carrierBarcode,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      // Check if barcode changed and update CarrierStatus if necessary
      if (selectedCarrierId !== carrierBarcode) {
        await axios.put(`/api/CarrierStatus/update/${selectedCarrierId}/`, {
          carrier_barcode: carrierBarcode,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      }
    
      window.location.reload();
    } catch (error) {
      console.error('Error updating carrier or CarrierStatus:', error);
    }
  };
  
  

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/Carrier/delete/${selectedCarrierId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      await axios.delete(`/api/CarrierStatus/delete/${selectedCarrierId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      window.location.reload();
    } catch (error) {
      console.error('Error deleting carrier or CarrierStatus:', error);
    }
  };
  
  



  const handleGenerateBarcode = async () => {
    const prefix = 'C'; 
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`; 
        setCarrierBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  
  
  const questions = [
    {
        text: 'Carrier Number',
        value: carrierNumber,
        handleChange: (e) => setCarrierNumber(e.target.value),
        type: 'text',
    },
    {
        text: 'Carrier Type',
        value: carrierType,
        handleChange: (e) => setCarrierType(e.target.value),
        type: 'select',
        styled: true, 
        options: [
            { value: "Create", label: "Create" },
        ],
    },
    {
        text: 'Carrier Barcode',
        value: carrierBarcode,
        handleChange: (e) => setCarrierBarcode(e.target.value),
        type: 'text',
        isLocked: true 
      },
  
        ...(!carrierBarcode ? [{
          text: 'Generate Barcode',
          type: 'generateBarcode'
        }] : [])
];

return (
    <div className="form-container">
        <DynamicForm
            title="Add New Carrier"
            questions={questions}
            handleSubmit={handleSubmit}
            handleRefresh={handleRefresh}
            handleUpdate={handleUpdate}
            handleDeleteConfirmation={handleDeleteConfirmation}
            handleGenerateBarcode={handleGenerateBarcode} 
            setDrugBarcode={setCarrierBarcode}  
            selectedId={selectedCarrierId}

        />
      </div>
    );
};

export default CarrierForm;

