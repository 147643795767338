// SimpleModal.js
import React from 'react';
import './SimpleModal.css'; // Ensure you have this CSS file with the styles provided earlier

const SimpleModal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{title}</h2>
        {children}
        <div className="modal-actions">
        </div>
      </div>
    </div>
  );
};

export default SimpleModal;
