import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import LocationForm from '../components/Station Carrier Inputs/StationInput';
import MedicineBag_inputs from '../components/Station Medicine_Bag Inputs/Medicine_bagInput';
import { useSelector } from 'react-redux';
import headerImage from '../static/images/Scanner.png';
import { useNavigate } from 'react-router-dom';
import useProductLocationSubmit from '../components/Location/ProductLocationSubmit';


const MedicineBagLoad = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const submitProductLocation = useProductLocationSubmit(token); // Correct placement inside the component
  const { name, station, Medicine_bag, station_barcode, Medicine_bagBarcode } = useSelector((state) => state.user);
  const [showLocationForm, setShowLocationForm] = useState(false);

  const updateLocationAndNavigate = (navigateTo) => {
    // Assuming Medicine_bagBarcode and station_barcode are correctly fetched from the Redux state or local storage
    if (Medicine_bagBarcode) {
      submitProductLocation(
        Medicine_bagBarcode,
        station_barcode,
        () => {
          console.log(`Location updated for medicine bag ${Medicine_bagBarcode}`);
          navigate(navigateTo);
        },
        (errorMsg) => console.error(`Error updating location for medicine bag ${Medicine_bagBarcode}: ${errorMsg}`)
      );
    }
  };

  const handlePackClick = () => updateLocationAndNavigate("/MedicineBagPackPage");
  const handleMoveClick = () => updateLocationAndNavigate("/MedicineBagMovepage");
  const handleUnpackClick = () => updateLocationAndNavigate("/MedicineBagUnpackPage");

  useEffect(() => {
  }, [name]);

  return (
    <div className='main-content'>
      <Header />

      <div className="packing-header-container">
        <div className="packing-header-box">
          <div className="text-content">
            <h2 className='packing-title'>Medicine Bag Operations</h2>
            <p className="user-greeting">Hi {name}</p>
            <button className="button-select-station" onClick={() => setShowLocationForm(!showLocationForm)}>Select Station</button>

          </div>
          <img src={headerImage} alt="Header" className="header-image"/>
        </div>
      </div>

      <div className="detail-content-wrapper">
        <div className="detail-header-box">
          <div className="unpacking-detail">
            <span className="detail-label">Station Name:</span>
            <span className="detail-value">{station}</span>
          </div>
          <div className="unpacking-detail">
            <span className="detail-label">Medicine Bag:</span>
            <span className="detail-value">{Medicine_bag ? Medicine_bag : 'No medicine bag selected'}</span>
          </div>

          <button className='button-pack' onClick={handlePackClick}>Pack Medicine Bag</button>
          <button className='button-store' onClick={handleMoveClick}>Move Medicine Bag</button>
          <button className='button-unpack' onClick={handleUnpackClick}>Unpack Medicine Bag</button>
        </div>

        <div className="unpacking-actions">
          <MedicineBag_inputs token={token} />
        </div>
        {showLocationForm && (
          <div className="unpacking-actions">
            <LocationForm token={token} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicineBagLoad;
