import React, { useEffect } from 'react';
import Header from '../components/header'; 
import MedicineBagDetailTable from '../components/DrugAdministration/MedicineBadDrugDetailedTable';  
import { useSelector } from 'react-redux';

const DrugAdministration = () => {
  const token = localStorage.getItem("token");
  const { username, station, pouchNumber, Medicine_bag } = useSelector((state) => state.user);

  useEffect(() => {
    // Any side-effects you want to execute
  }, [username, station, pouchNumber]);

  return (
    <div className='main-content'>
      <Header />

      <div className="button-container">
        <div className="selected-medicineBag-info">
      <MedicineBagDetailTable token={token} /> 
        </div>

      </div>
    </div>
  );
};

export default DrugAdministration;
