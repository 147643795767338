// src/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: '',
    name: '',
    position: '',
    station: '',
    station_barcode: '',
    userBarcode: '',
    pouchNumber: '',
    pouchType: '',
    pouchBarcode: '',
    carrier: '',  
    carrierBarcode: '',  
    Medicine_bag: '', 
    Medicine_bagBarcode: ''
  },
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setUser: (state, action) => {
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.position = action.payload.position;
      state.userBarcode = action.payload.user_barcode;
    },
    setUserBarcode: (state, action) => {
      state.userBarcode = action.payload;
    },
    setStation: (state, action) => {
      state.station = action.payload;
    },
    setStationBarcode: (state, action) => {
      state.station_barcode = action.payload;
    },
    setPouchNumber: (state, action) => {
      state.pouchNumber = action.payload;
    },
    setPouchData: (state, action) => {
      state.pouchNumber = action.payload.pouchNumber;
      state.pouchType = action.payload.pouchType;
      state.pouchBarcode = action.payload.pouchBarcode;
    },
    setCarrier: (state, action) => {  
      state.carrier = action.payload;
    },
    setCarrierBarcode: (state, action) => {  
      state.carrierBarcode = action.payload;
    },
    setMedicine_bag: (state, action) => {  
      state.Medicine_bag = action.payload;
    },
    setMedicine_bagBarcode: (state, action) => { 
      state.Medicine_bagBarcode = action.payload;
    },
    clearUserDetail: (state, action) => {
      action.payload.details.forEach((detail) => {
        state[detail] = '';
      });
    },
  },
});

export const { setUser, setUserBarcode, setStation, setStationBarcode, setPouchNumber, setPouchData, setMedicine_bag, setMedicine_bagBarcode, clearUserDetail } = userSlice.actions; 

export default userSlice.reducer;
