import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const withAuthCheck = (WrappedComponent, selectToken) => {
  return (props) => {
    const navigate = useNavigate();
    const token = useSelector(selectToken);

    useEffect(() => {
      if (!token) {
        navigate('/login');
      }
    }, [token, navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuthCheck;