import Header from '../components/header'
import UserTable from '../components/EditTables/UserTable';

const token = localStorage.getItem("token");

function EditUsers() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
          <div className="main-container">
            <UserTable token={token}/>     
          </div>  
        </div>
    </div>
  );
}

export default EditUsers;