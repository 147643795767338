import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StorageUnitForm = ({ token, selectedStorageUnit }) => {
  const [unitBarcode, setUnitBarcode] = useState('');
  const [unitName, setUnitName] = useState('');
  const [location, setLocation] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [stations, setStations] = useState([]); 

  useEffect(() => {
    if (selectedStorageUnit) {
      setUnitBarcode(selectedStorageUnit.unit_barcode);
      setUnitName(selectedStorageUnit.unit_name);
      setLocation(selectedStorageUnit.location);
      setIsEditing(true);
    }
  }, [selectedStorageUnit]);

  // Fetch stations for location dropdown
  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get('/api/Station/', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setStations(response.data);
      } catch (error) {
        console.error('Error fetching stations:', error);
      }
    };

    fetchStations();
  }, [token]);

  const handleGenerateBarcode = async () => {
    try {
      const response = await axios.get('/api/generate_unique_barcode/S', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (response.status === 200) {
        setUnitBarcode(response.data.unique_barcode);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = isEditing ? `/api/storage-unit/update/${unitBarcode}/` : '/api/storage-unit/create/';
    try {
      await axios({
        method: isEditing ? 'put' : 'post',
        url: url,
        data: {
          unit_barcode: unitBarcode,
          unit_name: unitName,
          location: location,
        },
        headers: { 'Authorization': `Bearer ${token}` },
      });
      alert(`Storage unit ${isEditing ? 'updated' : 'created'} successfully`);
      // Reset form or redirect as needed
    } catch (error) {
      console.error(`Error ${isEditing ? 'updating' : 'creating'} storage unit:`, error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete this storage unit?`)) {
      try {
        await axios.delete(`/api/storage-unit/delete/${unitBarcode}/`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        alert('Storage unit deleted successfully');
        // Reset form or redirect as needed
      } catch (error) {
        console.error('Error deleting storage unit:', error);
      }
    }
  };

  const resetForm = () => {
    setUnitBarcode('');
    setUnitName('');
    setLocation('');
    setIsEditing(false); // Reset editing state as well
    // Note: You might not want to reset stations as they are fetched separately and might still be useful for a new entry
  };

  return (
    <div className="form-container">
      <h2>{isEditing ? 'Edit' : 'Add'} Storage Unit</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Unit Barcode:</label>
          <input
            type="text"
            value={unitBarcode}
            onChange={(e) => setUnitBarcode(e.target.value)}
            required
            disabled={isEditing}
          />
          {!isEditing && (
            <button type="button" onClick={handleGenerateBarcode}>Generate Barcode</button>
          )}
        </div>
        <div>
          <label>Unit Name:</label>
          <input
            type="text"
            value={unitName}
            onChange={(e) => setUnitName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Location:</label>
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          >
            <option value="">Select a Location</option>
            {stations.map(station => (
              <option key={station.station_barcode} value={station.station_name}>{station.station_name}</option>
            ))}
          </select>
        </div>
        <div>
          <button type="submit">{isEditing ? 'Update' : 'Submit'}</button>
          {isEditing && <button type="button" onClick={handleDelete} style={{marginLeft: "10px"}}>Delete</button>}
          <button type="button" onClick={resetForm} style={{marginLeft: "10px"}}>Refresh</button>
        </div>
      </form>
    </div>
  );
};

export default StorageUnitForm;
