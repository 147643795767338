import Header from '../components/header'
import StationTable from '../components/EditTables/StationTable';

const token = localStorage.getItem("token");

function EditStation() {
  return (
    <div className="App">
      <Header />
        <div className="main-content">
        <StationTable token={token} />     
        </div>
    </div>
  );
}

export default EditStation;