import React, { useState } from 'react';
import Header from '../components/header';
import PackingHeader from '../components/Packing/PackingHeader'; 
import PouchNumberInput from '../components/Packing/PouchNumberInput';
import NSPouchNumberInput from '../components/Packing/NSPouchNumberInput';


const Packing = () => {
  const token = localStorage.getItem('token');
  const [showBarcodeInput, setShowBarcodeInput] = useState(true);

  return (
    <div className="App">
      <Header />
      <div className="packing-app">
        <PackingHeader
          token={token}
          setShowBarcodeInput={setShowBarcodeInput}
          showBarcodeInput={showBarcodeInput}
        />


        </div>
      </div>
  );
};

export default Packing;
