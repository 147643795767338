import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import useSubmitStockHistory from './useSubmitStockHistory'; 
import useUnpackProductLocationAndCarrierHistorySubmit from '../CarrierTrack/AllProductUnpack/UnpackProductLocationAndCarrierHistorySubmit';
import useUnpackItemAndLocationSubmit from './useUnpackItemAndLocationSubmit';


import '../../static/CSS/StorageUserDisplay.css';

const UnpackingToStationStorage = ({ token }) => {
  const user = useSelector((state) => state.user);
  const { userBarcode } = user || {};
  const [pouchDetails, setPouchDetails] = useState([]);
  const [pouchNumbers, setPouchNumbers] = useState([]);
  const [filteredPouchNumbers, setFilteredPouchNumbers] = useState([]);
  const [filteredPouchDetails, setFilteredPouchDetails] = useState([]);
  const [barcodeSearch, setBarcodeSearch] = useState(''); // State to keep track of the barcode search input
  const [displayCarrierName, setDisplayCarrierName] = useState('');
  const [displayMedicineBagNumber, setDisplayMedicineBagNumber] = useState('');
  const [displayVehicleNumber, setDisplayVehicleNumber] = useState('');
  const [searchBinBarcode, setSearchBinBarcode] = useState('');
  const [displayBinInfo, setDisplayBinInfo] = useState({ binName: '', storageUnitName: '' });
  const { performUnpackAndUpdate } = useUnpackItemAndLocationSubmit(token, user);

  const [quantity, setQuantity] = useState(1);
  const carrierBarcode = user.carrierBarcode; // or another source if applicable
  const [scannedItemBarcode, setScannedItemBarcode] = useState('');
  const [vehicles, setVehicles] = useState([]);

  const [carriers, setCarriers] = useState([]);
  const [medicineBags, setMedicineBags] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState('');
  const [selectedContentType, setSelectedContentType] = useState('');
  const [contents, setContents] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [bins, setBins] = useState([]);
  const [selectedBin, setSelectedBin] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  
  const [data, setData] = useState({
      product_barcode: '',
      bin_barcode: '',
      action: 'unpack',
      quantity_changed: 1, // Assuming unpacking is always of quantity 1
      user: userBarcode,
      comments: '',
    });

  // Fetch carriers and medicine bags
  useEffect(() => {
    // Fetch carriers and medicine bags
    const fetchCarriersAndMedicineBags = async () => {
      try {
        const [carrierResp, medicineBagResp, vehicleResp] = await Promise.all([
          axios.get('/api/Carrier/', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('/api/MedicineBags/', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('/api/Vehicle/', { headers: { Authorization: `Bearer ${token}` } }), // Fetch vehicles concurrently
        ]);
  
        setCarriers(carrierResp.data);
        setMedicineBags(medicineBagResp.data);
        setVehicles(vehicleResp.data); // Set fetched vehicles
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchCarriersAndMedicineBags();
  }, [token]);

  useEffect(() => {
    if (searchBinBarcode) {
      const findBin = async () => {
        try {
          const response = await axios.get(`/api/bin/barcode/${searchBinBarcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.data) {
            setDisplayBinInfo({
              binName: response.data.bin_name,
              storageUnitName: response.data.storage_unit_name,
            });
            setSelectedBin(response.data.bin_barcode);
          } else {
            setDisplayBinInfo({ binName: '', storageUnitName: '' });
          }
        } catch (error) {
          console.error('Error searching bin with barcode:', error);
          setDisplayBinInfo({ binName: '', storageUnitName: '' });
        }
      };
      findBin();
    } else {
      setDisplayBinInfo({ binName: '', storageUnitName: '' });
    }
  }, [searchBinBarcode, token]);

  useEffect(() => {
    const fetchBins = async () => {
      try {
        const response = await axios.get('/api/bin/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBins(response.data); // Make sure this matches the structure of your response
      } catch (error) {
        console.error("Error fetching bins:", error);
      }
    };
  
    fetchBins();
  }, [token]);
  
  useEffect(() => {
    const fetchItemDetails = async () => {
      if (scannedItemBarcode && carrierBarcode) {
        try {
          // Use the packed_products_in_carrier endpoint and pass the carrier barcode as a parameter
          const response = await axios.get(`/api/packed_products_in_carrier/?carrier_barcode=${carrierBarcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          // Assuming the API response includes an array of items, find the scanned item in the response
          const foundItem = response.data.find(item => item.Product_Barcode === scannedItemBarcode);
  
          if (foundItem) {
            setSelectedItem(scannedItemBarcode);
          } else {
            console.log('Scanned item not found in the selected carrier.');
            // Handle the case where the scanned item is not found in the carrier
          }
        } catch (error) {
          console.error('Error fetching item details from the carrier:', error);
        }
      }
    };
  
    fetchItemDetails();
  }, [scannedItemBarcode, carrierBarcode, token]);

  const fetchContents = async () => {
    if (!selectedContainer || !selectedContentType) {
      console.log("No container or content type selected.");
      setContents([]);
      return;
    }
  
    try {
      let endpoint;
      // Set the base endpoint without appending the selectedContainer to the path
      switch (selectedContentType) {
        case 'carrier':
          endpoint = '/api/packed_products_in_carrier/';
          break;
        case 'medicineBag':
          endpoint = '/api/packed_products_in_medicine_bag/';
          break;
        case 'vehicle':
          endpoint = '/api/packed_products_in_vehicle/';
          break;
        default:
          console.error(`Unsupported container type: ${selectedContentType}`);
          return;
      }
  
      // Prepare the query parameters based on selected container type
      const queryParams = new URLSearchParams();
      if (selectedContentType === 'carrier') {
        queryParams.append('Carrier_Barcode', selectedContainer);
      } else if (selectedContentType === 'medicineBag') {
        queryParams.append('Medicine_bag_Barcode', selectedContainer);
      } else if (selectedContentType === 'vehicle') {
        queryParams.append('Vehicle_Barcode', selectedContainer);
      }
  
      console.log(`Fetching contents from ${endpoint} with query: ${queryParams.toString()}`);
      const response = await axios.get(`${endpoint}?${queryParams.toString()}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.status === 200) {
        const mappedContents = response.data.map(item => ({
          barcode: item.Product_Barcode,
          name: item.Product_Detail,
        }));
        console.log("Fetched contents:", mappedContents);
        setContents(mappedContents);
      } else {
        console.error(`Failed to fetch contents. Status: ${response.status}`);
        setContents([]);
      }
    } catch (error) {
      console.error(`Error fetching contents from ${selectedContentType}:`, error);
      setContents([]);
    }
  };

  
  // UseEffect to fetch container contents
  useEffect(() => {
    if (selectedContainer && selectedContentType) {
      fetchContents();
    }
  }, [selectedContainer, selectedContentType, token]);


  
  useEffect(() => {
    setFilteredPouchDetails(
      pouchDetails.filter(detail =>
        detail.Product_Detail.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredPouchNumbers(
      pouchNumbers.filter(number => number.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm, pouchDetails, pouchNumbers]);

  const handleBarcodeSearch = async (barcode) => {
    setBarcodeSearch(barcode);
  
    try {
      let endpoint = '';
      let params = {};
  
      // Determine if the barcode belongs to a carrier or medicine bag
      if (barcode.startsWith('C')) {
        endpoint = `/api/Carrierbarcode/${barcode}`;
        params = { Carrier_Barcode: barcode };
      } else if (barcode.startsWith('B')) {
        endpoint = `/api/MedicineBags_Barcode/${barcode}`;
        params = { Medicine_bag_Barcode: barcode };
      } else if (barcode.startsWith('V')) { // Handling vehicle barcodes
        endpoint = `/api/Vehiclebarcode/${barcode}`;
        params = { Vehicle_Barcode: barcode };
      }
  
      // Fetch details if a valid endpoint has been determined
      if (endpoint) {
        const detailResponse = await axios.get(endpoint, { headers: { Authorization: `Bearer ${token}` } });
  
        if (barcode.startsWith('C')) {
          // Use carrier_number from the detailResponse data to update display name
          setDisplayCarrierName(detailResponse.data.carrier_number);
          setDisplayMedicineBagNumber(''); // Clear medicine bag number since it's a carrier
        } else if (barcode.startsWith('B')) {
          // Use medicine_bag_number from the detailResponse data to update display name
          setDisplayMedicineBagNumber(detailResponse.data.medicine_bag_number);
          setDisplayCarrierName(''); // Clear carrier name since it's a medicine bag
        } else if (barcode.startsWith('V')) {
          // Use vehicle_number from the detailResponse data to update display name
          setDisplayVehicleNumber(detailResponse.data.vehicle_number);
          setDisplayCarrierName(''); // Clear carrier name since it's now a vehicle
          setDisplayMedicineBagNumber(''); // Clear medicine bag number since it's now a vehicle
        }
  
        // Fetch contents based on carrier, medicine bag, or vehicle
        const contentResponse = await axios.get(`/api/packed_products_in_${barcode.startsWith('C') ? 'carrier' : barcode.startsWith('B') ? 'medicine_bag' : 'vehicle'}/`, { params, headers: { Authorization: `Bearer ${token}` } });
        const contents = contentResponse.data.map(item => ({ barcode: item.Product_Barcode, name: item.Product_Detail }));

        setContents(contents); // Update contents state with fetched data
      } else {
        // Clear contents and display names if the barcode does not match expected formats
        setContents([]);
        setDisplayCarrierName('');
        setDisplayMedicineBagNumber('');
        setDisplayVehicleNumber('');
      }
    } catch (error) {
      console.error('Error fetching details with barcode:', error);
      setContents([]);
      setDisplayCarrierName('');
      setDisplayMedicineBagNumber('');
      setDisplayVehicleNumber('');
    }
  };
  
  useEffect(() => {
    // Clear previous contents
    setContents([]);

    if (selectedContainer && selectedContentType) {
      console.log(`Fetching contents for container: ${selectedContainer}`);
      // Example fetching logic, replace with your actual API call
      const fetchUrl = `/api/${selectedContentType}/${selectedContainer}/contents`;
      axios.get(fetchUrl, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          setContents(response.data);
          console.log("Fetched contents:", response.data);
        })
        .catch(error => console.error(`Error fetching contents from ${selectedContentType}:`, error));
    }
  }, [selectedContainer, selectedContentType, token]);

  const handleContainerTypeChange = e => {
    setSelectedContentType(e.target.value);
    setSelectedContainer('');
  };

  const handleUnpackAction = async () => {
    const productBarcode = selectedItem; // Assuming this is your product barcode
    const containerBarcode = selectedContainer; // The barcode for carrier, medicine bag, or vehicle
    const quantityToUnpack = quantity; // Assuming this is already a number
    const locationBarcode = user.station_barcode; // Assuming selectedBin is your location barcode
    const binBarcode = selectedBin; // Assuming selectedBin is your location barcode

    console.log(`Unpacking: ${productBarcode}, Container: ${containerBarcode}, Quantity: ${quantityToUnpack}, Location: ${locationBarcode}, bin barcode: ${binBarcode} `);
    
    if (!productBarcode || !containerBarcode || !locationBarcode) {
      alert('Missing required information for unpacking.');
      return;
    }

    performUnpackAndUpdate(productBarcode, containerBarcode, binBarcode, quantity).then(() => {
      console.log('Complete workflow executed successfully.');
    }).catch((error) => {
      console.error('An error occurred in the workflow:', error);
    });

  };


  

  // Within the UnpackingToStationStorage component
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Determine the product_barcode based on user input
    const productBarcode = barcodeSearch || selectedItem; // Use barcodeSearch or selectedItem
    const quantityToUnpack = Number(quantity);

    try {
      // Handle unpack action
      await handleUnpackAction(productBarcode, quantityToUnpack, user.station_barcode);
      // Form submission and state reset logic here, if any
      alert('Unpacking and stock history update successful');
      resetForm();
    } catch (error) {
      console.error("Error during overall unpacking action:", error);
      alert('Error during overall unpacking action.');
    }
  };


  // Function to reset form state after submission or when needed
  const resetForm = () => {
    setData({
      product_barcode: '',
      bin_barcode: '',
      action: 'unpack',
      quantity_changed: 1,
      user: userBarcode,
      comments: '',
    });

    // Also reset other relevant states
    setSelectedContainer('');
    setSelectedContentType('');
    setSelectedItem('');
    setSelectedBin('');
    setBarcodeSearch('');
    setSearchBinBarcode('');
    setDisplayCarrierName('');
    setDisplayMedicineBagNumber('');
    // Reset any other state you might be using
  };


  return (
    <div className="Form-card">
      <div className="Form-card-body">
        <form onSubmit={handleSubmit} className="Form-form-container">
          <h3 className="Form-Title">Unpack to Station Storage</h3>

          {/* Barcode Search Input */}
          {!selectedContentType && ( // Show this input if no container type has been selected
            <div className="Form-form-row">
              <label>Carrier Barcode Search:</label>
              <input
                type="text"
                value={barcodeSearch}
                onChange={(e) => handleBarcodeSearch(e.target.value)}
                className="Form-input"
                placeholder="Enter barcode to search"
              />
            </div>
          )}
          {displayCarrierName && (
            <div className="Form-form-row">
              <label>Carrier Name:</label>
              <span>{displayCarrierName}</span>
            </div>
          )}

          {displayMedicineBagNumber && (
            <div className="Form-form-row">
              <label>Medicine Bag Number:</label>
              <span>{displayMedicineBagNumber}</span>
            </div>
          )}
          {displayVehicleNumber && (
            <div className="Form-form-row">
              <label>Vehicle Number:</label>
              <span>{displayVehicleNumber}</span>
            </div>
          )}

          {/* Container Type Selection */}
          {!barcodeSearch && ( // Show this dropdown if no barcode has been entered
            <div className="Form-form-row">
              <label>Container Type:</label>
              <select onChange={handleContainerTypeChange} value={selectedContentType} className="Form-select">
                <option value="">Select Type</option>
                <option value="carrier">Carrier</option>
                <option value="medicineBag">Medicine Bag</option>
                <option value="vehicle">Vehicle</option>
              </select>
            </div>
          )}

          {/* Conditional Rendering of Container Selection based on selectedContentType */}
          {selectedContentType && (
            <div className="Form-form-row">
              <label>Container:</label>
              <select
                onChange={e => {
                  const selectedValue = e.target.value;
                  console.log(`Selected container value (from dropdown): ${selectedValue}`);
                  setSelectedContainer(selectedValue); // Assuming this now correctly holds the barcode
                }}
                value={selectedContainer}
                className="Form-select"
              >
                <option value="">Select Container</option>
                {selectedContentType === 'carrier' && carriers.map(carrier => (
                  // Assuming `carrier` object has `barcode` property that holds the barcode value
                  <option key={carrier.id} value={carrier.barcode}>{carrier.carrier_number}</option>
                ))}
                {selectedContentType === 'medicineBag' && medicineBags.map(bag => (
                  // Assuming `bag` object has `medicine_bag_barcode` property that holds the barcode value
                  <option key={bag.id} value={bag.medicine_bag_barcode}>{bag.medicine_bag_number}</option>
                ))}
                {selectedContentType === 'vehicle' && vehicles.map(vehicle => ( // Add vehicles to the dropdown
                  <option key={vehicle.id} value={vehicle.vehicle_barcode}>{vehicle.vehicle_number}</option>
                ))}
              </select>
            </div>
          )}

          <div className="Form-form-row">
            <label htmlFor="scannedBarcode">Scan Item Barcode:</label>
            <input
              type="text"
              id="scannedBarcode"
              name="scannedBarcode"
              value={scannedItemBarcode}
              onChange={(e) => setScannedItemBarcode(e.target.value)}
              className="Form-input"
              placeholder="Scan barcode here"
            />
          </div>
          <div className="Form-form-row">
            <label>Item:</label>
            <select onChange={e => setSelectedItem(e.target.value)} value={selectedItem} className="Form-select">
              <option value="">Select Item</option>
              {contents.map((content, index) => (
                <option key={index} value={content.barcode}>{content.name}</option>
              ))}
            </select>
          </div>

          {/* Quantity Input Field */}
          <div className="Form-form-row">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="Form-input"
            />
          </div>

          {/* Conditionally render the bin search input or the container type dropdown based on user interaction */}
          {/* Search Bin by Barcode */}
          {(!selectedContentType && !barcodeSearch) && (
            <div className="Form-form-row">
              <label>Search Bin by Barcode:</label>
              <input
                type="text"
                placeholder="Enter bin barcode"
                value={searchBinBarcode}
                onChange={(e) => setSearchBinBarcode(e.target.value)}
                className="Form-input"
              />
              {displayBinInfo.binName && (
                <div>
                  <p>Bin Name: {displayBinInfo.binName}</p>
                  <p>Storage Unit: {displayBinInfo.storageUnitName}</p>
                </div>
              )}
            </div>
          )}

          <div className="Form-form-row">
            <label>Bin:</label>
            <select onChange={e => setSelectedBin(e.target.value)} value={selectedBin} className="Form-select">
              <option value="">Select Bin</option>
              {bins.map(bin => (
                <option key={bin.id} value={bin.bin_barcode}>{bin.bin_name}</option>
              ))}
            </select>
          </div>

          <div className="Form-form-row-button-container">
            <button className="button" type="submit">Unpack Items</button>
            <button className="button" type="button" onClick={resetForm}>Refresh</button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default UnpackingToStationStorage;
