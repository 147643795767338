import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useProductLocationSubmit from '../Location/ProductLocationSubmit'; // Import your custom hook

const MedicinebagPackCombined = ({ token, onReload, setPouchBarcodeExport }) => {
    const user = useSelector((state) => state.user) || {
        station_barcode: localStorage.getItem('station_barcode'),
    };
    const [isQAMode, setIsQAMode] = useState(false);
    const [pouchBarcode, setPouchBarcode] = useState(''); // Keep using pouchBarcode as is
    const [selectedPouchBarcode, setSelectedPouchBarcode] = useState('');
    const [pouchStatus, setPouchStatus] = useState('');
    const [confirmAction, setConfirmAction] = useState(false);
    const [pouchNumber, setPouchNumber] = useState('');
    const [pouchNumbers, setPouchNumbers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPouchNumbers, setFilteredPouchNumbers] = useState([]);
    const [dropdownPouchNumber, setDropdownPouchNumber] = useState('');
    const submitProductLocation = useProductLocationSubmit(token);
    
   
    // For dropdown selection
    const handleDropdownChange = async (e) => {
        const selectedNumber = e.target.value;
        setDropdownPouchNumber(selectedNumber);

        try {
            const response = await axios.get(`/api/Pouch/?pouchNumber=${selectedNumber}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const pouch = response.data.find(p => p.pouch_number === selectedNumber);
            if (pouch) {
                setSelectedPouchBarcode(pouch.pouch_barcode);
                setPouchBarcodeExport(pouch.pouch_barcode); // Export the barcode to the parent component
            }
        } catch (error) {
            console.error('Error fetching pouch details by number:', error);
        }
    };

    // When the pouch barcode is directly entered
    const handlePouchBarcodeChange = (e) => {
        const barcode = e.target.value;
        setPouchBarcode(barcode); // Update local state
        setPouchBarcodeExport(barcode); // Export the barcode to the parent component
    };

    
    useEffect(() => {
        const fetchPouchStatus = async () => {
            if (!pouchBarcode) return;
            try {
                const response = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${pouchBarcode}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data[0] && response.data[0].Pouch_Status) {
                    setPouchStatus(response.data[0].Pouch_Status);
                } else {
                    setPouchStatus('Status Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch status:', error);
            }
        };
        
        const fetchPouchNumber = async () => {
            if (!pouchBarcode) return;
            try {
                const response = await axios.get(`/api/PouchBarcode/${pouchBarcode}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data && response.data.pouch_number) {
                    setPouchNumber(response.data.pouch_number);
                } else {
                    setPouchNumber('Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch number:', error);
            }
        };
    
        fetchPouchStatus();
        fetchPouchNumber();
    }, [pouchBarcode, token]);

    useEffect(() => {
        const fetchPouchNumbers = async () => {
            try {
                const response = await axios.get('/api/Pouch/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setPouchNumbers(response.data.map(pouch => pouch.pouch_number));
                }
            } catch (error) {
                console.error('Error fetching pouch numbers:', error);
            }
        };
    
        fetchPouchNumbers();
    }, [token]);


    useEffect(() => {
        const fetchData = async () => {
            if (!dropdownPouchNumber) return;
            try {
                const barcodeResponse = await axios.get(`/api/Pouch/?pouchNumber=${dropdownPouchNumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                const matchingPouch = barcodeResponse.data.find(pouch => pouch.pouch_number === dropdownPouchNumber);
                if (matchingPouch && matchingPouch.pouch_barcode) {
                    setSelectedPouchBarcode(matchingPouch.pouch_barcode);
                    const statusResponse = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${matchingPouch.pouch_barcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (statusResponse.data[0] && statusResponse.data[0].Pouch_Status) {
                        setPouchStatus(statusResponse.data[0].Pouch_Status);
                    } else {
                        setPouchStatus('Status Not Available');
                    }
                }
            } catch (error) {
                console.error('Error fetching pouch details:', error);
            }
        };
    
        fetchData();
    }, [dropdownPouchNumber, token]);
    
    

    useEffect(() => {
        setFilteredPouchNumbers(
            pouchNumbers.filter(number => 
                number.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, pouchNumbers]);

    const handleProceed = () => {
        setConfirmAction(false);
        const barcodeToUse = isQAMode ? pouchBarcode : selectedPouchBarcode;
        submitMedicineBagHistoryAndUpdateLocation(barcodeToUse);
    };

    const handleCancel = () => {
        setConfirmAction(false);
        setDropdownPouchNumber('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const barcodeToUse = isQAMode ? pouchBarcode : selectedPouchBarcode;
    
        // Check if the pouch is already in the medicine bag
        const isAlreadyPacked = await checkIfPouchIsAlreadyPacked(barcodeToUse);
        if (isAlreadyPacked) {
            alert('This pouch is already packed in the medicine bag.');
            return;
        }
    
        // Check if pouch exists in any carrier history and is packed
        const pouchExistsInCarrierInfo = await checkPouchExistsInCarrierHistory(barcodeToUse);
        if (pouchExistsInCarrierInfo.exists) {
            alert(`This pouch is currently being shown as packed in the carrier ${pouchExistsInCarrierInfo.carrierNumber}. Please unpack before packing into a medicine bag.`);
            return;
        }
        
        // Check if pouch exists in medicine bag history
        const pouchExistsInfo = await checkPouchExistsInMedicineBagHistory(barcodeToUse);
        if (pouchExistsInfo.exists) {
            alert(`This pouch is currently being shown as packed in the ${pouchExistsInfo.medicineBagNumber}`);
            if (isQAMode) {
                setPouchBarcode('');
            } else {
                setSearchTerm('');
            }
            return;
        }
    
        if (pouchStatus === 'Unpacked' || pouchStatus === 'Status Not Available') {
            alert("This is showing as unpacked. Please ensure it is packed");
            if (isQAMode) {
                setPouchBarcode('');
            } else {
                setSearchTerm('');
            }
            return;
        }
        
        // Allow any pouch barcode to be submitted
        submitMedicineBagHistoryAndUpdateLocation(barcodeToUse);
    };
    
    const checkPouchExistsInCarrierHistory = async (pouchBarcode) => {
        try {
            // Making a GET request to the packed_pouches_in_carrier endpoint without specifying a Carrier_Barcode
            const response = await axios.get(`/api/packed_pouches_in_carrier/?Pouch_Barcode=${pouchBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Check if any rows were returned that match the given pouch barcode
            if (response.data && response.data.length > 0) {
                // Find the first instance where the pouch is packed
                const packedPouch = response.data.find(pouch => pouch.Pouch_Barcode === pouchBarcode && pouch.Carrier_Status === 'Packed');
    
                if (packedPouch) {
                    // Return the carrier number associated with this pouch
                    return { exists: true, carrierNumber: packedPouch.Carrier_Number };
                }
            }
            return { exists: false };
        } catch (error) {
            console.error('Error checking if pouch exists in any carrier:', error);
            return { exists: false, carrierNumber: null };
        }
    };
    

    const submitMedicineBagHistoryAndUpdateLocation = async (barcode) => {
        const stationBarcode = user.station_barcode || localStorage.getItem('station_barcode');
        const medicineBagBarcode = user.Medicine_bagBarcode; // Ensure this value is correctly obtained
        const medicineBagHistoryData = {
            MedicineBag_Barcode: medicineBagBarcode,
            Pouch_Barcode: barcode,
            Station_Barcode_Start: stationBarcode,
            datetime_start: new Date().toISOString(),
            amount: 1,
            User_Packed: user.userBarcode, // Ensure this is correctly obtained
            MedicineBag_Status: "Packed",
        };

    
        try {
            const historyResponse = await axios.post('/api/Medicine_BagHistory/create/', medicineBagHistoryData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (historyResponse.status === 200 || historyResponse.status === 201) {
                // Call submitProductLocation only once, correctly passing the necessary parameters
                // If you need to update the location for both the pouch and the medicine bag, consider making two separate calls or adjusting your API to handle multiple updates in one call
                submitProductLocation(
                    barcode, // Assuming this is the pouch barcode
                    stationBarcode,
                    () => {
                        console.log('Location updated successfully for the pouch!');
                        // Additional logic if needed
                    },
                    (errorMsg) => {
                        console.error(`Error updating location for the pouch: ${errorMsg}`);
                    }
                );
    
                // If needed, call submitProductLocation again for the medicine bag, but ensure logic is correct for your use case
                submitProductLocation(
                    medicineBagBarcode, // This should be the medicine bag's barcode
                    stationBarcode,
                    () => {
                        alert('Medicine bag and location updated successfully!');
                        onReload(); // Ensure onReload is defined and correctly triggers a UI update
                    },
                    (errorMsg) => {
                        alert(`Error creating product location for the medicine bag: ${errorMsg}`);
                    }
                );
            } else {
                alert('Error adding medicine bag history.');
            }
        } catch (error) {
            console.error('Error in operation:', error);
            alert('An error occurred during the operation.');
        }
    };
    
      
    
    const checkIfPouchIsAlreadyPacked = async (barcode) => {
        try {
          const response = await axios.get(`/api/packed_pouches_in_medicine_bag/?pouchBarcode=${barcode}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data.some(pouch => pouch.Pouch_Barcode === barcode);
        } catch (error) {
          console.error('Error checking packed pouches:', error);
          return false;
        }
      };


    const checkPouchExistsInMedicineBagHistory = async (barcode) => {
        try {
            const response = await axios.get(`/api/Medicine_BagHistory/?pouchBarcode=${barcode}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            for (let pouchData of response.data) {
                if (pouchData.Pouch_Barcode === barcode && pouchData.Medicine_Bag_Status === 'Packed') {
                    const medicineBagResponse = await axios.get(`/api/Medicine_Bag/?Medicine_Bag_Barcode=${pouchData.Medicine_bagBarcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const medicineBagData = medicineBagResponse.data && medicineBagResponse.data[0];
                    return {
                        exists: true,
                        medicineBagNumber: medicineBagData ? medicineBagData.medicine_bag_number : "Unknown"
                    };
                }
            }
            return { exists: false };
    
        } catch (error) {
            console.error('Error checking pouch in MedicineBagHistory:', error);
            return { exists: false };
        }
    };

    // Function to toggle between QA mode and Manual mode
    const toggleMode = () => {
        setIsQAMode(!isQAMode);
    };


    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Pack Medicine Bag</h2>
                <div className="packing-main-content">
                    {confirmAction ? (
                        <div>
                            <p>This pouch is showing as {pouchStatus} and has not been QAed. Are you sure you want to continue?</p>
                            <button onClick={handleProceed} className="card-button">Yes</button>
                            <button onClick={handleCancel} className="card-button">No</button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            {isQAMode ? (
                                <div className="input-group">
                                    <label>Pouch Barcode</label>
                                    <input 
                                        type="text"
                                        value={pouchBarcode}
                                        onChange={handlePouchBarcodeChange}
                                        placeholder="Enter Pouch Barcode"
                                    />
                                    <p>Pouch Number: {pouchNumber}</p>
                                </div>
                            ) : (
                                <div className="input-group">
                                    <label htmlFor="pouchNumberSearch">Pouch Number</label>
                                    <input 
                                        id="pouchNumberSearch"
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search Pouch Number"
                                    />
                                    <select 
                                        id="pouchNumberDropdown"
                                        value={dropdownPouchNumber}
                                        onChange={handleDropdownChange}
                                    >
                                        <option value="">Select Pouch</option>
                                        {filteredPouchNumbers.map((number, index) => (
                                            <option key={index} value={number}>{number}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <button type="submit" className="card-button">
                                Pack to Medicine Bag {user.medicineBag}
                            </button>
                        </form>
                    )}
                </div>
                <div className="button-group">
                    <button onClick={toggleMode} className="toggle-button-small">
                        {isQAMode ? "Manual" : "Barcode"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MedicinebagPackCombined;
