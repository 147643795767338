import axios from 'axios';

const useProductLocationAndCarrierHistorySubmit = (token, user) => {
  // Function to submit the product location
  const submitProductLocation = async (Barcode, Location_Barcode, onSuccess, onError) => {
    const url = `/api/ProductLocation/create/`;
    const dataWithDatetime = {
      barcode: Barcode,
      location_barcode: Location_Barcode,
      datetime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(url, dataWithDatetime, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        onSuccess(`Product location created successfully.`);
      } else {
        onError(`Error creating product location: status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error creating product location:`, error);
      onError(`Error creating product location: ${error.response.data}`);
    }
  };

  // Adjusted function to handle multiple submissions for carrier history based on quantity
  const submitCarrierHistoryAndUpdateLocation = async (barcodeToSubmit, locationBarcode, quantity, onReload) => {
    if (!barcodeToSubmit) {
      console.error('No barcode provided for submission');
      return;
    }

    // Ensure quantity is a positive integer, default to 1 if not specified or invalid
    const submissionsCount = Math.max(1, parseInt(quantity, 10));

    try {
      // Loop to submit carrier history data equal to the specified quantity
      for (let i = 0; i < submissionsCount; i++) {
        const carrierHistoryData = {
          Carrier_Barcode: user.carrierBarcode,
          Product_Barcode: barcodeToSubmit,
          Station_Barcode_Start: user.stationBarcode,
          datetime_start: new Date().toISOString(),
          amount: 1, // Each entry will have an amount of 1
          User_Packed: user.userBarcode,
          Carrier_Status: "Packed",
        };

        const historyResponse = await axios.post('/api/CarrierHistory/create/', carrierHistoryData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        // Check response for each submission. If any submission fails, break the loop and alert the user
        if (!(historyResponse.status === 200 || historyResponse.status === 201)) {
          alert(`Error adding carrier history at submission ${i + 1}. Response status: ` + historyResponse.status);
          return; // Exit the loop early on failure
        }
      }

      // If all submissions are successful, update the product location
      submitProductLocation(
        barcodeToSubmit,
        locationBarcode,
        () => {
          console.log('Carrier history and location added successfully for all items!');
          alert('Carrier history and location added successfully for all items!');
          if (onReload) onReload();
        },
        (errorMsg) => {
          console.error(`Error updating location for the carrier or pouch: ${errorMsg}`);
          alert(`Error updating location for the carrier or pouch: ${errorMsg}`);
        }
      );
    } catch (error) {
      console.error('Error in operation:', error);
      alert('An error occurred during the operation. ' + error.message);
    }
  };

  return { submitCarrierHistoryAndUpdateLocation };
};

export default useProductLocationAndCarrierHistorySubmit;
