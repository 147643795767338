import React from 'react';
import Header from '../../components/header';
import PouchTypeSummary from '../../components/Reports/PouchTypeSummary';

const PouchTypeSummaryReport = () => {
  const token = localStorage.getItem('token');
  // Define pouch_type and status as needed
  const pouch_type = 'yourPouchType';
  const status = 'yourStatus';

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <PouchTypeSummary token={token} />
      </div>
    </div>
  );
};

export default PouchTypeSummaryReport;
