import React from 'react';
import Header from '../components/header'; // Adjust the path as necessary
import StorageStockAddForm from '../components/StorageUnits/StorageStockAddForm';
import UnpackingToStationStorage from '../components/StorageUnits/UnpackingToStationStorage'; // Adjust the path as necessary
import StationStockToContainer from '../components/StorageUnits/Stationtocontainer/StationStockToContainer'; // Adjust the path as necessary
 
const StationStoragePage = () => {
  const token = localStorage.getItem('token');

  return (
    <div className="App">
      <Header />
      <div className="station-storage-page">
        <StorageStockAddForm token={token} />
        <UnpackingToStationStorage token={token} />
        <StationStockToContainer token={token} />
        
      </div>
    </div>
  );
};

export default StationStoragePage;
