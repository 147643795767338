import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import ConsumableBatchForm from "./ConsumableBatchForm";

const ConsumableBatchTable = ({token}) => {
  let [consumable, setconsumableBatch] = useState([]);
  const [selectedconsumable, setSelectedconsumableBatch] = useState(null);
  const [selectedconsumableId, setSelectedconsumableId] = useState(null);

  useEffect(() => {
    getconsumableBatch();
  }, []);

  let getconsumableBatch = async () => {
    try {
      let response = await fetch('/api/ConsumableBatch/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let data = await response.json();
      setconsumableBatch(data);
    } catch (error) {
      console.error('Error fetching consumable:', error);
    }
};


  const initialColumns = ['consumable name', 'Batch Number', 'Expiry Date', 'consumable Pricing', 'Supplier', 'Pack Size'];
  const allColumns = ['Supplier', 'Pack Size', 'Product Barcode', 'Unique_id','consumable name', 'batch_number', 'expiry_date', 'consumable_pricing'];

  const tableData = Array.isArray(consumable) ? consumable.map(consumable => ({
    'Consumable name': consumable.consumable_name,
    'Batch Number': consumable.batch_number,
    'Expiry Date': new Date(consumable.expiry_date).toLocaleDateString(),
    'consumable Pricing': consumable.consumable_pricing,
    'Product Barcode': consumable.product_barcode,
    'Pack Size': consumable.pack_size,
    'Supplier': consumable.supplier,
    'Unique_id': consumable.unique_id,
})) : [];


  const handleRowSelect = (selectedRow) => {
    setSelectedconsumableBatch(selectedRow);
    setSelectedconsumableId(selectedRow.Unique_id);
  };

  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

        <div className="form-container">
          <ConsumableBatchForm selectedconsumable={selectedconsumable} token={ token } />
        </div>        
        <div className="consumable-container">
        <div className="consumable-list">
          <EditableTable 
            selectedconsumable={selectedconsumable} 
            selectedconsumableId={selectedconsumableId} 
            setSelectedconsumableId={setSelectedconsumableId} 
            token={token} 
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns} // Add this
            allColumns={allColumns} // Add this
            data={tableData}
          />        
        </div>
      </div>
    </div>
  );
  
};

export default ConsumableBatchTable;
