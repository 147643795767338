import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StorageUnitForm from './StorageunitForm';

const StorageUnitTable = ({ token }) => {
  const [storageUnits, setStorageUnits] = useState([]);
  const [selectedStorageUnit, setSelectedStorageUnit] = useState(null);

  useEffect(() => {
    fetchStorageUnits();
  }, []);

  const fetchStorageUnits = async () => {
    try {
      const response = await axios.get('/api/storage-unit/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStorageUnits(response.data);
    } catch (error) {
      console.error("Error fetching storage units:", error);
    }
  };

  const handleRowClick = (unit) => {
    setSelectedStorageUnit(unit);
  };

  const handleFormSubmit = () => {
    fetchStorageUnits(); // Refresh the list after adding/editing a unit
    setSelectedStorageUnit(null); // Reset selection
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="storage-unit-container">
        <StorageUnitForm 
          token={token} 
          selectedStorageUnit={selectedStorageUnit} 
          onFormSubmit={handleFormSubmit} 
        />
        <div className="table-list" style={{ marginTop: '20px' }}>
          <table>
            <thead>
              <tr>
                <th>Unit Barcode</th>
                <th>Unit Name</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {storageUnits.map((unit, index) => (
                <tr key={index} onClick={() => handleRowClick(unit)}>
                  <td>{unit.unit_barcode}</td>
                  <td>{unit.unit_name}</td>
                  <td>{unit.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StorageUnitTable;
