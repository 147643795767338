import React from 'react';
import Header from '../components/header';
import MedicineBagExpiry from '../components/Expiry/MedicineBagExpiry';

const token = localStorage.getItem("token");

function EditMedicineBagExpiry() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <MedicineBagExpiry token={token} />
      </div>
    </div>
  );
}

export default EditMedicineBagExpiry;
