import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import Orders from '../components/Orders/Orders';
import CompleteOrders from '../components/Orders/CompleteOrders'; 
import SelectableOrdersTable from '../components/Orders/SelectableOrdersTable'; // <-- Import new component
import axios from 'axios';

const Pack = () => {
  const [reloadFlag, setReloadFlag] = useState(false);
  const handleReload = () => {
    setReloadFlag(!reloadFlag);
  };
  const [selectedOrder, setSelectedOrder] = useState(null); // <-- New state for selected order
  const [orders, setOrders] = useState([]); // <-- New state for orders

  useEffect(() => {
    // Fetch orders here and update the state
    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get('/api/orders/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [reloadFlag]);

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <Orders token={localStorage.getItem("token")} />
        <SelectableOrdersTable orders={orders} onRowSelect={setSelectedOrder} />
        <CompleteOrders token={localStorage.getItem("token")} selectedOrder={selectedOrder} />
      </div>
    </div>
  );
};

export default Pack;
