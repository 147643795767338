import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useProductLocationAndCarrierHistorySubmit from './ProductLocationAndCarrierHistorySubmit';
import LocationForm from '../../Station Carrier Inputs/StationInput'; 
import { useSelector } from 'react-redux';
import ProductDetailsCard from '../../Display/ProductDetailsCard'; // Updated import
import SimpleModal from '../../Modal/SimpleModal';


const CarrierAllProductForm = ({ token, onReload  }) => {
    const [searchResult, setSearchResult] = useState('');
    const [medicineBags, setMedicineBags] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [pouches, setPouches] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [consumableBatch, setConsumableBatch] = useState([]);
    const [selectedDatabase, setSelectedDatabase] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [databaseItems, setDatabaseItems] = useState([]);
    const [searchBarcode, setSearchBarcode] = useState('');
    const [showLocationForm, setShowLocationForm] = useState(false);
    const stationName = useSelector((state) => state.user.station);
    const stationBarcode = useSelector((state) => state.user.station_barcode);
    const userBarcode = useSelector((state) => state.user.userBarcode); // Assuming user_barcode is available in your Redux store
    const carrierBarcode = useSelector((state) => state.user.carrierBarcode); // Assuming carrier_barcode is available in your Redux store
    const [itemFilter, setItemFilter] = useState('');
    const [carrierName, setCarrierName] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [pendingSubmission, setPendingSubmission] = useState(false);
    const [pouchStatusConfirmation, setPouchStatusConfirmation] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const user = {
        stationBarcode,
        userBarcode,
        carrierBarcode,
      };
    // Adjusted to use the new hook
    const { submitCarrierHistoryAndUpdateLocation } = useProductLocationAndCarrierHistorySubmit(token, user);

  // Fetch Medicine Bags
  useEffect(() => {
    const fetchMedicine_bags = async () => {
      try {
        const response = await axios.get('/api/MedicineBags/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setMedicineBags(response.data);
        }
      } catch (error) {
        console.error('Error fetching medicine bags:', error);
      }
    };
    fetchMedicine_bags();
  }, [token]);

  // Fetch carrier name based on the carrierBarcode
  useEffect(() => {

    const fetchCarrierName = async () => {
      const carrier = carriers.find(c => c.carrier_barcode === carrierBarcode);
      if (carrier) {
        setCarrierName(carrier.carrier_number); // Assuming 'carrier_number' is the field you want to display as name
      } else {
        // Fetch the carrier name from the API if it's not in the already fetched list
        try {
          const response = await axios.get(`/api/Carrierbarcode/${carrierBarcode}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data) {
            setCarrierName(response.data.carrier_number); // Adjust according to your API response
          }
        } catch (error) {
          console.error('Error fetching carrier name:', error);
        }
      }
    };

    if (carrierBarcode) {
      fetchCarrierName();
    }
  }, [carrierBarcode, carriers, token]);

  // Fetch Pouches
  useEffect(() => {
    const fetchPouches = async () => {
      try {
        const response = await axios.get('/api/Pouch/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setPouches(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouches:', error);
      }
    };
    fetchPouches();
  }, [token]);


  // Fetch Consumable Batch
  useEffect(() => {
    const fetchConsumableBatch = async () => {
      try {
        const response = await axios.get('/api/ConsumableBatch/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setConsumableBatch(response.data);
        }
      } catch (error) {
        console.error('Error fetching consumable batch:', error);
      }
    };
    fetchConsumableBatch();
  }, [token]);


const handleAlertConfirm = () => {
  setShowAlert(false); 
  proceedWithSubmission(); 
};

const handleAlertCancel = () => {
  console.log('Cancel clicked, closing modal...');

  setShowAlert(false); 
  resetForm();
};

  // Handle barcode search within the loaded data
  useEffect(() => {
    const searchAllDatabases = () => {
      let allItems = [
        ...medicineBags.map(bag => ({ barcode: bag.medicine_bag_barcode, label: bag.medicine_bag_number, type: 'Medicine Bag' })),
        ...pouches.map(pouch => ({ barcode: pouch.pouch_barcode, label: pouch.pouch_number, type: 'Pouch' })),
        ...consumableBatch.map(batch => ({ barcode: batch.product_barcode, label: batch.consumable_name, type: 'Consumable Batch' })),
      ];

      const matchedItem = allItems.find(item => item.barcode === searchBarcode);
      if (matchedItem) {
        setSearchResult(`Found in ${matchedItem.type}: ${matchedItem.label}`);
        setSelectedDatabase(matchedItem.type); // Update selected database based on the found item
        setSelectedItem(matchedItem.barcode); // Update selected item to the found item's barcode
      } else {
        setSearchResult('No item found with that barcode in any database.');
        setSelectedDatabase(''); // Reset selected database
        setSelectedItem(''); // Reset selected item
      }
    };

    if (searchBarcode) searchAllDatabases();
    else setSearchResult('');
  }, [searchBarcode, medicineBags, carriers, pouches, vehicles, consumableBatch]);

  // Update the items dropdown when the selected database changes
  useEffect(() => {
    let items = [];
    switch (selectedDatabase) {
      case 'MedicineBags':
        items = medicineBags.map(bag => ({ id: bag.medicine_bag_barcode, label: bag.medicine_bag_number }));
        break;
      case 'Pouches':
        items = pouches.map(pouch => ({ id: pouch.pouch_barcode, label: pouch.pouch_number }));
        break;
      case 'ConsumableBatch':
        items = consumableBatch.map(batch => ({ id: batch.product_barcode, label: batch.consumable_name }));
        break;
      default:
        items = [];
    }
  
    // This is the corrected part: filter the items based on the itemFilter query
    const filteredItems = items.filter(item => item.label.toLowerCase().includes(itemFilter.toLowerCase()));
  
    setDatabaseItems(filteredItems);
    // If the filter is cleared, it should reset to the full list for the selected database
    // This is handled implicitly by including itemFilter in the useEffect's dependency array
  }, [selectedDatabase, medicineBags, carriers, pouches, vehicles, consumableBatch, itemFilter]); // Include itemFilter in the dependency array  
  const toggleLocationForm = () => setShowLocationForm(!showLocationForm);
  
  // Function to reset the form
  const resetForm = () => {
    setSelectedDatabase('');
    setSelectedItem('');
    setDatabaseItems([]);
    setSearchBarcode('');
    setSearchResult('');
    // Optionally reset other states as needed
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check if there's a pending submission that needs confirmation
    if (pendingSubmission | pouchStatusConfirmation) {
      // Now trigger the modal to show, indicating user attempted to submit
      setShowAlert(true);
    } else {
      // No pending confirmation, proceed directly with submission
      proceedWithSubmission();
    }
  };
  
  const handleStoredInChange = (storedInValue) => {
    if (storedInValue !== 'Not stored in carriers, bags, or vehicles') {
      setConfirmationMessage(`Item is shown as stored in: ${storedInValue}`);
      setPendingSubmission(true); // Mark that submission is pending confirmation, but don't show modal yet
    } else {
      setPendingSubmission(false); // No confirmation needed, clear any pending submission state
    }
  };

  const handlePouchStatusChange = (newPouchStatus) => {
    if (newPouchStatus !== 'QAed') {
        setConfirmationMessage(`Item is shown as: ${newPouchStatus}. Are you sure you want to pack this?`);
        setPouchStatusConfirmation(true); // Show confirmation modal for non-QAed statuses
    } else {
        setPouchStatusConfirmation(false); // No confirmation needed, directly proceed
    }
};

  const proceedWithSubmission = () => {
   
    const submitBarcode = searchBarcode || selectedItem; // This assumes selectedItem holds the barcode when selected from dropdown
  
    // Ensure we have a barcode to submit, otherwise call onError with a message
    if (!submitBarcode) {
      console.error("No barcode provided for submission.");
      return;
    }
  
    // Use stationBarcode directly from the Redux store as the location barcode
    submitCarrierHistoryAndUpdateLocation(submitBarcode, stationBarcode, quantity,
      (message) => console.log(message),
      (error) => console.error(error)
    );
    if (typeof onReload === "function") {
        onReload();
    } else {
        console.error("onReload is not a function:", onReload);
    }
    };

  return (
    <div className="carrier-pack-layout"> {/* New wrapper div */}

    <div className="medicine-upload-card">
      <h2 className="medicine-upload-header">Carrier Packing portal</h2>
      <h2 className="medicine-upload-header2">{carrierName}</h2>
      <div className="medicine-upload-body">
        <form onSubmit={handleSubmit} className="medicine-upload-form">
          {/* Barcode Search input */}
          <div className="medicine-upload-item">
            <label htmlFor="searchBarcode" className="medicine-upload-label">Barcode Search:</label>
            <input
              type="text"
              id="searchBarcode"
              className="medicine-upload-input"
              value={searchBarcode}
              onChange={(e) => setSearchBarcode(e.target.value)}
              disabled={selectedDatabase !== ''} // Disable if a database is selected
            />
          </div>
  
          {searchResult && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-value">{searchResult}</span>
            </div>
          )}
  
          {/* Database selection dropdown, shown only if search barcode is not used */}
          {searchBarcode === '' && (
            <div className="medicine-upload-item">
              <label htmlFor="database" className="medicine-upload-label">Select Database:</label>
              <select
                id="database"
                className="medicine-upload-select"
                value={selectedDatabase}
                onChange={(e) => {
                  setSelectedDatabase(e.target.value);
                  setSelectedItem(''); // Reset selected item when changing database
                }}
              >
                <option value="">Select...</option>
              <option value="MedicineBags">Medicine Bags</option>
              <option value="Pouches">Pouches</option>
              <option value="ConsumableBatch">Consumables</option>
            </select>
            </div>
          )}
  
         {/* Conditional rendering for Select Item Dropdown */}
         {selectedDatabase && searchBarcode === '' && (
            <>
              {/* Input field for filtering the items */}
              <div className="medicine-upload-item">
                <label htmlFor="itemFilter" className="medicine-upload-label">Filter Items:</label>
                <input
                  type="text"
                  id="itemFilter"
                  className="medicine-upload-input"
                  placeholder="Type to filter items..."
                  value={itemFilter}
                  onChange={(e) => setItemFilter(e.target.value)}
                />
              </div>

              {/* Filtered Select Item Dropdown */}
              <div className="medicine-upload-item">
                <label htmlFor="databaseItems" className="medicine-upload-label">Select Item:</label>
                <select
                  id="databaseItems"
                  className="medicine-upload-select"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                >
                  <option value="">Select...</option>
                  {databaseItems.map(item => (
                    <option key={item.id} value={item.id}>{item.label}</option>
                  ))}
                </select>
              </div>
            </>
          )}
            <div className="medicine-upload-item">
              <label htmlFor="quantity" className="medicine-upload-label">Quantity:</label>
              <input
                type="number"
                id="quantity"
                className="medicine-upload-input"
                value={quantity}
                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))} // Ensure positive integers only
                min="1"
              />
            </div>  
          <div className="medicine-upload-item">
            <span className="medicine-upload-label">Current Station:</span>
            <span className="medicine-upload-value">{stationName}</span>
          </div>
          <div className="button-row">
            <button type="submit" className="medicine-upload-button submit-button">Submit</button>
            <button type="button" onClick={resetForm} className="medicine-upload-button refresh-button">Refresh</button>
            <button type="button" onClick={toggleLocationForm} className="medicine-upload-button change-location-button">Change Location</button>
            </div>
            <div>
          {showLocationForm && <LocationForm token={token} onClose={() => setShowLocationForm(false)} />}

          </div>
        </form>
        {showAlert && (
          <SimpleModal
            isOpen={showAlert}
            onClose={handleAlertCancel} 
            title="Confirmation Needed"
          >
            <p>{confirmationMessage}</p>
            <div className="modal-actions">
              <button onClick={handleAlertConfirm}>Yes, Submit Anyway</button>
              <button onClick={handleAlertCancel}>Cancel</button>
            </div>
          </SimpleModal>
        )}
            <ProductDetailsCard
                token={token}
                productBarcode={selectedItem || searchBarcode}
                onStoredInChange={handleStoredInChange}
                onPouchStatusChange={handlePouchStatusChange}
            />      
        </div>
      </div>
    </div>

  );
  
  
};

export default CarrierAllProductForm;
