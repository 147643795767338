import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RolePermissionsPage = ({ token }) => {
  const [roles, setRoles] = useState({});
  const [updatedRoles, setUpdatedRoles] = useState({});
  const [featurePermissions, setFeaturePermissions] = useState([]);
  const headers = { 'Authorization': `Bearer ${token}` };
  const [allRoles, setAllRoles] = useState([]);

  useEffect(() => {
    axios.get('/api/UserPosition/', { headers })
      .then(response => {
        setAllRoles(response.data || []);
      })
      .catch(error => {
        console.error('Error fetching all roles:', error);
      });
  }, []);
  

  useEffect(() => {
    axios.get('/api/get_all_role_permissions/', { headers })
      .then(response => {
        setRoles(response.data.role_permissions || {});
        setUpdatedRoles(response.data.role_permissions || {});
      })
      .catch(error => {
        console.error('Error fetching all role permissions:', error);
      });

    axios.get('/api/feature_permission/', { headers })
      .then(response => {
        setFeaturePermissions(response.data || []);
      })
      .catch(error => {
        console.error('Error fetching feature permissions:', error);
      });
  }, []);

  const handlePermissionChange = (role, permissionId, isChecked) => {
    const tempUpdatedRoles = { ...updatedRoles };
  
    // Initialize the role's permissions array if it doesn't exist yet
    if (!tempUpdatedRoles[role]) {
      tempUpdatedRoles[role] = [];
    }
  
    if (isChecked) {
      tempUpdatedRoles[role].push(permissionId);
    } else {
      tempUpdatedRoles[role] = tempUpdatedRoles[role].filter(id => id !== permissionId);
    }
  
    setUpdatedRoles(tempUpdatedRoles);
  };
  

  const handleSave = () => {
    Object.keys(updatedRoles).forEach(role => {
      axios.put(`/api/UserPosition/${role}/permissions/update/`, { permissions: updatedRoles[role] }, { headers })
        .then(() => {
        })
        .catch(error => {
          console.error(`Error updating permissions for role ${role}:`, error);
        });
    });
    setRoles(updatedRoles);
  };

  return (
    <div>
      <h1>Role Permissions</h1>
      <table>
        <thead>
          <tr>
            <th>Role</th>
            {featurePermissions.map(({ name }) => (
              <th key={name}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allRoles.map((roleObj) => (
            <tr key={roleObj.id}>
              <td>{roleObj.name}</td>
              {featurePermissions.map(({ id, name }) => (
                <td key={id}>
                  <input
                    type="checkbox"
                    checked={updatedRoles[roleObj.name] && updatedRoles[roleObj.name].includes(id)}
                    onChange={e => handlePermissionChange(roleObj.name, id, e.target.checked)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button className='button'  onClick={handleSave}>Save</button>
    </div>
  );
};

export default RolePermissionsPage;
