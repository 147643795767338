import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { updateHistory } from './UpdateHistory'; // Adjust the import path as necessary

const StationStockToContainer = ({ token }) => {
  const user = useSelector((state) => state.user);
  const { userBarcode } = user || {};
  const stationBarcode = user.station_barcode || ''; // Adjust the property name as necessary
  const [binBarcode, setBinBarcode] = useState('');
  const [binContents, setBinContents] = useState([]);
  const [productBarcode, setProductBarcode] = useState('');
  const [containerBarcode, setContainerBarcode] = useState(localStorage.getItem('containerBarcode') || '');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productDetails, setProductDetails] = useState({ name: '', type: '' });
  const [containerDetails, setContainerDetails] = useState({ name: '', type: '' });
  const [selectedProductType, setSelectedProductType] = useState('');
  const [searchBinBarcode, setSearchBinBarcode] = useState('');
  const [displayBinInfo, setDisplayBinInfo] = useState({ binName: '', storageUnitName: '' });
  const carrierBarcode = user.carrierBarcode; // or another source if applicable
  const [scannedItemBarcode, setScannedItemBarcode] = useState('');
  const [carriers, setCarriers] = useState([]);
  const [medicineBags, setMedicineBags] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [bins, setBins] = useState([]);
  const [selectedBin, setSelectedBin] = useState('');

    // Fetch carriers and medicine bags
    useEffect(() => {
        // Fetch carriers and medicine bags
        const fetchCarriersAndMedicineBags = async () => {
        try {
            const [carrierResp, medicineBagResp] = await Promise.all([
            axios.get('/api/Carrier/', { headers: { Authorization: `Bearer ${token}` } }),
            axios.get('/api/MedicineBags/', { headers: { Authorization: `Bearer ${token}` } })
            ]);

            setCarriers(carrierResp.data);
            setMedicineBags(medicineBagResp.data);
        } catch (error) {
            console.error('Error fetching carriers and medicine bags:', error);
        }
        };

        fetchCarriersAndMedicineBags();
    }, [token]);

    // Fetch all carriers and medicine bags
    useEffect(() => {
        const fetchCarriers = async () => {
        const response = await axios.get('/api/Carrier/', {
            headers: { Authorization: `Bearer ${token}` },
        });
        setCarriers(response.data);
        };

        const fetchMedicineBags = async () => {
        const response = await axios.get('/api/MedicineBags/', {
            headers: { Authorization: `Bearer ${token}` },
        });
        setMedicineBags(response.data);
        };

        fetchCarriers();
        fetchMedicineBags();
    }, [token]);

    useEffect(() => {
        if (searchBinBarcode) {
        const findBin = async () => {
            try {
            const response = await axios.get(`/api/bin/barcode/${searchBinBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data) {
                setDisplayBinInfo({
                binName: response.data.bin_name,
                storageUnitName: response.data.storage_unit_name,
                });
                setSelectedBin(response.data.bin_barcode);
            } else {
                setDisplayBinInfo({ binName: '', storageUnitName: '' });
            }
            } catch (error) {
            console.error('Error searching bin with barcode:', error);
            setDisplayBinInfo({ binName: '', storageUnitName: '' });
            }
        };
        findBin();
        } else {
        setDisplayBinInfo({ binName: '', storageUnitName: '' });
        }
    }, [searchBinBarcode, token]);

    useEffect(() => {
        const fetchBins = async () => {
        try {
            const response = await axios.get('/api/bin/', {
            headers: { Authorization: `Bearer ${token}` },
            });
            setBins(response.data); // Make sure this matches the structure of your response
        } catch (error) {
            console.error("Error fetching bins:", error);
        }
        };

        fetchBins();
    }, [token]);

    useEffect(() => {
        const fetchItemDetails = async () => {
        if (scannedItemBarcode && carrierBarcode) {
            try {
            // Use the packed_products_in_carrier endpoint and pass the carrier barcode as a parameter
            const response = await axios.get(`/api/packed_products_in_carrier/?carrier_barcode=${carrierBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Assuming the API response includes an array of items, find the scanned item in the response
            const foundItem = response.data.find(item => item.Product_Barcode === scannedItemBarcode);

            if (foundItem) {
                setSelectedItem(scannedItemBarcode);
            } else {
                // Handle the case where the scanned item is not found in the carrier
            }
            } catch (error) {
            console.error('Error fetching item details from the carrier:', error);
            }
        }
        };

        fetchItemDetails();
    }, [scannedItemBarcode, carrierBarcode, token]);

  // Fetch contents of the selected bin
    useEffect(() => {
      const fetchBinContents = async () => {
        const binId = binBarcode || selectedBin; // Use either binBarcode input or selectedBin from dropdown
        if (!binId) {
          setBinContents([]);
          setSelectedProduct('');
          return;
        }

        try {
          const response = await axios.get(`/api/bin_contents/${binId}/`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setBinContents(response.data);
        } catch (error) {
          console.error('Error fetching bin contents:', error);
          setBinContents([]);
        }
      };

      fetchBinContents();
    }, [binBarcode, selectedBin, token]);
  
      useEffect(() => {
        const fetchProductDetails = async () => {
            if (!productBarcode) return; // Exit if no productBarcode is set
    
            try {
                const response = await axios.get(`/api/product_details/${productBarcode}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProductDetails({
                    name: response.data.product_detail, // Adjust according to the actual response structure
                    type: response.data.product_type,   // Adjust according to the actual response structure
                });
            } catch (error) {
                console.error('Error fetching product details:', error);
                setProductDetails({ name: 'Error fetching details', type: '' }); // Handle error
            }
        };
    
        fetchProductDetails();
    }, [productBarcode, token]); 

    useEffect(() => {
        const fetchContainerDetails = async () => {
            if (!containerBarcode) {
                return; // Exit if no containerBarcode is set
            }
        
            try {
                // Adjust the URL according to your API endpoint
                const response = await axios.get(`/api/product_details/${containerBarcode}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                // Assume the response contains "name" and "type" of the container
                setContainerDetails({
                  product_detail: response.data.product_detail,
                  product_type: response.data.product_type,
              });
              
            } catch (error) {
                console.error('Error fetching container details:', error);
                // Reset or handle error
                setContainerDetails({ name: 'Error fetching details', type: '' });
            }
        };
    
        fetchContainerDetails();
    }, [containerBarcode, token]);
    
    const handleBinBarcodeChange = (e) => {
      const barcode = e.target.value;
      setBinBarcode(barcode);
      if (barcode) {
        // If a barcode is entered, clear the selected bin from the dropdown
        setSelectedBin('');
      }
    };

  // Function to handle changes to the container barcode input
  const handleContainerBarcodeChange = async (e) => {
    const barcode = e.target.value;
    setContainerBarcode(barcode); // Update containerBarcode state

    try {
      // Fetch container details including its type
      const response = await axios.get(`/api/product_details/${barcode}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setContainerDetails({
        product_detail: response.data.product_detail,
        product_type: response.data.product_type,
      });

      // Assuming you have a state to store the selected product type, update it here
      setSelectedProductType(response.data.product_type);

    } catch (error) {
      console.error("Error fetching container details:", error);
      setContainerDetails({ product_detail: 'Error', product_type: '' });
      // Also reset or handle the selectedProductType for error scenario
      setSelectedProductType('');
    }
  };

  const resetForm = () => {
    setBinBarcode('');
    setBinContents([]);
    setSelectedBin('');
    setProductBarcode('');
    setContainerBarcode(localStorage.getItem('containerBarcode') || '');
    setSelectedProduct([]);
    setProductDetails({ name: '', type: '' });
    setContainerDetails({ name: '', type: '' });
    setSelectedProductType('');
    setSearchBinBarcode('');
    setDisplayBinInfo({ binName: '', storageUnitName: '' });
    setScannedItemBarcode('');
    // Reset any other state variables here if necessary
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await updateHistory({
        selectedProduct,
        binBarcode,
        selectedBin,
        userBarcode,
        stationBarcode: user.station_barcode, // Assuming station_barcode is correctly named in your user state
        selectedProductType: containerDetails.product_type,
        containerBarcode: containerBarcode,
        token,
      });
      
    
      console.log(result);
      // Add any success handling here
      resetForm();
    } catch (error) {
      console.error("Error in form submission:", error);
      // Add any error handling here
    }
  };

  return (
    <div className="Form-card">
      <form onSubmit={handleSubmit} className="Form-form-container">
        <h3>Move Item from Station Storage to Container</h3>


        {/* Bin Barcode Input */}
        <div className="Form-form-row">
          <label htmlFor="binBarcode">Bin Barcode:</label>
          <input
            type="text"
            id="binBarcode"
            value={binBarcode}
            onChange={handleBinBarcodeChange}
            placeholder="Scan or enter bin barcode"
          />
        </div>

        {/* Bin Dropdown */}
        <div className="Form-form-row">
          <label htmlFor="selectedBin">Or Select Bin:</label>
          <select
            id="selectedBin"
            value={selectedBin}
            onChange={(e) => setSelectedBin(e.target.value)}
            disabled={!!binBarcode} // Disable if binBarcode is entered
          >
            <option value="">Select a bin...</option>
            {bins.map((bin) => (
              <option key={bin.bin_barcode} value={bin.bin_barcode}>
                {bin.bin_name} ({bin.bin_barcode})
              </option>
            ))}
          </select>
        </div>

        {/* Product Dropdown */}
        {binContents.length > 0 && (
          <div className="Form-form-row">
            <label htmlFor="selectedProduct">Select Product:</label>
            <select
              id="selectedProduct"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <option value="">Select a product...</option>
              {binContents.map((item) => (
                <option key={item.product_barcode} value={item.product_barcode}>
                  {item.product_detail} - Type: {item.product_type} - Quantity: {item.net_quantity}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="Form-form-row">
            <label htmlFor="productBarcode">Product Barcode:</label>
            <input
            type="text"
            id="productBarcode"
            value={productBarcode}
            onChange={(e) => setProductBarcode(e.target.value)}
            placeholder="Enter product barcode"
            />
        </div>

        {/* Container Barcode Input */}
        <div className="Form-form-row">
            <label htmlFor="containerBarcode">Container Barcode:</label>
            <input
                type="text"
                id="containerBarcode"
                value={containerBarcode}
                onChange={handleContainerBarcodeChange}
                placeholder="Enter container barcode"
            />
        </div>
        {/* Displaying fetched container details */}
        {containerDetails.product_detail && (
            <div className="Form-form-row">
                <label>Container Name:</label>
                <span>{containerDetails.product_detail}</span>
            </div>
        )}
        {containerDetails.product_type && (
            <div className="Form-form-row">
                <label>Container Type:</label>
                <span>{containerDetails.product_type}</span>
            </div>
        )}

        <button type="submit">Move Item</button>
        <button type="button" onClick={resetForm}>Refresh</button>

      </form>
    </div>
  );
};

export default StationStockToContainer;
