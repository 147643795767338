// updateHistory.js
import axios from 'axios';

export const updateHistory = async ({
  selectedProduct,
  binBarcode,
  selectedBin,
  userBarcode,
  stationBarcode,
  selectedProductType,
  containerBarcode,
  token,
}) => {
  let apiUrl = ''; // Initialize apiUrl

  const Stationstockhistory = {
    product_barcode: selectedProduct,
    bin_barcode: binBarcode || selectedBin,
    action: 'remove', // Assuming "remove" is the correct action
    quantity_changed: 1,
    user: userBarcode,
    comments: `Moved to ${containerBarcode}`,
  };

  let containerHistoryData = {
    Product_Barcode: selectedProduct,
    Station_Barcode_Start: stationBarcode,
    datetime_start: new Date().toISOString(),
    amount: 1,
    User_Packed: userBarcode,
    // Adjust the status fields and barcodes based on product type
  };

  // Dynamically adjust barcodes and statuses based on the product type
  if (selectedProductType === 'Carrier') {
    containerHistoryData.Carrier_Barcode = containerBarcode;
    containerHistoryData.Carrier_Status = "Packed";
    apiUrl = '/api/CarrierHistory/create/';
  } else if (selectedProductType === 'Medicine Bag') {
    containerHistoryData.MedicineBag_Barcode = containerBarcode;
    containerHistoryData.MedicineBag_Status = "Packed";
    apiUrl = '/api/Medicine_BagHistory/create/';
  } else if (selectedProductType === 'Vehicle') {
    containerHistoryData.Vehicle_Barcode = containerBarcode;
    containerHistoryData.Vehicle_Status = "Packed";
    apiUrl = '/api/VehicleHistory/create/';
  } else {
    console.error('Invalid product type');
    return;
  }

  // Omit undefined properties for cleanliness
  Object.keys(containerHistoryData).forEach(key => containerHistoryData[key] === undefined && delete containerHistoryData[key]);

  const productLocationData = {
    barcode: containerBarcode,
    location_barcode: stationBarcode,
    datetime: new Date().toISOString(),
  };

  try {
    // Update the specific history based on the product type
    await axios.post(apiUrl, containerHistoryData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    // Update the station stock history
    await axios.post('/api/add-station-stock-history/', Stationstockhistory, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    // Create a product location entry
    await axios.post('/api/ProductLocation/create/', productLocationData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('History and product location updated successfully');
    return 'History and product location updated successfully';
  } catch (error) {
    console.error('Error updating history and creating product location:', error);
    throw error; // Throw the error to catch it in the calling function
  }
};
