import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const VehicleTypeForm = ({ token, selectedVehicleType }) => {
  const [vehicleTypeName, setVehicleTypeName] = useState('');
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);

  useEffect(() => {
    if (selectedVehicleType) {
      setVehicleTypeName(selectedVehicleType.vehicle_type_name);
      setSelectedVehicleTypeId(selectedVehicleType.vehicle_type_name);
    }
  }, [selectedVehicleType]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/VehicleType/create/', {
        vehicle_type_name: vehicleTypeName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly included
        },
      });
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating vehicle type:', error);
    }
  };
  

  const handleRefresh = () => {
    setVehicleTypeName('');
    setSelectedVehicleTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${vehicleTypeName}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/VehicleType/update/${selectedVehicleTypeId}/`, {
        vehicle_type_name: vehicleTypeName, // Adjusted field name
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly included
        },
      });
      // Check for a status code of 200 or 204 for success
      if (response.status === 200 || response.status === 204) {
        window.location.reload();
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error updating vehicle type:', error.response ? error.response.data : error);
    }
  };
  
  

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/VehicleType/delete/${selectedVehicleTypeId}/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly included
        },
      });
      if (response.status === 204) { 
        window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting vehicle type:', error);
    }
  };
  
  const questions = [
    {
      type: 'text',
      text: 'Vehicle Type Name',
      value: vehicleTypeName,
      handleChange: (e) => setVehicleTypeName(e.target.value),
    },
  ];

  return (
    <DynamicForm
      title="Add New Vehicle Type"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedVehicleTypeId}
    />
  );
};

export default VehicleTypeForm;
