import React, { useState } from 'react';
import axios from 'axios';
import { jsonToCSV } from 'react-papaparse';

const TemplateDownloader = ({ token }) => { 
  const [selectedModel, setSelectedModel] = useState('');

  const databases = [
    'ConsumableBatch',
    'MedicineBag',
    'Carrier',
    'Medicine',
    'MedicineBatch',
    'Pouch'
  ];

  const modelColumnHeaders = {
    ConsumableBatch: ['unique_id', 'consumable_name', 'batch_number', 'expiry_date', 'consumable_pricing', 'product_barcode', 'added_by', 'date_added', 'supplier', 'pack_size'],
    MedicineBatch: ['unique_id', 'medicine_name', 'batch_number', 'expiry_date', 'medicine_pricing', 'drug_barcode', 'added_by', 'date_added', 'supplier', 'pack_size'],
    Medicine: ['id', 'medicine_name', 'dose', 'max_level', 'reorder_level', 'pack_by_vial'],
    Carrier: ['carrier_number', 'carrier_type', 'carrier_barcode'],
    Pouch: ['pouch_number', 'pouch_type', 'pouch_barcode'],
    PouchContents: ['unique_id', 'pouch_number', 'drug_barcode', 'amount', 'user_barcode', 'datetime_qa', 'location_packed'],
    Station: ['station_name', 'station_type', 'station_barcode'],
    MedicineBags: ['medicine_bag_number', 'medicine_bag_type', 'medicine_bag_barcode']
  };

  const handleDownload = () => {
    if (selectedModel) {
      axios.get(`api/${selectedModel}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        console.log(response.data);
        let dataToConvert = response.data;
  
        if (dataToConvert.length === 0 && modelColumnHeaders[selectedModel]) {
          // Create an empty row with column headers
          const emptyRow = modelColumnHeaders[selectedModel].reduce((obj, header) => {
            obj[header] = '';
            return obj;
          }, {});
          dataToConvert = [{ ...emptyRow, 'Update': 'no' }];
        } else {
          // Add 'Update' column to each data row
          dataToConvert = dataToConvert.map(item => {
            return { ...item, 'Update': 'no' };
          });
        }
  
        // Modify the data before converting to CSV
        const modifiedData = dataToConvert.map(item => {
          Object.keys(item).forEach(key => {
            if (typeof item[key] === 'number' || /^\d+$/.test(item[key])) {
              // Append an underscore to numeric values
              item[key] = `'${item[key]}'`;
            }
          });
          return item;
        });

        const csv = jsonToCSV(modifiedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${selectedModel}_data.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        alert('Download failed: ' + error.message);
      });
    } else {
      alert('Please select a database model to download its data.');
    }
  };
  

  return (
    <div className="medicine-upload-card">
      <div className="medicine-upload-header">
        <h3>Download Template</h3>
      </div>
      <div className="medicine-upload-body">
        <div className="medicine-upload-form">
          <div className="medicine-upload-item">
            <label className="medicine-upload-label">Select a Database</label>
            <select className="medicine-upload-value" value={selectedModel} onChange={e => setSelectedModel(e.target.value)}>
              <option value="">Select a Database</option>
              {databases.map(db => (
                <option key={db} value={db}>{db}</option>
              ))}
            </select>
          </div>
          <button className="medicine-upload-button" onClick={handleDownload}>Download Template</button>
        </div>
      </div>
    </div>
  );
};

export default TemplateDownloader;