// GlobalImportExport.jsx
import React from 'react';
import FileUploader from '../components/Global import export/FileUploader'; // Assuming FileUploader is in the same directory
import TemplateDownloader from '../components/Global import export/Templatedownload'; // Assuming TemplateDownloader is in the same directory
import Header from '../components/header';

const GlobalImportExport = () => {
  const token = localStorage.getItem("token");

  return (
    <div className="packing-app">
      <Header />
      <div>
        <h1>Global Import and Export</h1>
        <div>
          <FileUploader token={token} />
        </div>
        <div>
          <TemplateDownloader token={token} />
        </div>
      </div>
    </div>
  );
};

export default GlobalImportExport;
