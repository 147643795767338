import React, { useState, useEffect } from 'react';
import VehicleForm from "./VehicleForm";

const VehicleTable = ({ token }) => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  useEffect(() => {
    getVehicles();
  }, []);

  const getVehicles = async () => {
    try {
      const response = await fetch('/api/Vehicle/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setVehicles(data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleRowClick = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="vehicle-container">
        <div className="table-list" style={{ flex: 1 }}>
          <VehicleForm selectedVehicle={selectedVehicle} token={token}/>
          <table>
            <thead>
              <tr>
                <th>Vehicle Number</th>
                <th>Vehicle Type</th>
                <th>Vehicle Barcode</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(vehicles) && vehicles.map((vehicle, index) => (
                <tr key={index} onClick={() => handleRowClick(vehicle)}>
                  <td>{vehicle.vehicle_number}</td>
                  <td>{vehicle.vehicle_type}</td>
                  <td>{vehicle.vehicle_barcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  
};

export default VehicleTable;
