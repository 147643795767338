// Textinput.js
import React from 'react';

const Textinput1Question = ({ question, isLocked }) => {
  return (
    <div className="account-card-item">
      <div className="Form-input-field">
        <input 
          type={question.type} 
          value={question.value || ''} 
          onChange={question.handleChange} 
          required 
          readOnly={isLocked}  // Make input read-only if isLocked is true
        />
        <label>
          {question.text.split('').map((char, i) => 
            <span key={i} style={{transitionDelay: `${i * 50}ms`, margin: char === ' ' ? '0 2px' : '0'}}>{char}</span>
          )}
        </label>
      </div>
    </div>
  );
};

export default Textinput1Question;