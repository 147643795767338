import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm';
import { useSelector } from 'react-redux';

const DrugAdministrationForm = ({ token }) => {
    const [ePCRNumber, setEPCRNumber] = useState('');
    const [medicineName, setMedicineName] = useState('');
    const [administeredMg, setAdministeredMg] = useState('');
    const [wastedMg, setWastedMg] = useState('0');
    const [medicineOptions, setMedicineOptions] = useState([]);
    const user = useSelector((state) => state.user);
    const medicineBagBarcode = user.Medicine_bagBarcode;
    const userBarcode = useSelector((state) => state.user.userBarcode);
    const [pouchDetails, setPouchDetails] = useState(null);
    const [pouchContents, setPouchContents] = useState([]);
    const [pouchBarcode, setPouchBarcode] = useState('');
    const [selectedPouchNumber, setSelectedPouchNumber] = useState('');
    const [pouchNumbers, setPouchNumbers] = useState([]);
    const [pouchHistoryUniqueId, setPouchHistoryUniqueId] = useState('');

  // Format the date to match the datetime-local input requirements
  const formatDateForInput = (date) => {
    return date.toISOString().slice(0, 16);
  };

  const [dateTime, setDateTime] = useState(formatDateForInput(new Date()));

    // handleMedicineChange function
    const handleMedicineChange = (selectedOption) => {
      setMedicineName(selectedOption.value);
      const selectedMedicine = medicineOptions.find(option => option.value === selectedOption.value);
      if (selectedMedicine && selectedMedicine.pouchBarcode) {
          setPouchBarcode(selectedMedicine.pouchBarcode);
          console.log("Updated pouchBarcode (in handleMedicineChange):", selectedMedicine.pouchBarcode); // Debugging
      }
  };
  useEffect(() => {
    const fetchMedicineDetails = async () => {
        try {
            const response = await axios.get(`api/medicine_bag_medicine_detail_data/${medicineBagBarcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
                setMedicineOptions(response.data.map(item => ({
                    label: item.Medicine_Name, // Display name
                    value: item.Medicine_Barcode, // Actual value to be sent in requests
                    pouchBarcode: item.pouch_barcode
                })));
                setPouchNumbers([...new Set(response.data.map(item => item.Pouch_Number))]);
            }
        } catch (error) {
            console.error('Error fetching medicine details:', error);
        }
    };
    if (medicineBagBarcode) {
        fetchMedicineDetails();
    }
}, [token, medicineBagBarcode]);

    // Secondary useEffect to update pouchBarcode based on selected medicine
    // useEffect for updating pouchBarcode
    useEffect(() => {
      const selectedMedicine = medicineOptions.find(option => option.value === medicineName);
      if (selectedMedicine && selectedMedicine.pouchBarcode) {
          setPouchBarcode(selectedMedicine.pouchBarcode);
          console.log("Updated pouchBarcode (in useEffect):", selectedMedicine.pouchBarcode); // Debugging
      }
  }, [medicineName, medicineOptions]);

    // Update pouchBarcode when selectedPouchNumber changes
    useEffect(() => {
      const selectedPouchData = medicineOptions.find(option => option.Pouch_Number === selectedPouchNumber);
      if (selectedPouchData) {
          setPouchBarcode(selectedPouchData.pouchBarcode);
      }
  }, [selectedPouchNumber, medicineOptions]);

    // Fetch Pouch Details
    useEffect(() => {
      const fetchPouchDetails = async () => {
          try {
              const response = await axios.get(`api/PouchBarcode/${pouchBarcode}/`, {
                  headers: { Authorization: `Bearer ${token}` },
              });
              setPouchDetails(response.data);
          } catch (error) {
              console.error('Error fetching pouch details:', error);
          }
      };

      if (pouchBarcode) {
          fetchPouchDetails();
      }
  }, [token, pouchBarcode]);

  // Fetch Pouch Contents
  useEffect(() => {
      if (pouchDetails) {
          const fetchPouchContents = async () => {
              try {
                  const response = await axios.get(`api/Pouch_contents_with_number/${selectedPouchNumber}/`, {
                      headers: { Authorization: `Bearer ${token}` },
                  });
                  setPouchContents(response.data);
              } catch (error) {
                  console.error('Error fetching pouch contents:', error);
              }
          };

          fetchPouchContents();
      }
  }, [token, pouchDetails]);

    // Fetch pouchBarcode based on selected pouch number
    useEffect(() => {
      const fetchPouchBarcode = async () => {
          if (!selectedPouchNumber) return;
          try {
              const response = await axios.get(`/api/Pouch/?pouchNumber=${selectedPouchNumber}`, {
                  headers: { Authorization: `Bearer ${token}` },
              });
              if (response.data && response.data.length > 0) {
                  setPouchBarcode(response.data[0].pouch_barcode);
              }
          } catch (error) {
              console.error('Error fetching pouch barcode:', error);
          }
      };
      fetchPouchBarcode();
  }, [selectedPouchNumber, token]);

  useEffect(() => {
    const fetchPouchHistoryUniqueId = async () => {
        if (medicineBagBarcode && medicineName && selectedPouchNumber) {
            console.log("Fetching PouchHistory Unique ID for:", medicineName, selectedPouchNumber);

            try {
                const response = await axios.get(`/api/medicine_bag_medicine_detail_data/${medicineBagBarcode}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const sanitizedMedicineName = medicineName.trim().replace(/\s+/g, ' '); // Replace multiple spaces with a single space
                const filteredData = response.data.filter(item => {
                    const itemMedicineName = item.Medicine_Name.trim().replace(/\s+/g, ' ');
                    const itemPouchNumber = item.Pouch_Number.trim();
                    const isMedicineMatch = itemMedicineName === sanitizedMedicineName;
                    const isPouchMatch = itemPouchNumber === selectedPouchNumber.trim();

                    console.log(`Comparing Medicine: "${itemMedicineName}" (Length: ${itemMedicineName.length}) with "${sanitizedMedicineName}" (Length: ${sanitizedMedicineName.length}) - Match: ${isMedicineMatch}`);
                    console.log(`Comparing Pouch: "${itemPouchNumber}" with "${selectedPouchNumber.trim()}" - Match: ${isPouchMatch}`);

                    return isMedicineMatch && isPouchMatch;
                });

                console.log("Filtered data:", filteredData);

                if (filteredData.length > 0) {
                    setPouchHistoryUniqueId(filteredData[0].PouchHistory_Unique_ID);
                    console.log("Fetched PouchHistory Unique ID:", filteredData[0].PouchHistory_Unique_ID);
                } else {
                    setPouchHistoryUniqueId('');
                    console.warn("No PouchHistory Unique ID found for the selected medicine and pouch number.");
                }
            } catch (error) {
                console.error('Error fetching PouchHistory Unique ID:', error);
            }
        }
    };
    fetchPouchHistoryUniqueId();
}, [medicineBagBarcode, medicineName, selectedPouchNumber, token]);








  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit - pouchBarcode:", pouchBarcode);
    console.log("handleSubmit - pouchHistoryUniqueId:", pouchHistoryUniqueId);
    console.log("Selected medicine:", medicineName);
    console.log("Selected pouch number:", selectedPouchNumber);

    if (!pouchBarcode || !pouchHistoryUniqueId) {
        alert("Pouch barcode or pouch history ID is not selected. Please select a valid medicine and pouch number.");
        return;
    }

      try {
        await axios.put(`/api/update_pouch_history/${pouchHistoryUniqueId}/`, {
            user_unpacked: userBarcode,
            datetime_unpacked: new Date().toISOString(),
            status: 'Unpacked',
            comments: `Drug administered on ePCR`, ePCRNumber,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Additional logic if needed after updating the pouch history
        alert("Pouch history updated successfully");

    } catch (error) {
        console.error('Error updating pouch history:', error);
        alert("Error updating pouch history");
    }
};
  
  

  const questions = [
    {
      text: 'ePCR Number',
      value: ePCRNumber,
      handleChange: (e) => setEPCRNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Medicine Given',
      value: medicineName,
      handleChange: (e) => setMedicineName(e.target.value),
      type: 'select',
      styled: true,
      options: medicineOptions,
    },
    {
      text: 'Select Pouch Number',
      value: selectedPouchNumber,
      handleChange: (e) => setSelectedPouchNumber(e.target.value),
      type: 'select',
      styled: true,
      options: pouchNumbers.map(number => ({ value: number, label: number }))
    },
    {
      text: 'Administered mg',
      value: administeredMg,
      handleChange: (e) => setAdministeredMg(e.target.value),
      type: 'number',
    },
    {
      text: 'Wasted mg',
      value: wastedMg,
      handleChange: (e) => setWastedMg(e.target.value),
      type: 'number',
    },
    {
      text: 'Date and Time',
      value: dateTime,
      handleChange: (e) => setDateTime(e.target.value),
      type: 'datetime-local',
    },
  ];

  return (
    <div className="form-container">
      <DynamicForm
        title="Add New Drug Administration Record"
        questions={questions}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default DrugAdministrationForm;
